import moment from 'moment';

const formatNumber = (number) => {
  return number?.toString()?.padStart(3, '0');
}

const toCamelCase = (str) => {
	return str.replace(/\s(.)/g, function ($1) { return $1.toUpperCase(); })
		.replace(/\s/g, '')
		.replace(/^(.)/, function ($1) { return $1.toLowerCase(); });
}

const getDateFormats = () => {

  const separators = ["/", "-", ".", ""]
  const dateFormats = ["YYYY-MM-DD", "YYYY-MM-DDTHH:mm:ss.SSSZ", "YYYY-MM-DDTHH:mm:ss.SSS", "YYYY-MM-DDTHH:mm:ss", "YYYY-MM-DD HH:mm:ss", 
    "YYYY-MM-DD HH:mm", "DD-MM-YYYY", "DD-MM-YYYYTHH:mm:ss.SSSZ", "DD-MM-YYYYTHH:mm:ss.SSS", "DD-MM-YYYYTHH:mm:ss", "DD-MM-YYYY HH:mm:ss", 
    "DD-MM-YYYY HH:mm", "MM-DD-YYYY", "MM-DD-YYYYTHH:mm:ss.SSSZ", "MM-DD-YYYYTHH:mm:ss.SSS", "MM-DD-YYYYTHH:mm:ss", "MM-DD-YYYY HH:mm:ss", 
    "MM-DD-YYYY HH:mm"]

  const formats = [];
  dateFormats.forEach((format) => {
    separators.forEach((separator) => {
      formats.push(format.replace(/-/g, separator));
    });
  });

  return formats;
    
}

const getDate = (value, format = "DD/MM/YYYY") => {
  const formats = getDateFormats();
  let date = null;
  for(let i = 0; i < formats.length; i++) {
    date = moment.utc(value, formats[i]);
    if(date.isValid()) break;
  }
  if(!date.isValid()) date = null;
  if(format == "iso") return date?.toISOString() ?? "";
  return date?.format(format) ?? "";
}

export {
  formatNumber,
  toCamelCase,
  getDateFormats,
  getDate
}