import React from "react";
import AppsList from 'components/flow/toolbox/apps/AppList';

const Apps = ({
	search,
	flowId,
	activeTab,
	setActiveTab,
	onSearchApps,
	onUpdateAppsQuery
}) => (
	<div id="app-store">

		<div class="px-4 mt-4 mb-3" id="search-bar">
			<input type="text" placeholder="Buscar" class="py-1 pl-7 pr-2 rounded w-100"
				value={search} onChange={(event) => onSearchApps(event.target.value)}
			/>
			<i className="fas fa-search d-block ml-3"></i>
		</div>

		{search == "" ? (
			<div id="toolbox-apps-tab" className="tabs mb-3 mt-4 col-md-12 d-flex">
				<button
					className={"dropdown-header px-3 py-2 " + (activeTab == 'installed' ? "active" : "")}
					onClick={() => setActiveTab('installed')}>
					Instaladas
				</button>
				<button
					className={"dropdown-header px-3 py-2 " + (activeTab == 'all' ? "active" : "")}
					onClick={() => setActiveTab('all')}>
					Disponibles
				</button>			
			</div>) : <div class="mt-4"></div>
		}
		<div id="app-list" className="px-3 scrollbar-narrow">
			<AppsList
				search={search}
				flowId={flowId}
				activeTab={activeTab}
				onUpdateAppsQuery={onUpdateAppsQuery}
			/>
		</div>

	</div>
);

export default Apps;
