/* eslint-disable max-len */
import React from "react";
import * as util from "components/flow/toolbox/util/toolbox";
import { CategoryItem, CollapseView } from "components/flow/toolbox/Toolbox.sub.NavHelpers.view";
import "styles/css/flow/Toolbox.css";

const NavCategories = ({
  apps,
  categoriesData,
  actionSearch,
  actionFilters,
  isDeveloper,
  onDragStart,
  onClickAction
}) => {
  let hasFilters = actionFilters.length > 0;

  let groupIdx = 0
  return categoriesData.map((categoryData, idx) => {
    if (!categoryData) return <div key={idx}></div>;
    const isPrevDifferentGroup = (idx > 0 && categoriesData[idx].groupLabel != categoriesData[idx - 1].groupLabel)
    const isNextDifferentGroup = (idx < categoriesData.length && categoriesData[idx].groupLabel != categoriesData[idx + 1]?.groupLabel)
    const isEvenSection = groupIdx % 2 == 1 || isNextDifferentGroup


    const getCollapse = (categoryData) => {

      let mainActions = []
      let hiddenActions = []
      for (let app of apps){
        mainActions = mainActions.concat(
          util.getMainActions(app, actionSearch, actionFilters, isDeveloper)
          .filter(action => (action.uiSettings?.general?.categories ?? []).includes(categoryData.name)))
        hiddenActions = hiddenActions.concat(
          util.getHiddenActions(app, actionSearch, actionFilters, isDeveloper)
          .filter(action => (action.uiSettings?.general?.categories ?? []).includes(categoryData.name)))
      }

      return <CollapseView
        id={categoryData.name}
        mainActions={mainActions}
        hiddenActions={hiddenActions}
        onClickAction={onClickAction}
        onDragStart={onDragStart}
        actionSearch={actionSearch}
        hasFilters={hasFilters}
        groupActions={true} />;
    }

    const title =
      (idx == 0 || categoriesData[idx].groupLabel != categoriesData[idx - 1].groupLabel) && actionSearch == "" && !hasFilters ?
        <div className={"w-100 section-title pr-1"}>
          <label className="w-100 text-left">
            {categoriesData[idx].groupLabel}
          </label>
        </div> : null

    const category =
      <CategoryItem
        id={categoryData.name}
        groupIdx={groupIdx}
        label={categoryData.label}
        icon={categoryData.icon}
        actionSearch={actionSearch}
        hasFilters={hasFilters} />;

    const collapse = isEvenSection && !hasFilters && actionSearch == "" ?
      <div className="w-100">
        {!isPrevDifferentGroup ? getCollapse(categoriesData[idx - 1]) : null}
        {getCollapse(categoryData)}
      </div> : hasFilters || actionSearch != "" ?
        <div className="w-100">
          {getCollapse(categoryData)}
        </div> : null


    groupIdx = !isNextDifferentGroup ? groupIdx + 1 : 0
    return [title, category, collapse]
  }
  );
}


export default NavCategories;