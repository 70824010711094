
import React from "react";

const MultipleFieldWrapper = ({ 
  title, 
  children, 
  className = "",
  isDefault = false, 
  values,
  valueKey,
  defaultAddValue = {},
  setFieldValue,
  onChangeDefaults, 
}) => {

  const onChangeUp = (idx) => {
    const newValues = [...values[valueKey]];
    const temp = newValues[idx];
    newValues[idx] = newValues[idx - 1];
    newValues[idx - 1] = temp;
    setFieldValue(valueKey, newValues);
  }

  const onChangeDown = (idx) => {
    const newValues = [...values[valueKey]];
    const temp = newValues[idx];
    newValues[idx] = newValues[idx + 1];
    newValues[idx + 1] = temp;
    setFieldValue(valueKey, newValues);
  }

  const onAdd = () => {
    const newValues = [...values[valueKey]];
    newValues.push(defaultAddValue);
    setFieldValue(valueKey, newValues);
  }

  const onDelete = (idx) => {
    const newValues = [...values[valueKey]];
    newValues.splice(idx, 1);
    setFieldValue(valueKey, newValues);
  }

  return <div className={"mt-4 " + className}>
    <ul className={"step step-form step-eicon-xs mb-0"}>
      <FieldWrapper className="mb-0">
        <div className="w-100 mt-1">
          <span
            class="d-flex mb-1 w-100 justify-content-start align-items-center" 
            data-toggle="collapse" 
            href={"#"}
            style={{ cursor: "default" }}
          >
              <span class="dropdown-header p-0 m-0">
                <span style={{ color: "#777", lineHeight: "2"}}>{title}</span>
              </span>
              {
                !isDefault &&
                  <button type="button" class="btn btn-link m-0 p-0 px-2" onClick={onChangeDefaults}>
                    Reestablecer  
                  </button>
              }
          </span>
          <hr class="mt-2 w-100" style={{ border: "none", height: "1.5px", background: "#e7eaf3" }} />
        </div>
        {
          children.map((child, idx) => (
            <div key={"multiple_" + idx} className="w-100">
              <ul className={"step step-form step-eicon-xs mb-0 sub w-100"}>
                <div class="d-flex align-items-center justify-content-between mb-1 mt-n1 w-100">
                  <div className={"d-flex"} style={{ width: "92%" }}>
                    <span 
                      className="mr-1"
                      style={{
                        fontSize: "0.72em", 
                        color: "#80858a", 
                        background:"#f0f0f0", 
                        height: "1rem", 
                        padding: "0rem 0.3rem", 
                        borderRadius: "50%"
                      }}
                    >
                      {idx + 1}
                    </span>
                    <div className="w-100">
                      {child}
                    </div>
                    <div className="d-flex align-items-start">
                      <button 
                        type="button" 
                        class="btn btn-link px-1"
                        onClick={() => onChangeUp(idx)}
                        disabled={idx === 0}
                        style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                      >
                        <i class={`fas fa-arrow-up text-primary ${idx === 0 ? "text-muted" : ""}`}/>
                      </button>
                      <button 
                        type="button" 
                        class="btn btn-link px-1"
                        onClick={() => onChangeDown(idx)}
                        disabled={idx == values[valueKey].length - 1}
                        style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                      >
                        <i class={`fas fa-arrow-down text-primary ${idx === values[valueKey].length - 1 ? "text-muted" : ""}`}/>
                      </button>
                      <button 
                        type="button" 
                        class="btn btn-link px-1"
                        onClick={() => onDelete(idx)}
                        style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                      >
                        <i class="fas fa-minus-circle text-danger"/>
                      </button>
                    </div>
                  </div>
                </div>
              </ul>
            </div>
          ))
        }
      </FieldWrapper>
      <FieldWrapper>
        <button 
          type="button" 
          class="btn pl-0 mt-1 pt-1"
          onClick={onAdd}
        >
          <div className="d-flex align-items-center">
            <i class="fas fa-plus"></i>&nbsp;Agregar  
          </div>
        </button>
      </FieldWrapper>
    </ul>
  </div>
}

const FieldWrapper = ({ children, className = "" }) => (
  <li className={"step-item " + className}>
    <div className="step-content-wrapper">
      <span className="step-icon step-icon-pseudo step-icon-soft-dark ml-2"></span>
      <div className="step-content mr-4 pt-0">
        {children}
      </div>
    </div>
  </li>
)

export { MultipleFieldWrapper, FieldWrapper };