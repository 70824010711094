import React from "react";
import { Loading } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const CategoriesView = ({
  Category,
  categories,
  activeCategory,
  scrollAmount,
  style,
  arrowsStyle,
  arrowContainerStyle,
  isTemplateCategoriesLoading,
  onSelectCategory
}) => (
  <div className="d-flex justify-content-center" style={style}>
    {isTemplateCategoriesLoading ? <Loading /> : (
      <ScrollArrow
        scrollAmount={scrollAmount}
        leftButtonStyle={arrowsStyle}
        rightButtonStyle={arrowsStyle}
        itemsContainerStyle={arrowContainerStyle}
      >
        {categories && categories.map((category, idx) => (
          <div
            key={category.id}
            onClick={() => onSelectCategory(category.id)}
            style={{ display: 'contents' }}
          >
            {Category({
              idx: idx,
              isActive: category.id == activeCategory,
              category: category
            })}
          </div>
        ))}
      </ScrollArrow>
    )}
  </div>
)

CategoriesView.propTypes = {};

export default CategoriesView;