import React, { useState, useEffect } from "react";
import { parseConditions } from "components/tables/util/filters";

const MultipleCondition = ({ 
  columns, 
  condition, 
  depth,
  showOperator, 
  onChange, 
  onDelete 
}) => {
  return <div className="d-flex justify-content-between align-items-center w-100 my-2">

    {
      showOperator
        ? <select 
            className="form-control mr-3" 
            style={{ width: "10%", minWidth: "70px" }}
            value={condition?.operator}
            onChange={e => onChange({ ...condition, operator: e.target.value })}
          >
            <option value="AND">Y</option>
            <option value="OR">O</option>
          </select> 
        : <span 
            className="mr-3"
            style={{ width: "10%", minWidth: "70px" }}
          >
            Donde
          </span>
    }
    
    <div 
      className="w-100 card card-body"
      style={{
        backgroundColor: `rgba(0, 0, 0, ${0.015 * depth})`,
      }}
    >
      <Filters
        columns={columns}
        isSubQuery={true}
        filters={condition.conditions}
        depth={depth}
        onChange={internalConditions => onChange({ ...condition, conditions: internalConditions })}
        onDelete={onDelete}
      />
    </div>

  </div>;
}

const SingleCondition = ({ columns, condition, showOperator, onChange, onDelete }) => {
  return <div className="d-flex justify-content-between align-items-center w-100">

    {
      showOperator
        ? <select 
            className="form-control mr-3" 
            style={{ width: "10%", minWidth: "70px" }}
            value={condition?.operator}
            onChange={e => onChange({ ...condition, operator: e.target.value })}
          >
            <option value="AND">Y</option>
            <option value="OR">O</option>
          </select> 
        : <span 
            className="mr-3"
            style={{ width: "10%", minWidth: "70px" }}
          >
            Donde
          </span>
    }

    <select 
      className="form-control my-2" 
      style={{width: "30%"}}
      onChange={e => onChange({ ...condition, column: e.target.value })}
      value={condition?.column}
    >
      {
        columns.map((column, index) => (
          <option key={column.name} value={column.name}>{column.label}</option>
        ))
      }
    </select>

    <select 
      className="form-control my-2"
      style={{width: "30%"}}
      onChange={e => onChange({ ...condition, condition: e.target.value })}
      value={condition?.condition}
    >
      <option value="=">Igual</option>
      <option value="<>">Diferente</option>
      <option value=">">Mayor que</option>
      <option value="<">Menor que</option>
      <option value=">=">Mayor o igual que</option>
      <option value="<=">Menor o igual que</option>
      <option value="contains">Contiene</option>
      <option value="not_contains">No contiene</option>
    </select>

    <input 
      type="text" 
      style={{width: "30%"}}
      className="form-control my-2"
      defaultValue={condition?.value}
      onBlur={e => onChange({ ...condition, value: e.target.value })}
    />

    <button
      className="btn ml-2 text-danger"
      onClick={onDelete}
    >
      <i className="fas fa-trash"></i>
    </button>

  </div>;
}

const Filters = ({ 
  columns, 
  query,
  filters = [],
  depth = 0,
  isSubQuery = false,
  onChangeQuery = () => {}, 
  onChangeFilters = () => {},
  onChange = () => {},
  onDelete = () => {},
  onClose = () => {}
}) => {

  const [conditions, setConditions] = useState([]);
  const [loaded, setLoaded] = useState(false);

  const _onChange = (conditions) => {
    let parsedQuery = parseConditions(conditions);
    onChangeQuery(parsedQuery);
    onChangeFilters(conditions);
  }

  const onAddSingleCondition = (conditions) => {
    const newConditions = [...conditions];
    if(newConditions.length == 0) newConditions.push({ column: columns[0].name, condition: "=", value: "", type: "SINGLE" });
    else newConditions.push({ operator: "AND", column: columns[0].name, condition: "=", value: "", type: "SINGLE" });
    return newConditions;
  }

  const onAddMultipleCondition = (conditions) => {
    const newConditions = [...conditions];
    if(newConditions.length == 0) newConditions.push({ type: "MULTIPLE", conditions: [] });
    else newConditions.push({ operator: "AND", type: "MULTIPLE", conditions: [] });
    return newConditions;
  }

  const onChangeCondition = (index, newCondition) => {
    const newConditions = [...conditions];
    newConditions[index] = newCondition;
    setConditions(newConditions);
  }

  const onDeleteCondition = (index) => {
    const newConditions = [...conditions];
    newConditions.splice(index, 1);
    setConditions(newConditions);
  }

  useEffect(() => {
    onChange(conditions);
  }, [conditions]);

  useEffect(() => {
    if(!loaded) {
      setConditions(filters?.length > 0 ? filters : onAddSingleCondition([]));
      setLoaded(true);
    }
  }, [filters]);

  return (
    <div>

      {
        !isSubQuery && 
          <div className="d-flex justify-content-between align-items-center w-100 mb-2">
            <h3>Filtros</h3>
            <div className="d-flex mt-2">
              <button 
                className="btn btn-sm btn-outline-primary mr-3"
                onClick={() => setConditions(onAddSingleCondition(conditions))}
              >
                <i className="fas fa-plus"></i>
                Agregar condición 
              </button>
              {/* <button 
                className="btn btn-sm btn-outline-primary"
                onClick={() => setConditions(onAddMultipleCondition(conditions))}
              >
                <i className="fas fa-plus"></i>
                Agregar condición múltiple
              </button> */}
            </div>
          </div>
      }

      {
        isSubQuery &&
          <div className="d-flex justify-content-end align-items-center w-100">
            <button 
              class="btn mr-3"
              type="button" 
              id="dropdownMenuButton" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"
            >
              <i className="fas fa-plus"></i>
            </button>
            <div class="dropdown-menu">
              <span 
                class="dropdown-item" 
                style={{cursor: "pointer"}}
                onClick={() => setConditions(onAddSingleCondition(conditions))}
              >
                <i className="fas fa-plus"></i> Agregar condición
              </span>
              <span
                class="dropdown-item"
                style={{cursor: "pointer"}}
                onClick={() => setConditions(onAddMultipleCondition(conditions))}
              >
                <i className="fas fa-plus"></i> Agregar condición múltiple
              </span>
            </div>
            <button
              className="btn"
              onClick={onDelete}
            >
              <i className="fas fa-trash"></i>
            </button>
          </div>
      }

      {
        conditions.map((condition, index) => (
          condition.type == "SINGLE"
            ? <SingleCondition 
                key={index} 
                columns={columns}
                condition={condition}
                showOperator={index > 0}
                onDelete={() => onDeleteCondition(index)}
                onChange={newCondition => onChangeCondition(index, newCondition)}
              />
            : <MultipleCondition
                key={index}
                columns={columns}
                condition={condition}
                depth={depth + 1}
                showOperator={index > 0}
                onDelete={() => onDeleteCondition(index)}
                onChange={newCondition => onChangeCondition(index, newCondition)}
              />
        ))
      }

      {
        !isSubQuery &&
          <div className="d-flex justify-content-end align-items-center w-100 mt-3">
            <button
              className="btn btn-primary mr-3"
              onClick={() => {
                _onChange(conditions);
                onClose();
              }}
            >
              Aplicar filtros
            </button>
            <button 
              className="btn btn-outline-danger"
              onClick={() => {
                _onChange([]);
                setConditions([]);
              }}
            >
              Limpiar filtros
            </button>
          </div>
      }

    </div>
  );

}

export default Filters;