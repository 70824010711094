import React from "react";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/schema_form/fields/rich_text/RichText.view";

function RichText({
  name,
  label,
  value = "",
  values,
  required,
  rows=5,
  hidden,
  disabled,
  caption,
  helpMessage,
  placeholder,
  singleLine,
  showStyles,
  flowId,
  setFieldValue
}) {
  const pageId = sessionStorage.getItem("page_" + flowId)
  const reqNodes = useQuery(`{
    flowNodes {
      inputValue
      outputValue
      action {
        name
        uiSettings
        app {
          name
          uiSettings
        }
      }
    }
  }`, `flow.id=${flowId} AND action.is_referenceable=true ${pageId ? "AND page.id=" + pageId : ""}`)

  const reqCollections = useQuery(`{
    collections {
      name
      schema
    }
  }`, `flow.id=${flowId}`)

  const reqDataTypes = useQuery(`{
    appDatatypes {
      name
      structure
    }
  }`, '')

  if (reqNodes.loading || reqCollections.loading || reqDataTypes.loading) return <Loading size={27} />;
  if (reqNodes.error || reqCollections.error || reqDataTypes.error) return "Error";

  const { flowNodes = [] } = reqNodes.data;
  // const { collections = [] } = reqCollections.data;
  const collections = [] // TODO ommit temp collections
  const { datatypes = []} = reqDataTypes.data;

  const onChangeValue = (newValue) => {
    setFieldValue(name, newValue)
  }

  return (
    <View
      nodes={flowNodes}
      collections={collections}
      datatypes={datatypes}
      name={name}
      label={label}
      required={required}
      caption={caption}
      helpMessage={helpMessage}
      placeholder={placeholder}
      singleLine={singleLine}
      rows={rows}
      showStyles={showStyles}
      values={values}
      initialValue={value}
      setFieldValue={setFieldValue}
      onChangeValue={onChangeValue}
    />
  );
}

export default RichText;