import React, { useState, useEffect, useRef } from "react";
import swal from "sweetalert";
import { SET_EXECUTION } from "seed/gql/queries";
import { useQuery, useDetail, useSet } from "seed/gql";
import { useGetCall, usePost } from "seed/api";
import { Loading } from "seed/helpers";
import { showExecutionToast } from "components/util/flow";
import View from "components/visual_mode/PresentationMode.view";

function PresentationMode({ match }) {

  const token = match.params?.token;
  const userId = sessionStorage.getItem("id");
  const roomName = `EXECUTION_STATUS_${userId}`;
  const [reset, setReset] = useState(0);
  const [isTutorialFinished, setIsTutorialFinished] = useState(null)
  const [isEditModalShown, setIsEditModalShown] = useState(false);
  const [isTemplatesShown, setIsTemplatesShown] = useState(false);
  const [isShareShown, setIsShareShown] = useState(false);

  const [isAuth, setIsAuth] = useState(false);

  const [callSetRead, reqSetRead] = useSet(SET_EXECUTION);

  const reqFlow = useQuery(`{
    flows {
      name
      description
      token
      presentationSettings
      settings
    }
  }`, `token=${token}`);

  const reqUser = useDetail(`{
    user {
      firstName
      lastName
      email
      license {
        name
      }
      accountSettings
    }
  }`, userId)

  const [callAuth] = useGetCall("/auth/user", "", {
    onCompleted: (data) => {
      setIsAuth(true)
      reqFlow.refetch();
      reqUser.refetch();
    },
    onError: () => {
      sessionStorage.clear();
      localStorage.clear();
      postMessage({ type: "logout" });
      window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
    }
  })

  const { user = null } = reqUser.data || {};
  const { flows = [] } = reqFlow.data;

  useEffect(() => {
    if (localStorage.getItem("id") != null) { //Preload data from localStorage
      sessionStorage.setItem("token", localStorage.getItem("token"));
      sessionStorage.setItem("id", localStorage.getItem("id"));
    }
    callAuth({ user_id: sessionStorage.getItem("id") });
  }, [token]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    
    if(!flow?.settings?.theme) return;
    sessionStorage.setItem("show_helps", flow.settings.show_helps);

    const currentTheme = sessionStorage.getItem("theme") ?? "{}";
    const theme = JSON.stringify(flow.settings?.theme ?? {});
    if(currentTheme != theme) {
      sessionStorage.setItem("theme", theme);
      window.location.reload();
    }

  }, [flow]);

  if (!isAuth) return null;
  if (reqFlow.loading || reqUser.loading) return <Loading />;
  if (reqFlow.error || reqUser.error) return "Error";
  if (flows.length == 0) window.location.replace("/app");
  const flow = flows[0];

  const onResetPresentation = () => {
    setReset((prev) => prev + 1);
    setIsEditModalShown(false);
    setIsTemplatesShown(false);
    setIsShareShown(false);
  }

  const onCloseEditModal = () => {
    setIsEditModalShown(false);
  }

  const onCloseTemplatesModal = () => {
    setIsTemplatesShown(false);
  }

  const onCloseShareModal = () => {
    setIsShareShown(false);
  }

  const onExecutionMessage = (data) => {

    const status = data.data.status;
    const executionId = data.data.execution_id;
    const message = data.data.message;
    
    callSetRead({ id: executionId, isRead: true });
    showExecutionToast({ status, executionId, message });

  }

  return <div className="content container-fluid px-1 px-md-3 py-3 py-md-4">
    <View
      user={user}
      flow={flow}
      reset={reset}
      roomName={roomName}
      isEditModalShown={isEditModalShown}
      isTemplatesShown={isTemplatesShown}
      isShareShown={isShareShown}
      isTutorialFinished={isTutorialFinished}
      onCloseEditModal={onCloseEditModal}
      onCloseTemplatesModal={onCloseTemplatesModal}
      onCloseShareModal={onCloseShareModal}
      onResetPresentation={onResetPresentation}
      onExecutionMessage={onExecutionMessage}
    />;
  </div>
}

export default PresentationMode;