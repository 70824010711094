import React from "react";
import { Link } from "react-router-dom";
import ExecModeCharts from "components/visual_mode/panels/Charts";
import ExecModeTable from "components/visual_mode/panels/Data";
import ExecModeForm from "components/visual_mode/panels/Form";
import HelpMessage from 'components/util/helpers/HelpMessage';
import Tour from "components/misc/tour/Tour";

const ExecModeView = ({
  flow,
  execModeSettings,
  activeTab,
  chartsRef,
  dataRef,
  isShareMode,
  isTutorialShown,
  currentTutorialStep,
  tutorialSteps,
  setActiveTab,
  onExecuteNode,
  onChangeExecMode,
}) => (
  <Tour
    steps={tutorialSteps}
    startAt={currentTutorialStep}
    show={isTutorialShown && !isShareMode}
    tutorialName={"exec_mode"}
  >
    <div
      id="exec-mode"
      className={"card animate__animated animate__fadeIn animate__faster " +
        (!isShareMode ? "py-4 px-5 " : "py-5 px-6")}
      style={{
        height: !isShareMode ? "calc(100% - 90px)" : "100%",
        border: "1px solid #e7eaf3",
        background: "#f8fafe",
        borderRadius: "0.375rem",
      }}
    >

      {
        execModeSettings.length == 0
          ? <div className="d-flex justify-content-center align-items-center h-100 pb-5">
            <div className="h4 ml-1 d-flex flex-column justify-content-center align-items-center mb-5">
              <h3 className="text-muted text-center"
                style={{ width: "25rem", lineHeight: "1.7" }}>
                Personaliza tu proceso para explorar el modo visual
              </h3>
              <button
                type="button"
                className="btn btn-lighter-white mt-2"
                style={{ width: "10rem" }}
                onClick={() => onChangeExecMode(false)}
              >
                <i className="fas fa-edit mr-1"></i> Editar proceso
              </button>
            </div>
          </div>

          : <>

            {execModeSettings.length > 1
              ? <div className="d-flex justify-content-start pb-4">
                {
                  execModeSettings.length > 1 && execModeSettings.map((execModeSetting, index) => (
                    <a
                      key={"exec-mode-tab-" + index}
                      style={{
                        maxWidth: "16rem",
                      }}
                      className={"btn exec-mode-tab d-flex align-items-center mr-2 px-3 py-0 " +
                        (activeTab === index ? "active" : "")}
                      onClick={() => setActiveTab(index)}
                    >
                      <span
                        className="text-truncate py-2"
                      >
                        {execModeSetting.title}
                      </span>
                    </a>
                  ))
                }
              </div>
              : null
            }

            <div className="d-flex justify-content-between align-items-start">
              <div className="">
                <div className="h1 ml-1 d-flex">
                  {
                    execModeSettings.length > 1
                      ? execModeSettings[activeTab]?.title
                      : flow.name
                  }
                </div>
                <div className="h4 ml-1 font-weight-light" 
                  style={{marginTop: "-0.15rem", color: "#555a60"}}>
                  {flow.description}
                </div>
              </div>
              <div className="d-flex">

                {
                  function () {

                    const execModeSetting = execModeSettings[activeTab] ?? {};
                    const leftPanel = execModeSetting.left_panel ?? "";
                    const rightPanel = execModeSetting.right_panel ?? "";

                    if (leftPanel == "ACTIVITY_CHART" || rightPanel == "ACTIVITY_CHART")
                      return;

                    return <HelpMessage message="Visualiza la actividad de tu proceso">
                      <Link to="/flow/activity" className="btn btn-lighter-white mr-2"
                        style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}>
                        <i className="fas fa-chart-line mr-1"></i> Actividad
                      </Link>
                    </HelpMessage>

                  }()
                }


                {!isShareMode ?
                  <HelpMessage message="Configura las opciones de visualización">
                    <Link
                      id="exec-mode-settings"
                      to="/exec_mode/settings"
                      className="btn btn-outline-primary"
                      style={{ paddingRight: "0.85rem", paddingLeft: "0.85rem" }}
                    >
                      <i class="fas fa-edit mr-1"></i> Personalizar
                    </Link>
                  </HelpMessage>
                  : null}

                {
                  (execModeSettings[activeTab]?.actions??[]).map((action, index) => 
                    <button
                      key={"exec-mode-action-" + index}
                      type="button"
                      className="btn btn-success px-3 mb-0 ml-2"
                      onClick={() => onExecuteNode(action.id)}
                    >
                      <i className="fas fa-play mr-1"></i> {action.title??"Ejecutar proceso"}
                    </button>
                  )
                }

              </div>
            </div>

            <div
              id="exec-mode-content"
              className="row pt-3 h-100"
              style={{ overflowY: "auto" }}
            >
              {
                function () {

                  const execModeSetting = execModeSettings[activeTab] ?? {};
                  const leftPanel = execModeSetting.left_panel ?? "";
                  const rightPanel = execModeSetting.right_panel ?? "";

                  const getPanel = (panel, isFullWidth = false, isLeftPanel = false) => {

                    if (panel === "FORM")
                      return <ExecModeForm
                        flow={flow}
                        isLeftPanel={isLeftPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                    if (panel === "TABLE")
                      return <ExecModeTable
                        ref={dataRef}
                        flow={flow}
                        isLeftPanel={isLeftPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                    if (panel === "ACTIVITY_CHART")
                      return <ExecModeCharts
                        ref={chartsRef}
                        flow={flow}
                        isFullWidth={isFullWidth}
                        isLeftPanel={isLeftPanel}
                        execModeSetting={execModeSetting}
                        onExecuteNode={onExecuteNode}
                      />

                  }

                  if (leftPanel == "EMPTY" || rightPanel == "EMPTY")
                    return <div className="col-lg-12 h-100">
                      <div
                        className="border rounded w-100 h-100 bg-white"
                        style={{
                          maxHeight: "100%",
                          overflowY: "hidden",
                        }}
                      >
                        {getPanel(leftPanel, true, true)}
                        {getPanel(rightPanel, true, true)}
                      </div>
                    </div>

                  return <>
                    <div className="col-lg-6 h-100 mt-3 mt-lg-0 pr-2">
                      <div className="border rounded w-100 h-100 bg-white">
                        {getPanel(leftPanel, false, true)}
                      </div>
                    </div>
                    <div className="col-lg-6 h-100 mt-3 mt-lg-0 pl-2">
                      <div className="border rounded w-100 h-100 bg-white">
                        {getPanel(rightPanel, false, false)}
                      </div>
                    </div>
                  </>

                }()
              }
            </div>
          </>

      }

    </div>
  </Tour>
)

export default ExecModeView;