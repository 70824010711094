import React from "react";

const icons = {
  "conditional": "fas fa-diagram-project",
  "note": "fas fa-sticky-note",
  "pipe": "fas fa-object-group",
  "node_annotation": "fas fa-code-branch",
  "text": "fas fa-font"
}

const ActionItem = ({
  style = {},
  customTitle = null,
  type,
  actions,
  actionName,
  iconClassName,
  className,
  onDragStart,
  onClickAction
}) => {

  const action = actions.find((action) => action.name == actionName);
  if (!action) return <></>;

  return (
    <a className={className} draggable style={{ cursor: "pointer", ...style }}
      onDragStart={(event) => onDragStart(event, type, action.id)}
      onClick={() => onClickAction(action.id, type)}>
      {<i className={(icons[actionName] ?? "") + " " + (iconClassName ?? "mr-2")}></i>}
      {customTitle ? (
        customTitle
      ) : (
        action.uiSettings?.general?.title ?? action.uiSettings?.node?.title
      )}
      {
        actionName == "node_annotation" ? <small className="ml-1">(Visual)</small> : null
      }
    </a>
  )

}


export default ActionItem;