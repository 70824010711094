/* eslint-disable max-len */
import React from "react";
import { warningMapper, errorMapper } from "components/flow/board/nodes/Node.lib";

function DropdownErrors({ errors, warnings, actionUiSettings }) {

	return (
		<div class="dropdown-menu dropdown-card" style={{ width: "200px" }}>
			<div className="card-body px-4 py-3 text-center">

				{errors.map((error) => (
					<div key={error.id} className="d-flex mb-1">
						<div> <i class="fa-solid fa-circle-xmark text-danger"></i> </div>
						<div>
							<p className="px-2 mb-0 text-left" style={{ fontSize: "0.7rem" }}>
								<div dangerouslySetInnerHTML={{ __html: errorMapper(error, actionUiSettings) }} />
							</p>
						</div>
					</div>
				))}

				{warnings.map((warning) => (
					<div key={warning.id} className="d-flex mb-1">
						<div> <i class="fa-solid fa-triangle-exclamation text-warning"></i> </div>
						<div>
							<p className="px-2 mb-0 text-left" style={{ fontSize: "0.7rem" }}>
								<div dangerouslySetInnerHTML={{ __html: warningMapper(warning, actionUiSettings) }} />
							</p>
						</div>
					</div>
				))}
				
			</div>
		</div>
	);

}

export default DropdownErrors;
