import React from 'react';

const FileViewer = ({ url }) => {
  return (<div className='card'>
    <div class="card-header">
      <h3 class="mb-0">{"Descargar archivo"}</h3>
    </div>
    <div class="card-body py-4">
      <a className='btn btn-primary w-100' href={url} target="_blank" rel="noreferrer" >Descargar</a>
    </div>
  </div>)

}

export default FileViewer;