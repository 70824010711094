import React, { useState } from "react";

const MenuButton = ({ 
  editor,
  action = "",
  actionProps = {}, 
  icon = "",
  iconStyle = {}, 
  onClick = () => {}
}) => (
  <button
    type="button"
    className={"btn btn-sm m-0 p-0"}
    style={{
      height: "30px",
    }}
    onClick={onClick}
  >
    <i 
      className={icon + (editor.isActive(action, actionProps) ? ' text-primary' : '')}
      style={iconStyle}
    />
  </button>
)

const MenuBar = ({ editor }) => {

  const [showTableMenu, setShowTableMenu] = useState(false);
  if (!editor) return null;

  const actions = [
    [
      {
        action: "bold",
        icon: "fas fa-bold",
        onClick: () => editor.chain().focus().toggleBold().run()
      },
      {
        action: "italic",
        icon: "fas fa-italic",
        onClick: () => editor.chain().focus().toggleItalic().run()
      },
      {
        action: "strike",
        icon: "fas fa-strikethrough",
        onClick: () => editor.chain().focus().toggleStrike().run()
      }
    ],
    [
      {
        action: "heading",
        actionProps: { level: 1 },
        icon: "fas fa-heading",
        iconStyle: { fontSize: "1.1em" },
        onClick: () => editor.chain().focus().toggleHeading({ level: 1 }).run()
      },
      {
        action: "heading",
        actionProps: { level: 2 },
        icon: "fas fa-heading",
        iconStyle: { fontSize: "0.9em" },
        onClick: () => editor.chain().focus().toggleHeading({ level: 2 }).run()
      }
    ],
    [
      {
        action: "bullet_list",
        icon: "fas fa-list-ul",
        onClick: () => editor.chain().focus().toggleBulletList().run()
      },
      {
        action: "ordered_list",
        icon: "fas fa-list-ol",
        onClick: () => editor.chain().focus().toggleOrderedList().run()
      }
    ],
    [
      {
        action: "text_align",
        actionProps: { textAlign: 'left' },
        icon: "fas fa-align-left",
        onClick: () => editor.chain().focus().setTextAlign('left').run()
      },
      {
        action: "text_align",
        actionProps: { textAlign: 'center' },
        icon: "fas fa-align-center",
        onClick: () => editor.chain().focus().setTextAlign('center').run()
      },
      {
        action: "text_align",
        actionProps: { textAlign: 'right' },
        icon: "fas fa-align-right",
        onClick: () => editor.chain().focus().setTextAlign('right').run()
      },
      {
        action: "text_align",
        actionProps: { textAlign: 'justify' },
        icon: "fas fa-align-justify",
        onClick: () => editor.chain().focus().setTextAlign('justify').run()
      }
    ],
    [
      {
        action: "link",
        icon: "fas fa-link",
        onClick: () => {
          const url = window.prompt('Enter the URL')
          if (url) editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run()
        }
      },
      {
        action: "image",
        icon: "fas fa-image",
        onClick: () => {
          // request file, read image and convert to base64
          const fileInput = document.createElement('input');
          fileInput.type = 'file';
          fileInput.accept = 'image/*';
          fileInput.onchange = (e) => {
            const file = e.target.files[0];
            const reader = new FileReader();
            reader.onload = (e) => {
              const url = e.target.result;
              editor.chain().focus().setImage({ src: url }).run();
            }
            reader.readAsDataURL(file);
          }
          fileInput.click();
        }
      },
      {
        action: "table",
        icon: "fas fa-table",
        onClick: () => setShowTableMenu(!showTableMenu),
        subActions: [
          {
            title: "Tabla",
            actions: [
              {
                action: "insert_table",
                icon: "fas fa-table",
                label: "Insertar",
                onClick: () => {
                  editor.chain().focus().insertTable({ rows: 2, cols: 2, border: 'table-border' }).run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "delete_table",
                icon: "fas fa-trash-alt",
                label: "Eliminar",
                onClick: () => {
                  editor.chain().focus().deleteTable().run();
                  setShowTableMenu(false);
                }
              },
              {
                action: "toggle_border",
                icon: "fas fa-border-all",
                label: "Cambiar borde",
                onClick: () => {
                  const toggleBorder = editor.isActive('table', { border: 'table-border' });
                  editor.chain().focus().setTableBorder(!toggleBorder ? 'table-border' : 'table-borderless').run()
                  setShowTableMenu(false)
                }
              }
            ]
          },
          {
            title: "Columnas",
            actions: [
              {
                action: "add_column_before",
                icon: "fas fa-arrow-left",
                label: "Añadir antes",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().addColumnBefore().run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "add_column_after",
                icon: "fas fa-arrow-right",
                label: "Añadir después",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().addColumnAfter().run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "delete_column",
                icon: "fas fa-trash-alt",
                label: "Eliminar",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().deleteColumn().run()
                  setShowTableMenu(false)
                }
              },
            ]
          },
          {
            title: "Filas",
            actions: [
              {
                action: "add_row_before",
                icon: "fas fa-arrow-up",
                label: "Añadir antes",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().addRowBefore().run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "add_row_after",
                icon: "fas fa-arrow-down",
                label: "Añadir después",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().addRowAfter().run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "delete_row",
                icon: "fas fa-trash-alt",
                label: "Eliminar",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().deleteRow().run()
                  setShowTableMenu(false)
                }
              },
            ]
          },
          {
            title: "Celdas",
            actions: [
              {
                action: "merge_cells",
                icon: "fas fa-compress-arrows-alt",
                label: "Unir",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().mergeCells().run()
                  setShowTableMenu(false)
                }
              },
              {
                action: "split_cell",
                icon: "fas fa-expand-arrows-alt",
                label: "Dividir",
                activeRequired: true,
                onClick: () => {
                  editor.chain().focus().splitCell().run()
                  setShowTableMenu(false)
                }
              }
            ] 
          }
        ]
      }
    ],
    [
      {
        action: "undo",
        icon: "fas fa-undo",
        onClick: () => editor.chain().focus().undo().run()
      },
      {
        action: "redo",
        icon: "fas fa-redo",
        onClick: () => editor.chain().focus().redo().run()
      }
    ]
  ]

  return (
    <div className="d-flex flex-fill flex-wrap justify-content-between border px-2 py-2">

        {
          actions.map((group, idx) => (
            <div key={idx} className="d-flex">
              {
                group.map((button, idx) => (
                  <div
                    key={idx}
                    className="mr-2"
                  >

                    <MenuButton
                      editor={editor}
                      action={button.action}
                      actionProps={button.actionProps}
                      icon={button.icon}
                      iconStyle={button.iconStyle}
                      onClick={button.onClick}
                    />

                    {
                      button.subActions && showTableMenu && (
                        <div className="d-flex flex-column editor-submenu">
                          {
                            button.subActions.map((subSection, idx) => (
                              <div key={idx} >
                                <div className="px-2 pt-2 pb-1 editor-submenu-title">
                                  {subSection.title}
                                </div>
                                {
                                  subSection.actions.map((subButton, idx) => (
                                    <div 
                                      key={idx}
                                      className="px-2 py-0 editor-submenu-item"
                                      style={{
                                        cursor: "pointer",
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                      }}
                                      onClick={subButton.onClick}
                                    >
                                      <span className="mr-2">
                                        <MenuButton
                                          editor={editor}
                                          action={subButton.action}
                                          actionProps={subButton.actionProps}
                                          icon={subButton.icon}
                                          iconStyle={subButton.iconStyle}
                                          onClick={subButton.onClick}
                                        />
                                      </span>
                                      <span>
                                        {subButton.label}
                                      </span>
                                    </div>
                                  ))
                                }
                              </div>
                            ))
                          }
                        </div>
                      )
                    }

                  </div>
                ))
              }
            </div>
          ))
        }
    </div>
  )
}

export default MenuBar;