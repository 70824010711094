import React, { useState } from "react";
import BaseField from "components/schema_form/fields/custom_fields/BaseField";
import { usePost } from "seed/api";
import { Field } from "formik";
import { Modal } from "seed/helpers";

const ButtonField = ({
    name,
    label,
    value,
    values,
    actionId,
    flowId,
    nodeId,
    required,
    caption,
    helpMessage,
    sectionIdx,
    buttonType,
    buttonAction,
    buttonCallback,
    buttonText,
    buttonStyle,
    setFieldValue
}) => {

    const userId = sessionStorage.getItem("id");
    const [showPreview, setShowPreview] = useState(false)
    const [callCall, reqCall] = usePost(`/call/${actionId}/${buttonAction}`, {
        onCompleted: (data) => {
            if(buttonType == "call") setFieldValue(name, true)
            else if(buttonType == "preview") setFieldValue(name, data)
        }
    })

    const onClick = () => {
        if (buttonType == "link"){
            window.location.href = buttonAction
            setFieldValue(name, true)
        }
        if (buttonType == "call")
            callCall({
                user_id: parseInt(userId),
                flow_id: parseInt(flowId),
                node_id: parseInt(nodeId),
                values: values,
            })
        if (buttonType == "preview") {
            callCall({
                user_id: parseInt(userId),
                flow_id: parseInt(flowId),
                node_id: parseInt(nodeId),
                values: values,
            })
            setShowPreview(true)
        }
        if (buttonType == "callback")
            buttonCallback(sectionIdx, values)
    }

    let buttonClass = "btn-outline-primary"
    if (buttonStyle == "filled") buttonClass = "btn-primary"
    if (buttonStyle == "soft") buttonClass = "btn-soft-primary"
    if (buttonStyle == "link") buttonClass = "btn-link"
    

    return (
        <BaseField
            name={name}
            label={label}
            required={required}
            values={values}
            caption={caption}
            helpMessage={helpMessage}
            enableComments={false}
            setFieldValue={setFieldValue}
        >
            {showPreview && <Modal
                title={"Vista previa"}
                onClose={() => setShowPreview(false)}
                width={800}
                height={600}
                component={() => 
                    <div 
                        style={{
                            backgroundColor: "white",
                            width: "100%",
                            height: "100%",
                            overflowY: "auto",
                            padding: "20px"
                        }} 
                        dangerouslySetInnerHTML={{ __html: value }}
                    />
                }
            >
            </Modal>}
            <button type={"button"} className={"btn btn-sm " + buttonClass} onClick={onClick}>
                {reqCall.loading ? "Cargando..." : 
                reqCall.error ? "Ha ocurrido un error, prueba de nuevo" : 
                `${value && required ? "✓ " : ""} ${buttonText}`}
            </button>
            <Field type={"text"} name={name} required={required} style={{opacity: "0", width: "1px"}} />
        </BaseField>
    )
}

export default ButtonField;