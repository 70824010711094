import React from "react";
import { useGet } from "seed/api";
import * as chartOptions from "components/start/usage/Usage.lib.chartOptions";
import * as lib from "components/start/executions/Executions.lib.format";
import View from "components/start/executions/Executions.view";
import moment from "moment";
import 'moment/locale/es';


const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

function Executions({ 
  flow = {}, 
  showList = true,
  showCharts = true,
  styles = {
    background: "#f8fafe",
    border: "1px solid #E7EAF3",
  }
}) {

  const userId = sessionStorage.getItem("id");
  const now = new Date();
  const date15DaysAgo = new Date();
  date15DaysAgo.setDate(now.getDate() - 15);
  const endDate = formatDate(now);
  const startDate = formatDate(date15DaysAgo);

  const reqHeatmapData = useGet("/executions/get_executions_heatmap", { "user_id": userId });

  const reqExecutionsUsage = useGet("/executions/get_executions_usage",
    { "user_id": userId, "start_date": startDate, "end_date": endDate })
  const reqExecutionsByApp = useGet("/executions/get_executions_by_app",
    { "user_id": userId, "start_date": startDate, "end_date": endDate })

  if (reqExecutionsUsage.loading || reqExecutionsByApp.loading || reqHeatmapData.loading) return <div style={{ padding: "0.72rem 0rem" }} />

  const response = reqHeatmapData.data?.executions ?? {};
  const executions = response?.data ?? {};
  const headers = response?.header ?? [];  

  const executionsByApp = reqExecutionsByApp.data?.executions ?? [];
  const topExecutionsByApp = executionsByApp.slice(0, 5);

  const formattedExecutions = [];
  Object.keys(executions).forEach((key) => {
    formattedExecutions.push({
      "name": lib.getWeekNameByNumber(lib.getWeekDayNum(key)),
      "weekday": lib.getWeekDayNum(key),
      "data": executions[key].map((execution, idx) => {
        return {
          x: headers.length > idx ? moment(headers[idx], "YYYY-MM-DD").format('D MMMM') : `Sem ${idx}`,
          y: execution
        }
      })
    })
  })
  formattedExecutions.sort((a, b) => b["weekday"] - a["weekday"]);
  const heatMapExecutions = {
    "data": formattedExecutions,
    "options": chartOptions.heatmapChartOptions
  }
  heatMapExecutions["options"]["title"] = {
    text: "Actividad de los últimos 30 dias",
    style: { fontFamily: "sans-serif", fontWeight: 700 }
  };

  const barChartExecutions = {
    "data": [{
      "name": "Actividad",
      "data": topExecutionsByApp.map(record => record.executions)
    }],
    "options": chartOptions.barChartOptions
  }
  barChartExecutions["options"]["title"] = {
    text: "Actividad por app de los últimos 15 dias",
    style: { fontFamily: "sans-serif", fontWeight: 700 }
  };
  barChartExecutions["options"]["xaxis"] = {
    "categories": topExecutionsByApp.map(record => record["app"]["label"])
  }

  return (
    <View
      flow={flow}
      showCharts={showCharts}
      showList={showList}
      heatMapExecutions={heatMapExecutions}
      barChartExecutions={barChartExecutions}
      styles={styles}
    />
  );
}

export default Executions;