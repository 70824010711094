import React, { useState, useEffect } from 'react';
import { useWS } from 'seed/ws';
import { Modal } from "seed/helpers";
import { usePost } from 'seed/api';
import SchemaForm from 'components/schema_form/SchemaForm';
import { parseCollectionOptions } from 'components/misc/apps/forms/util';

const Form = ({ flow }) => {

  const userId = sessionStorage.getItem("id");
  const [callSendForm] = usePost("/apps/forms/send_form");
  const [formData, setFormData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [lastExecutionId, setLastExecutionId] = useState(null);
  const [_] = useWS("SHOW_FORM_" + userId,
    {
      onMessageReceived: (message) => {
        const pageId = sessionStorage.getItem("page_" + flow.id);
        if (message.data?.page_id == pageId) {
          setFormData(message.data);
          setShowModal(true);
        }
      },
    });

  const [callCollections, reqCollections] = usePost("/collections/get_data_by_queries");
  const collections = reqCollections?.data;

  let schema = formData?.schema;
  const formTitle = formData?.title;
  const extraData = formData?.extra_data ?? {}
  const nodeId = formData?.node_id;
  const executionId = formData?.execution_id;
  const appId = formData?.app_id;
  const actionId = formData?.action_id;
  const flowId = formData?.flow_id;

  const collectionReferences = (schema ?? [])?.map((field) => field.collection_options).flat();
  const queries = collectionReferences.map((ref) => ref?.query).filter((query) => query != null);

  if (collections != null && schema != null)
    schema = schema.map((field) =>
      field.collection_options != null
        ? parseCollectionOptions(collections, field)
        : field
    );

  useEffect(() => {
    if (executionId != lastExecutionId) {
      setLastExecutionId(executionId);
      callCollections({ queries, execution_id: executionId });
    }
  }, [schema, collections, queries, executionId, lastExecutionId]);

  if (!showModal) return null;

  const onFormSubmit = (data) => {
    callSendForm({
      response: data,
      node_id: nodeId,
      user_id: userId,
      execution_id: executionId,
      ...extraData
    });
    setShowModal(false);
  }

  const SchemaComponent = () =>
    <div class="card">
      <div class="card-header">
        <h3 class="mb-0">{formTitle != "" ? formTitle : "Completar Formulario"}</h3>
      </div>
      <div class="card-body">
        <SchemaForm 
          onSubmit={onFormSubmit} 
          schema={schema} 
          appId={appId}
          actionId={actionId}
          flowId={flowId}
          nodeId={nodeId}
          submitText="Enviar" 
        />
      </div>
    </div>

  return <Modal
    id="form-modal"
    animation="zoomIn animate__fast"
    width={600}
    height={450}
    component={SchemaComponent}
    onClose={() => setShowModal(false)}
  />

}

export default Form;