import React, { useEffect, useState } from "react";
import { useGetCall, usePost } from "seed/api";
import { useQuery } from "seed/gql";
import { useHistory } from "react-router-dom";
import View from "components/flow/forms/executions/Execution.view";


function Execution({ executionId }) {

  const history = useHistory();
  const [executionSteps, setExecutionSteps] = useState({});
  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";

  const reqExecutions = useQuery(`{
    executions {
      id
      status
      startDate
      endDate
      isRead
      exception
      settings
      flow {
        id
        name
      }
    }
  }`, `id=${executionId}`);

  const [callResume, reqResume] = usePost("/executions/resume_execution", {
    onCompleted: (data) => {
      reqExecutions.refetch();
    }
  });

  const [callCancel, reqCancel] = usePost("/executions/cancel_execution", {
    onCompleted: (data) => {
      reqExecutions.refetch();
    }
  });

  useEffect(() => {
    reqExecutions.refetch();
  }, [executionId])

  if(reqExecutions.loading) return <div style={{ padding: "0.72rem 0rem" }} />
  const { executions = [] } = reqExecutions.data || {};

  let execution = null;
  if(executions.length > 0) execution = executions[0];

  const onResumeExecution = () => {
    history.replace(`/flow/${execution?.flow?.id}/flow?resume=${execution?.id}`);
    callResume({ execution_id: execution?.id });
  }

  const onCancelExecution = () =>
    callCancel({ execution_id: execution?.id });

  return (
    <View
      execution={execution}
      executionId={executionId}
      isDeveloperMode={isDeveloperMode}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
    />
  );

}

export default Execution;