import React from "react";
import { useGetCall } from "seed/api";
import View from "components/start/executions/ExecutionDetails.view";


function ExecutionDetails({ idx, execution, style={}, onResumeExecution, onCancelExecution }) {

  const isDeveloperMode = sessionStorage.getItem("developer_mode") == "true";
  const randomKey = Math.random().toString(36).substring(7);
  const [callGetExecutions, reqExecutions] = useGetCall("/executions/get_executions", { execution_id: execution.id });

  const { executions = [] } = reqExecutions.data || {};
  let details = null;
  if(executions.length > 0) details = executions[0];

  const onShowDetails = () =>
    callGetExecutions({});

  return (
    <View
      idx={idx}
      randomKey={randomKey}
      execution={execution}
      details={details}
      style={style}
      isDeveloperMode={isDeveloperMode}
      onResumeExecution={onResumeExecution}
      onCancelExecution={onCancelExecution}
      onShowDetails={onShowDetails}
    />
  );
}

export default ExecutionDetails;