import React from "react";
import { Formik, Form, Field } from "formik";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Chip from '@material-ui/core/Chip';
import { Loading, Modal } from "seed/helpers";
import SuggestionHistory from "components/flow/suggestions/SuggestionHistory";
import SuggestionDetail from "components/flow/suggestions/SuggestionHistory.view.lib.detail";

const SuggestionsView = ({
  formikRef,
  flowId,
  pageId,
  options,
  suggestion,
  isLoading,
  isHistoryShown,
  setIsHistoryShown,
  onGeneratePositions,
  onGenerateProcesses,
  onGenerateProcess,
  onGenerateSolution,
  onCreateGuide,
  onChangeStep,
  reloadFlow
}) => {
  return (
    <div
      id="suggestions"
      className="card h-100 px-4 py-4"
      style={{
        position: "relative",
        zIndex: 5,
        overflowY: "auto",
      }}
    >

      <Formik
        innerRef={formikRef}
        initialValues={{ 
          initials: true,
          step: 0, 
          process_steps: [""] 
        }}
      >
        {({ values, setFieldValue }) =>
          <Form>

            {values.initials ? null : onChangeStep(values)}

            <div
              className="card-body d-flex flex-column pt-0"
              style={{
                overflowY: "auto",
                minHeight: "30rem"
              }}
            >

              {
                values.step == 0 &&
                  <div className="d-flex justify-content-end w-100 mb-3">
                    <button
                      type="button"
                      className="btn btn-link text-muted p-0"
                      onClick={() => setIsHistoryShown(true)}
                    >
                      <i className="fas fa-history mr-2"></i>
                      Ver historial
                    </button>
                  </div>
              }

              {
                function () {

                  const components = [];
                  const steps = [
                    "Inicio",
                    "Proceso",
                    "Descripción",
                    "Idea"
                  ];

                  for (let i = 0; i < steps.length; i++) {

                    components.push(
                      <div key={"step" + i} className="d-flex flex-column align-items-center"
                        onClick={i < values.step ? () => setFieldValue("step", i) : null}
                        style={i < values.step ? { cursor: "pointer" } : null}>

                        <span className="fa-stack">
                          <i
                            className="fas fa-circle fa-stack-2x"
                            style={{
                              color: i == values.step ? "#2080e5" :
                                i < values.step ? "#00c9a7" : "#90959a"
                            }}
                          />
                          <span className="fa-stack-1x text-white"
                            style={{ fontWeight: i == values.step ? "bold" : "normal" }}>
                            {
                              i < values.step
                                ? <i className="fas fa-check fa-stack-1x"></i>
                                : i + 1
                            }
                          </span>
                        </span>
                        <span className="mt-1"
                          style={{ fontWeight: i == values.step ? "bold" : "normal" }}>
                          {steps[i]}
                        </span>
                      </div>
                    );

                    if (i < steps.length - 1)
                      components.push(<hr key={"hr" + i} className="w-100 mt-3" />);

                  }

                  return <div className="d-flex w-100 mb-6">
                    {components}
                  </div>

                }()
              }

              {
                values.step == 0 &&
                <div className="d-flex flex-column w-100">
                  <div className="mb-3 animate__animated animate__fadeIn">
                    <div className="h4">
                      ¿Cuál es tu área?
                    </div>
                    {
                      !values.initials &&
                        <Autocomplete
                          freeSolo={true}
                          style={{ width: "100%" }}
                          options={options?.areas ?? []}
                          inputValue={values.area}
                          value={values.area}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              <Chip
                                key={"area" + index}
                                variant="outlined"
                                label={option}
                                {...getTagProps({ index })}
                              />
                            ))
                          }
                          renderInput={(params) =>
                            <TextField
                              {...params}
                              label=""
                              autoFocus={false}
                              variant="outlined"
                              placeholder="Buscar área..."
                            />
                          }
                          onChange={(e, value) => {
                            setFieldValue("area", value);
                            onGeneratePositions(value);
                          }}
                          onInputChange={(e, value) => {
                            setFieldValue("area", value);
                            onGeneratePositions(values.area);
                          }}
                        />
                    }
                  </div>
                  <div className="mt-3">
                    {
                      options.positions && options.positions.length > 0 ?
                        <div className="mb-3 animate__animated animate__fadeIn">
                          <div className="h4">
                            ¿Qué describe mejor tu puesto?
                          </div>
                          <Autocomplete
                            freeSolo={true}
                            style={{ width: "100%" }}
                            options={options?.positions ?? []}
                            inputValue={values.position}
                            value={values.position}
                            renderTags={(value, getTagProps) =>
                              value.map((option, index) => (
                                <Chip
                                  key={"area" + index}
                                  variant="outlined"
                                  label={option}
                                  {...getTagProps({ index })}
                                />
                              ))
                            }
                            renderInput={(params) =>
                              <TextField
                                {...params}
                                label=""
                                autoFocus={false}
                                variant="outlined"
                                placeholder="Buscar área..."
                              />
                            }
                            onChange={(event, newValue) => {
                              setFieldValue("position", newValue);
                              onGenerateProcesses(values.area, values.position);
                            }}
                            onBlur={(e) =>
                              onGenerateProcesses(values.area, values.position)}
                            onInputChange={(event, newInputValue) =>
                              setFieldValue("position", newInputValue)}
                          />
                        </div>
                        : options.positions ? <div><Loading size={30} /></div> : null
                    }
                  </div>
                </div>
              }

              {
                values.step == 1 && <>
                  {
                    (!options.processes || options.processes.length == 0) &&
                    <div className="d-flex w-100 justify-content-center">
                      <div className="spinner-border text-primary" role="status">
                        <span className="sr-only">Loading...</span>
                      </div>
                    </div>
                  }
                  {
                    (options.processes && options.processes.length > 0) &&
                    <div className="d-flex flex-column w-100">
                      <div className="mb-3 animate__animated animate__fadeIn">
                        <div className="h4">
                          ¿Qué proceso te gustaría mejorar?
                        </div>
                        <Field
                          className="form-control"
                          type="text"
                          name="process_title"
                          placeholder="Ej. Conciliación bancaria"
                          onBlur={(e) =>
                            onGenerateProcess(values.process_title)}
                        />
                      </div>
                      <div className="animate__animated animate__fadeIn mt-3">
                        <div className="h5">
                          Ideas
                        </div>
                        <div className="d-flex flex-column w-100">
                          {
                            options.processes.map((process, index) =>
                              <button
                                key={"process" + index}
                                className={"form-control mb-2 text-left h-auto "
                                  + (values.process_title == process ? "btn-primary" : "btn-lighter")}
                                style={{ border: ".0625rem solid #e7eaf3" }}
                                onClick={() => {
                                  setFieldValue("process_title", process);
                                  onGenerateProcess(process);
                                }}
                              >
                                {process}
                              </button>
                            )
                          }
                        </div>
                      </div>

                    </div>
                  }
                </>
              }

              {
                values.step == 2 && <>

                  <div className="d-flex flex-column w-100">
                    <div className="mb-3 animate__animated animate__fadeIn mb-2">
                      <div className="h4">
                        Platícanos de tu proceso<br />
                        <small className="text-muted">Entre más específico, mejores resultados</small>
                      </div>
                      {
                        (values.process_steps).map((step, index) =>
                          <div key={"step" + index} className="d-flex align-items-center w-100 mb-2">
                            <span className="btn btn-outline-primary py-2 px-0 mr-2 bg-white"
                              style={{ userSelect: "none", pointerEvents: 'none', lineHeight: "1.35", width: "5.4rem" }}>
                              Paso {index + 1}
                            </span>
                            <Field
                              className="form-control"
                              type="text"
                              name={"process_steps[" + index + "]"}
                              placeholder="Descripción"
                            />
                            <button
                              type="button"
                              className="btn btn-sm ml-2"
                              onClick={() => {
                                const steps = values.process_steps;
                                steps.splice(index, 1);
                                setFieldValue("process_steps", steps);
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                        )
                      }
                      <button
                        type="button"
                        className="btn btn-sm btn-lighter float-right"
                        onClick={() => {
                          const steps = values.process_steps;
                          steps.push("");
                          setFieldValue("process_steps", steps);
                        }}
                      >
                        <i className="fas fa-plus mr-1"></i> Agregar paso
                      </button>
                    </div>
                  </div>

                  <div className="animate__animated animate__fadeIn mt-2 w-100">
                    <div className="h5">
                      Inspiración
                    </div>
                    <table className="table table-bordered table-hover table-sm m-0 p-0 mt-3">
                      <tr>
                        <th className="text-center">Paso</th>
                        <th className="px-2">Descripción</th>
                        <th></th>
                      </tr>

                      {
                        options.processSteps ?
                          function () {
                            try {
                              return <>
                                {options.processSteps.map((step, index) => (
                                  <tr key={index}>
                                    <td className="text-center" style={{ verticalAlign: "middle" }}>{index + 1}</td>
                                    <td className="px-2" style={{ verticalAlign: "middle" }}>{step}</td>
                                    <td><button className="btn btn-sm btn-link py-1 font-weight-normal"
                                      onClick={() => {
                                        const steps = values.process_steps;
                                        if (steps.length == 0)
                                          steps.push("")
                                        else if (steps[steps.length - 1] != "")
                                          steps.push("")
                                        steps[steps.length - 1] = step
                                        steps.push("");
                                        setFieldValue("process_steps", steps);
                                      }}
                                    >Utilizar</button></td>
                                  </tr>
                                ))}
                              </>
                            }
                            catch (e) {
                              return <></>
                            }
                          }() : <Loading size={30} />
                      }
                    </table>
                  </div>
                </>
              }

              {
                values.step == 3 && <>
                  <div className="animated animate__fadeIn">
                    {
                      !suggestion?.response && 
                        <div className="d-flex w-100 justify-content-center mt-7">
                          <div className="spinner-border text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                    }
                    {
                      suggestion?.response && <>
                        <SuggestionDetail
                          suggestion={suggestion}
                          showRegenerateButton={true}
                          onRegenerate={onGenerateSolution}
                        />
                      </>
                    }
                  </div>
                </>
              }

            </div>

            <div className="card-footer d-flex justify-content-between">
              {
                function () {
                  let type = "button";
                  if (values.step > 0)
                    return (
                      <button
                        type={type}
                        className="btn btn-lighter w-25 mr-1"
                        onClick={() => setFieldValue("step", values.step - 1)}
                      >
                        Anterior
                      </button>
                    );
                  return <div className="w-100"></div>;
                }()
              }
              {
                function () {

                  let condition = false;
                  let type = "button";

                  if(values.step == 0) condition = values.area && values.position;
                  if(values.step == 1) condition = values.process_title;
                  if(values.step == 2) condition = values.process_steps && values.process_steps.length > 0;
                  if(values.step == 3) condition = suggestion;
                  if(isLoading) condition = false;

                  return <button
                    type={type}
                    className={"btn w-100 ml-2 " + (values.step < 3 ? "btn-primary" : "btn-accent")}
                    disabled={!condition}
                    onClick={() => {

                      if(values.step == 2) 
                        onGenerateSolution();

                      if(values.step < 3)
                        setFieldValue("step", values.step + 1)

                      if(values.step == 3 && suggestion?.response)
                        onCreateGuide();

                    }}
                  >
                    { values.step < 3 ? "Siguiente" : "Crear guía" }
                  </button>

                }()
              }
            </div>

          </Form>
        }
      </Formik>
      {isHistoryShown && (
        <Modal
          width={600}
          height={600}
          animation={""}
          flowId={flowId}
          pageId={pageId}
          reloadFlow={reloadFlow}
          onClose={() => setIsHistoryShown(false)}
          component={SuggestionHistory}
        />
      )}
    </div>
  );
}

export default SuggestionsView;