import React from 'react';
import { NavLink } from "react-router-dom";
import HelpMessage from 'components/util/helpers/HelpMessage';
import { Analytics } from "components/util/analytics";

const Tab = ({ flow, route, title, caption, onClick = () => { } }) =>
  <HelpMessage message={caption} style={{ marginTop: "-5px" }}
    delay={350}>
    <NavLink to={route} className="dropdown-header text-center mx-1"
      style={{
        marginTop: "-0.3rem", padding: "0.45rem 1rem", position: "relative"
      }} id="flow-tab"
      onClick={() => {
        Analytics.CreateEvent("tab_selected", { tab: title, flow: flow.id });
        onClick();
      }}>
      {title}
    </NavLink>
  </HelpMessage>

export { Tab }