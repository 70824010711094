import React from "react";
import ExecutionsList from "components/start/executions/ExecutionsList";
import BarChart from "components/misc/charts/BarChart";
import HeatmapChart from "components/misc/charts/HeatmapChart";


const ExecutionsView = ({
  flow,
  heatMapExecutions,
  barChartExecutions,
  styles,
}) => (
  <div
    className="px-2 scrollbar-narrow"
    style={{
      width: "100%",
      height: "calc(100% - 110px)",
      overflow: "auto",
      paddingTop: "0.3rem",
      ...styles
    }}
  >
    <div className="w-100 p-4">

      <div className="row">

        <div className="col-lg-7 mb-4" >
          <h2 className="mb-1">Actividad reciente</h2>
          <label className="mb-4" style={{color: "#858a90"}}>Resultados de las últimas ejecuciones del proceso</label>
          <ExecutionsList flow={flow} placeholder="No hay actividad reciente" />
        </div>

        <div className={`col-lg-5`} >
          <div className="mb-3">
            <HeatmapChart data={heatMapExecutions.data} options={heatMapExecutions.options} />
          </div>
          <div className="mx-2">
            <BarChart data={barChartExecutions.data} options={barChartExecutions.options} height={350} />
          </div>
        </div>

      </div>

    </div>
  </div>
);

export default ExecutionsView;