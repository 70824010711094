
import React from "react";
import { Formik, Form } from "formik";
import { Modal } from "seed/helpers";
import * as Fields from "components/schema_form/fields";
import { FieldWrapper, MultipleFieldWrapper } from "components/visual_mode/Settings.sub.wrappers.view";
import TableSettings from "components/visual_mode/Settings.sub.table.view";
import FormSettings from "components/visual_mode/Settings.sub.form.view";

const SettingsView = ({
  formRef,
  flow,
  collections = [],
  execModeDefaults = [],
  execModeSettings = [],
  activeTab,
  isTableSettingsShown,
  isFormSettingsShown,
  setIsTableSettingsShown,
  setIsFormSettingsShown,
  getPanelOptions,
  getColumnOptions,
  onCreateTable,
  onAddSection,
  onChangeTab,
  onDelete,
  onSubmit,
  onReset
}) => (
  <div className="card p-5">

    <div className="card-title d-flex justify-content-between mb-1">
      <h2>Personalizar</h2>
      <button 
        type="button"
        class="btn btn-link"
        onClick={onReset}
      >
        Restablecer default
      </button>
    </div>

    <div className="card-body p-0">
      <Formik
        innerRef={formRef}
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ values, setValues, setFieldValue }) => (
          <Form className="m-0 p-0">

            {/* Tabs */}
            <div className="d-flex mb-4">

              {
                execModeSettings.map((execModeSetting, index) => (
                  <div
                    key={"tab_" + index}
                    className={`btn d-flex justify-content-center px-2 mr-2 exec-mode-tab ${activeTab === index ? "active" : ""}`}
                    style={{ width: "12rem" }}
                    onMouseEnter={() => setFieldValue("hoverTab", index)}
                    onMouseLeave={() => setFieldValue("hoverTab", null)}
                  >
                    <span 
                      className="text-truncate" 
                      onClick={() => onChangeTab(values, index)}
                    >
                      {execModeSetting.title ?? "Nueva sección"}
                    </span>
                    {
                      values.hoverTab === index &&
                        <span>
                          <i className="fas fa-trash ml-3" onClick={() => onDelete(index)}></i>
                        </span>
                    }
                  </div>
                ))
              }

              <button 
                type="button"
                className={`btn text-center text-truncate px-2 mr-2 exec-mode-tab`}
                style={{ width: "4rem" }}
                onClick={() => onAddSection(values)}
              >
                <i className="fas fa-plus"></i> Agregar
              </button>

            </div>

            {
              function(){

                let currentDefaults = execModeDefaults[values.reference];
                if(!currentDefaults) currentDefaults = {};
              
                return <>

                  <h5>Datos generales</h5>

                  {/* Fields */}
                  <div className="">
                    <ul className={"step step-form step-eicon-xs mb-0 mt-4"}>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.SelectField
                            required={true}
                            label={"Referencia de proceso"}
                            name={`reference`}
                            setFieldValue={setFieldValue}
                            value={values.reference}
                            values={values}
                            helpMessage={"Referencia del flujo"}
                            options={
                              Object.keys(execModeDefaults).map(k => 
                                ({ label: execModeDefaults[k].reference.title, value: execModeDefaults[k].reference.id }))}
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.TextField
                            required={true}
                            label={"Título"}
                            name={"title"}
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                          {
                            values.title != currentDefaults.title &&
                              <button
                                type="button"
                                class="btn btn-link"
                                style={{ width: "140px" }}
                                onClick={() => {
                                  setFieldValue("is_manual_title", false)
                                  setFieldValue("title", currentDefaults.title)
                                }}
                              >
                                Reestablecer
                              </button>
                          }
                        </div>
                      </FieldWrapper>
                    </ul>
                  </div>
                  
                  <h5>Botones</h5>

                  {/* Buttons */}
                  <div className="">
                    <MultipleFieldWrapper
                      title={"Botones"}
                      isDefault={values.actions == currentDefaults.actions}
                      values={values}
                      valueKey={"actions"}
                      setFieldValue={setFieldValue}
                      onChangeDefaults={() => {
                        setFieldValue("is_manual_actions", true);
                        setFieldValue("actions", currentDefaults.actions);
                      }}
                    >
                      {
                        (values.actions??[]).map((action, idx) => (
                          <>
                            <FieldWrapper>
                              <div className="d-flex justify-content-between align-items-end">
                                <Fields.SelectField
                                  required={true}
                                  label={"Ejecutar proceso"}
                                  name={`actions[${idx}].id`}
                                  setFieldValue={setFieldValue}
                                  value={action.id}
                                  values={values}
                                  helpMessage={"Referencia del flujo a ejecutar"}
                                  onChange={(value) => {
                                    let currentDefaults = execModeDefaults[value];
                                    if(!currentDefaults) currentDefaults = { reference: {} };
                                    setFieldValue(`actions[${idx}]`, currentDefaults.reference);
                                  }}
                                  options={
                                    Object.keys(execModeDefaults).map(k => 
                                      ({ label: execModeDefaults[k].title, value: k }))}
                                />
                              </div>
                            </FieldWrapper>
                            <FieldWrapper>
                              <div className="d-flex justify-content-between align-items-end">
                                <Fields.TextField
                                  required={true}
                                  label={"Título"}
                                  name={`actions[${idx}].title`}
                                  values={values}
                                  setFieldValue={setFieldValue}
                                />
                                {
                                  values.actions[idx].title != execModeDefaults[values.actions[idx].id]?.reference.title &&
                                    <button
                                      type="button"
                                      class="btn btn-link"
                                      style={{ width: "140px" }}
                                      onClick={() => {
                                        setFieldValue(`actions[${idx}].title`, execModeDefaults[values.actions[idx].id]?.reference.title)
                                      }}
                                    >
                                      Reestablecer
                                    </button>
                                }
                              </div>
                            </FieldWrapper>
                          </>
                        ))
                      }
                    </MultipleFieldWrapper>
                  </div>

                  <h5>Secciones</h5>

                  {/* Panels */}
                  <div 
                    className="row px-4 pt-0 mt-0"
                    style={{
                      overflowX: "hidden",
                    }}
                  >
                    <div 
                      className={"col-md-6"}
                    >
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.3rem" }}>Panel izquierdo</span>
                          </div>
                          <div className="d-flex justify-content-center px-3">
                            <Fields.SelectField
                              required={true}
                              name={"left_panel"}
                              value={values.left_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              options={getPanelOptions(values, execModeDefaults)}
                            />
                            {
                              values.left_panel != currentDefaults.left_panel &&
                                <button
                                  type="button"
                                  class="btn btn-link p-0 px-2"
                                  style={{ width: "140px" }}
                                  onClick={() => {
                                    setFieldValue("is_manual_left_panel", false)
                                    setFieldValue("left_panel", currentDefaults.left_panel)
                                  }}
                                >
                                  Reestablecer
                                </button>
                            }
                          </div>
                        </div>
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                          {getPanelIcon(values.left_panel)}
                          <div style={{ height: "25px" }}>
                            {
                              values.left_panel == "TABLE" &&
                                <button
                                  type="button"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => setIsTableSettingsShown(true)}
                                >
                                  Opciones
                                </button>
                            }
                            {
                              values.left_panel == "FORM" &&
                                <button
                                  type="button"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => setIsFormSettingsShown(true)}
                                >
                                  Opciones
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={"col-md-6"}>
                      <div class="card">
                        <div class="card-header d-block text-center">
                          <div class="mb-2">
                            <span class="card-title h5" style={{ fontSize: "1.3rem" }}>Panel derecho</span>
                          </div>
                          <div className="d-flex justify-content-center px-3">
                            <Fields.SelectField
                              required={true}
                              name={"right_panel"}
                              value={values.right_panel}
                              values={values}
                              setFieldValue={setFieldValue}
                              options={getPanelOptions(values, execModeDefaults)}
                            />
                            {
                              values.right_panel != currentDefaults.right_panel &&
                                <button
                                  type="button"
                                  class="btn btn-link p-0 px-2"
                                  style={{ width: "140px" }}
                                  onClick={() => {
                                    setFieldValue("is_manual_right_panel", false)
                                    setFieldValue("right_panel", currentDefaults.right_panel)
                                  }}
                                >
                                  Reestablecer
                                </button>
                            }
                          </div>
                        </div>
                        <div class="card-body d-flex flex-column align-items-center justify-content-center">
                          {getPanelIcon(values.right_panel)}
                          <div style={{ height: "25px" }}>
                            {
                              values.right_panel == "TABLE" &&
                                <button
                                  type="button"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => setIsTableSettingsShown(true)}
                                >
                                  Opciones
                                </button>
                            }
                            {
                              values.right_panel == "FORM" &&
                                <button
                                  type="button"
                                  class="btn btn-link m-0 p-0 mt-2"
                                  onClick={() => setIsFormSettingsShown(true)}
                                >
                                  Opciones
                                </button>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </>

              }()
            }

          <button 
            type="submit"
            class="btn btn-primary btn-block ml-2 mt-3"
          >
            Guardar
          </button>

          </Form>
        )}
      </Formik>
    </div>

    {/* Table fields */}
    {
      isTableSettingsShown &&
        <Modal
          onClose={() => setIsTableSettingsShown(false)}
          width={800}
          height={600}
          component={TableSettings}
          flow={flow}
          collections={collections}
          currentDefaults={execModeDefaults[formRef.current.values.reference]}
          initialValues={formRef.current.values}
          setParentFieldValue={formRef.current.setFieldValue}
          getColumnOptions={getColumnOptions}
          onCreateTable={onCreateTable}
        />
    }

    {
      isFormSettingsShown &&
        <Modal
          onClose={() => setIsFormSettingsShown(false)}
          width={800}
          height={600}
          component={FormSettings}
          flow={flow}
          execModeDefaults={execModeDefaults}
          currentDefaults={execModeDefaults[formRef.current.values.reference]}
          initialValues={formRef.current.values}
          setParentFieldValue={formRef.current.setFieldValue}
        />
    }

  </div>
)

const getPanelIcon = (value) => {

  let classname = "fas fa-minus";
  if(value == "TABLE") classname = "fas fa-table";
  if(value == "ACTIVITY_CHART") classname = "fas fa-chart-area";
  if(value == "FORM") classname = "fas fa-file-alt";

  return <i className={classname} style={{ fontSize: "12rem", color: "#dfe3ee" }}></i>

}

export default SettingsView;