
import React from 'react';
import { getField } from 'components/visual_mode/panels/Data.lib.parser';

const ExecModeDataCards = ({ 
  collections,
  values, 
  structure, 
}) => {
  return <div className='row w-100 m-0 p-0 mt-3'>
      {
        values.map((row, idx) =>
          <div key={idx} className='col-lg-6 my-2 mb-2'>
            <div className='card card-body h-100 w-100'>
              <div className='d-flex flex-column'>
                {
                  structure.map((column, idx) =>
                    <div className={`${idx !=  structure.length - 1 ? 'mb-2' : ''}`} key={idx}>
                      <h5 className='card-title'>{column.label}</h5>
                      <p className='card-text'>
                        {
                          function(){
                            const value = row.data[column.name];
                            if(!value) return "-";
                            return getField(column, value, collections);
                          }()
                        }
                      </p>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        )
      }
      {
        values.length == 0 && <div className='col-12'>
          <div className='card card-body h-100 w-100'>
            <h5 className='card-title'>Sin datos</h5>
            <p className='card-text'>No hay datos para mostrar</p>
          </div>
        </div>
      }
    </div>
}


export default ExecModeDataCards;