import React from "react";
import "styles/css/start/ExecutionHistory.css"
import ExecutionGroup from "components/start/executions/ExecutionHistory.sub.Item.view";


const months = {
  "01": "Enero",
  "02": "Febrero",
  "03": "Marzo",
  "04": "Abril",
  "05": "Mayo",
  "06": "Junio",
  "07": "Julio",
  "08": "Agosto",
  "09": "Septiembre",
  "10": "Octubre",
  "11": "Noviembre",
  "12": "Diciembre"
}


const ExecutionHistoryView = ({
  data,
  placeholder
}) => (
  <ul className="list-group" id="execution-history">

    <ExecutionGroup
      title={"Hoy"}
      placeholder={placeholder}
      executions={data.today}
    />

    <ExecutionGroup
      title={"Esta semana"}
      placeholder={placeholder}
      executions={data.thisWeek}
    />

    <ExecutionGroup
      title={"Este mes"}
      placeholder={placeholder}
      executions={data.thisMonth}
    />

    {Object.keys(data.months).map((date, idx) => {
      const year = date.split("-")[0];
      const month = date.split("-")[1];
      return (
        <ExecutionGroup
          key={idx}
          title={`${months[month]} ${year}`}
          placeholder={placeholder}
          executions={data.months[date]}
        />
      )
    })}

  </ul>
);

ExecutionHistoryView.propTypes = {};

export default ExecutionHistoryView;