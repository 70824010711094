import React, { useEffect } from 'react';
import { useGet } from "seed/api";
import * as chartOptions from "components/start/usage/Usage.lib.chartOptions";
import * as lib from "components/start/executions/Executions.lib.format";
import ExecutionsList from "components/start/executions/ExecutionsList";
import LineChart from "components/misc/charts/LineChart";
import { addPostMessageListener } from "components/util/flow";

const formatDate = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

const ExecModeCharts = ({ 
  flow, 
  isFullWidth,
  execModeSetting,
}) => {

  const userId = sessionStorage.getItem("id");
  const now = new Date();
  const date15DaysAgo = new Date();
  date15DaysAgo.setDate(now.getDate() - 15);
  const endDate = formatDate(now);
  const startDate = formatDate(date15DaysAgo);

  const reqExecutionsUsage = useGet("/executions/get_executions_usage",
    { "user_id": userId, "start_date": startDate, "end_date": endDate })

  useEffect(() => {
    addPostMessageListener((data) => {
      if(data.type == "refetch-exec-mode-charts")
        reqExecutionsUsage.refetch();
    });
  }, []);

  if(reqExecutionsUsage.loading) return <div style={{ padding: "0.72rem 0rem" }} />

  const executionsPerDay = reqExecutionsUsage.data?.executions ?? {};

  const lineChartExecutions = {
    "data": [{
      "name": "Actividad",
      "data": Object.keys(executionsPerDay).map(key => executionsPerDay[key])
    }],
    "options": chartOptions.lineChartOptions
  }
  lineChartExecutions["options"]["title"] = {
    text: "Actividad de los últimos 15 dias",
    style: { fontFamily: "sans-serif", fontWeight: 700 }
  };
  lineChartExecutions["options"]["xaxis"] = {
    "categories": Object.keys(executionsPerDay).map(key => {
      const date = key;
      const day = parseInt(date.split("-")[2]);
      const month = parseInt(date.split("-")[1]);
      return `${day} ${lib.getMonthName(month)}`
    })
  }

  return <div 
    className='scrollbar-narrow'
    style={{
      height: "100%",
      overflowY: "auto",
    }}
  >
    <div
      className="px-2"
      style={{
        width: "100%",
        height: "calc(100% - 110px)",
        paddingTop: "0.3rem",
      }}
    >
      <div className="w-100 p-4">
        <div className="row">

          <div className={`${isFullWidth ? "col-lg-5" : "col-lg-12"} mb-4`}>
            <h3 className={`${isFullWidth ? "mb-4" : "mb-0"}`}>Actividad</h3>
            <label className="mb-3" style={{color: "#858a90"}}>Resultados de las últimas ejecuciones del proceso</label>
            <ExecutionsList 
              flow={flow} 
              rootId={execModeSetting.reference.id}
              placeholder="No hay actividad reciente" 
            />
          </div>

          {
            isFullWidth &&
              <div className={`col-lg-${isFullWidth ? "7" : "12"}`} >
                <div className="mb-3">
                  <LineChart data={lineChartExecutions.data} options={lineChartExecutions.options} height={350} />
                </div>
              </div>
          }

        </div>
      </div>
    </div>
  </div>;
}

export default ExecModeCharts;