const areas = [
  "Administración",
  "Comercio y Bienes de Consumo",
  "Diseño",
  "Educación",
  "Farmacéutica o Biotecnología",
  "Finanzas",
  "Gestión de Proyectos",
  "Gobierno / Sector Público",
  "Industria Automotriz",
  "Ingeniería",
  "Investigación y Desarrollo",
  "Legal",
  "Logística",
  "Manufactura",
  "Marketing",
  "Medios de Comunicación",
  "Recursos Humanos",
  "Relaciones Internacionales",
  "Salud",
  "Servicios",
  "Tecnologías de la Información",
  "Telecomunicaciones",
  "Ventas",
  "Otro"
];

const positions = {
  "Administración": [
    "Asistente Administrativo",
    "Coordinador de Recursos Humanos",
    "Director Administrativo",
    "Gerente de Oficina"
  ],
  "Comercio y Bienes de Consumo": [
    "Analista de Mercado",
    "Ejecutivo de Ventas",
    "Gerente de Marca",
    "Gerente de Producto"
  ],
  "Diseño": [
    "Director de Arte",
    "Diseñador Gráfico",
    "Diseñador UX/UI",
    "Ilustrador"
  ],
  "Educación": [
    "Asesor Pedagógico",
    "Coordinador Educativo",
    "Director Académico",
    "Profesor"
  ],
  "Farmacéutica o Biotecnología": [
    "Especialista en Regulatorio",
    "Gerente de Producto Farmacéutico",
    "Investigador de Biotecnología",
    "Técnico de Laboratorio"
  ],
  "Finanzas": [
    "Analista Financiero",
    "Auditor Interno",
    "Contador",
    "Gerente de Finanzas"
  ],
  "Gestión de Proyectos": [
    "Consultor de Gestión de Proyectos",
    "Coordinador de Proyectos",
    "Gerente de Proyectos",
    "Scrum Master"
  ],
  "Gobierno / Sector Público": [
    "Asesor Político",
    "Director de Programas Públicos",
    "Funcionario Público",
    "Gestor de Proyectos Sociales"
  ],
  "Industria Automotriz": [
    "Diseñador de Vehículos",
    "Ingeniero Automotriz",
    "Jefe de Producción Automotriz",
    "Técnico Automotriz"
  ],
  "Ingeniería": [
    "Ingeniero Civil",
    "Ingeniero Eléctrico",
    "Ingeniero Industrial",
    "Ingeniero de Software"
  ],
  "Investigación y Desarrollo": [
    "Desarrollador de Productos",
    "Investigador Científico",
    "Investigador de Mercado",
    "Ingeniero de I+D"
  ],
  "Legal": [
    "Abogado Corporativo",
    "Abogado Penalista",
    "Asesor Legal",
    "Notario"
  ],
  "Logística": [
    "Analista de Cadena de Suministro",
    "Coordinador de Distribución",
    "Gerente de Logística",
    "Supervisor de Almacén"
  ],
  "Manufactura": [
    "Ingeniero de Manufactura",
    "Jefe de Planta",
    "Operario de Producción",
    "Supervisor de Producción"
  ],
  "Marketing": [
    "Analista de Mercado",
    "Especialista en SEO/SEM",
    "Gerente de Marketing",
    "Social Media Manager"
  ],
  "Medios de Comunicación": [
    "Editor de Contenidos",
    "Periodista",
    "Presentador de TV",
    "Productor de Radio"
  ],
  "Recursos Humanos": [
    "Consultor en RRHH",
    "Coordinador de Capacitación",
    "Especialista en Reclutamiento",
    "Gerente de Recursos Humanos"
  ],
  "Relaciones Internacionales": [
    "Analista de Relaciones Internacionales",
    "Consultor en Política Exterior",
    "Diplomático",
    "Especialista en Comercio Internacional"
  ],
  "Salud": [
    "Enfermero/a",
    "Médico General",
    "Psicólogo/a Clínico",
    "Técnico en Radiología"
  ],
  "Servicios": [
    "Consultor de Servicios",
    "Coordinador de Servicios Generales",
    "Gerente de Servicios",
    "Supervisor de Atención al Cliente"
  ],
  "Tecnologías de la Información": [
    "Administrador de Sistemas",
    "Analista de Seguridad Informática",
    "Desarrollador de Software",
    "Especialista en Big Data"
  ],
  "Telecomunicaciones": [
    "Especialista en Comunicaciones",
    "Gerente de Proyectos de Telecomunicaciones",
    "Ingeniero de Telecomunicaciones",
    "Técnico de Redes"
  ],
  "Ventas": [
    "Director Comercial",
    "Ejecutivo de Ventas",
    "Gerente de Ventas",
    "Representante de Ventas"
  ],
  "Otro": [
    "Community Manager",
    "Consultor Independiente",
    "Emprendedor",
    "Freelancer"
  ]
}

export {
  areas, positions
}