import React, { useState } from "react";
import { Field } from "formik";
import { useQuery } from "seed/gql";
import { Modal } from "seed/helpers"
import BaseField from "components/schema_form/fields/custom_fields/BaseField";
import AddAccount from "components/schema_form/fields/account_picker/AddAccount";


const AccountPicker = ({
	name,
	label,
	appId,
	value,
	required,
	values,
	caption,
	helpMessage,
	integrationNames,
	defaultAccountName,
	addAccountButtonText = "Agregar cuenta",
	setFieldValue,
}) => {

	const userId = sessionStorage.getItem('id');
	const [modalShown, setModalShown] = useState(false);

	const integrationNameQuery = integrationNames.map(name => `integration.name = ${name}`).join(" OR ")
	const qIntegrationAccounts = useQuery(`{
		integrationAccounts {
			username
			integration {
				name
				uiSettings
				authSettings
			}            
			user {
				email
			}
		}
  	}`, `user.id=${userId} AND (${integrationNameQuery})`, {
		onCompleted: data => {
			if (value === undefined) {
				if (data.integrationAccounts.length > 0)
					setFieldValue(name, data.integrationAccounts[0].id);
				else if (defaultAccountName) setFieldValue(name, 0);
				else setFieldValue(name, "")
			}
		}
	});

	if (qIntegrationAccounts.loading) return <div></div>;
	let integrationAccounts = [...qIntegrationAccounts.data.integrationAccounts ?? []]

	return (
		<div>
			<BaseField name={name} label={label} required={required} caption={caption} helpMessage={helpMessage}
				enableComments={false} values={values} setFieldValue={setFieldValue} >

				{integrationAccounts.length > 0 || defaultAccountName ?
					<Field as="select" name={name}
						required={required} class="form-control select-field mt-1"
						onChange={(ev) => {
							let value = ev.target.value
							if (value == "ADD_ACCOUNT") {
								setFieldValue(name, integrationAccounts.length > 0 ? integrationAccounts[0].id : defaultAccountName ? 0 : "")
								setModalShown(true)
							} else setFieldValue(name, parseInt(value))
						}} >
						{<option disabled hidden value="">Selecciona una cuenta</option>}
						{defaultAccountName ? <option value="0">{defaultAccountName}</option> : null}
						{
							integrationAccounts.map((integrationAccount, idx) => {
								const integrationUiSettings = (integrationAccount.integration.uiSettings)
								return <option key={integrationAccount.id} value={integrationAccount.id}>
									{integrationAccount.username} ({integrationUiSettings.label ?? integrationAccount.integration.name})
								</option>
							})
						}
						<option value="ADD_ACCOUNT">+ Agregar cuenta</option>
					</Field> : null
				}

				{
					integrationAccounts.length == 0 && !defaultAccountName ?
						<button type="button" class="btn btn-sm btn-outline-primary"
							onClick={() => {
								setFieldValue(name, "");
								setModalShown(true);
							}}>
							<i className="fas fa-plus mr-1"></i> {addAccountButtonText}
						</button> : null
				}

			</BaseField>

			{modalShown ?
				<Modal
					width={420}
					height={420}
					onClose={() => setModalShown(false)}
					component={() => <AddAccount
						integrationNames={integrationNames} />}
				/>
				: null}
		</div>
	)
}

export default AccountPicker;