import React from "react";
import { useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/flow/board/annotations/Node.view";

function Node({ id, data }) {

  const nodes = useNodes();
  const historyRouter = useHistory();
  const { setNodes } = useReactFlow();

  const actionId = data.actionId;
  const isSelected = data?.is_selected ?? false;
  const input = data?.input_value ?? [];
  const backgroundColor = input.find(inp => inp.name == "background")?.value ?? "#ffffff";
  let content = "";

  for (let inp of input) {
    if (inp.name == "content")
      content = inp.value
  }

  const reqAction = useDetail(`{
    action { 
      name
      type
      isEditable
      inputStructure
      outputStructure
      uiSettings
      app {
        uiSettings
      }
    }
  }`, actionId);

  if (reqAction.loading) return <Loading size={27} />;
  const { action = {} } = reqAction.data;
  const isEditable = action?.isEditable;

  const onEditNodeAnnotation = () => {
    if (!isEditable) return;
    const node_annotation = nodes.find((nd) => nd.id == id);
    if (node_annotation) {
      const url = `/flow/edit/${node_annotation.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  const onSelectNodeAnnotation = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_selected = !isSelected;
    setNodes(nodes);
    data.onSelectNode()
  }

  return (
    <View
      id={id}
      content={content}
      backgroundColor={backgroundColor}
      isSelected={isSelected}
      isEditable={isEditable}
      onEdit={onEditNodeAnnotation}
      onSelect={onSelectNodeAnnotation}
    />
  );
}

export default Node;