import React from "react";
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";


const CategoryHeader = ({ value }) => {
  if (value != "collection" && value != "variable")
    return <div></div>

  return <span className="d-flex justify-content-between dropdown-header pt-2 px-3">
    <span style={{ color: "#aaa", fontSize: "0.8em" }}>
      {value == "collection" ? "Tablas" : ""}
      {value == "variable" ? "Referencias" : ""}
    </span>
  </span>
}

const CollectionItem = ({ collection, disabled, isOnSelector = false }) =>
  <div className="d-flex align-items-center pl-1 pr-4 py-2"
    style={{
      pointerEvents: "none",
      color: "#777", fontSize: "1rem",
      ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff"  } : {}
    }}>
    <i className="fas fa-table mr-3" style={{ color: "#888" }}></i>
    <span style={{ color: "#606a70", fontSize: "0.9em" }}>{collection?.name.substring(1)}</span>
  </div>


const AddCollectionItem = ({ onClickAddCollection }) =>
  <div className="d-flex align-items-center px-1 py-2 w-100"
    onClick={onClickAddCollection}
    style={{ color: "#777", fontSize: "1rem" }}>
    <i className="fas fa-plus mr-3" style={{ color: "#888" }}></i>
    <span style={{ color: "#777", fontSize: "0.9em" }}><i>Agregar tabla</i></span>
  </div>


const VariableItem = ({ node, disabled, isOnSelector = false }) => {

  const title = node.action.uiSettings?.node?.title ?? node.action.uiSettings?.general?.title;
  const subtitle = bodyContentMapper(node.action.uiSettings?.node ?? [], node.input, "subtitle", [])
  const numOutput = node.output_count > 1

  return <div className="d-flex align-items-center pl-1 pr-4"
    style={{
      pointerEvents: "none",
      color: "#777", fontSize: "1rem",
      ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff" } : {}
    }}>
    <i className={"fas fa-project-diagram mr-4"} style={{ color: "#888" }}></i>
    <span
      style={{ color: "#a0a0a0", fontSize: "0.7em", background: "#fff", border: "1px solid #ddd", padding: "0 0.3rem", 
        marginLeft: "-1.45rem", marginTop: "1rem", position: "relative"
       }}>{node.id}</span>
    <div className="mt-2 ml-1">
    <span className="text-truncate d-inline-block"
      style={{ color: "#606a70", fontSize: "0.9em", maxWidth: subtitle != "" ? "10rem" : "100rem" }}>
      {title}
    </span>
    {subtitle != "" ?
      <span className="ml-2 text-truncate d-inline-block"
        style={{ color: "#aaa", fontSize: "0.8em", maxWidth: "10rem" }}>({subtitle})</span> : null
    }

    {numOutput > 1 ?
      <span className="ml-1"
        style={{ color: "#b0b0b0", fontSize: "0.75em" }}>({node.label ?? node.name})</span> : null
    }
    </div>

    
  </div>
}

const AttributeItem = ({ label, disabled, isOnSelector = false }) =>
  <div className="d-flex align-items-center pl-1 pr-4 py-2"
    style={{
      pointerEvents: "none",
      color: "#777", fontSize: "1rem",
      ...isOnSelector ? { position: "absolute", marginTop: "-2.55rem", marginLeft: "1rem", background: disabled ? "#f8fafd" : "#fff" } : {}
    }}>
    {/*<i className="fas fa-diagram-project mr-3" style={{color: "#888"}}></i> */}
    <span style={{ color: "#777", fontSize: "0.9em" }}>
      {label}
    </span>
  </div>


export { CategoryHeader, CollectionItem, AddCollectionItem, VariableItem, AttributeItem };