/* eslint-disable max-len */
import React from "react";
import { Handle, Position } from 'reactflow';
import { useEdges } from 'reactflow';


const defaultPoints = [
    { "mode": "NEXT", "position": "TOP" },
    { "mode": "NEXT", "position": "RIGHT" },
    { "mode": "NEXT", "position": "BOTTOM" },
    { "mode": "NEXT", "position": "LEFT" }
]

function TargetPoints({ nodeId, action }) {

    const edges = useEdges(); 
    const actionName = action.name;
    const uiSettings = action.uiSettings;
    const relationships = uiSettings?.relationships ?? defaultPoints;
    const isControl = actionName == "conditional" || actionName == "iterate";
    const points = [];

    if (actionName == "conditional") {
        const sourceEdge = edges.find(edge => edge.target == nodeId);

        if (sourceEdge) {
            const sourcePosition = (sourceEdge?.targetHandle ?? "_").split("_")[1].toUpperCase();

            if (sourcePosition == "TOP" || sourcePosition == "BOTTOM") {
                points.push(<Handle id="next_right" position={Position.Right} style={{ right: "-17px" }} />);
                points.push(<Handle id="next_left" position={Position.Left} style={{ left: "-17px" }} />);

                if (sourcePosition == "TOP") {
                    points.push(<Handle id="next_top" position={Position.Top} style={{ top: "-17px" }} />);
                    points.push(<Handle id="next_bottom" position={Position.Bottom} style={{ bottom: "-17px", visibility: "hidden" }} />);
                }
                if (sourcePosition == "BOTTOM") {
                    points.push(<Handle id="next_top" position={Position.Top} style={{ top: "-17px", visibility: "hidden" }} />);
                    points.push(<Handle id="next_bottom" position={Position.Bottom} style={{ bottom: "-17px" }} />);
                } 
            }

            if (sourcePosition == "LEFT" || sourcePosition == "RIGHT") {
                points.push(<Handle id="next_top" position={Position.Top} style={{ top: "-17px" }} />);
                points.push(<Handle id="next_bottom" position={Position.Bottom} style={{ bottom: "-17px" }} />);

                if (sourcePosition == "LEFT") {
                    points.push(<Handle id="next_left" position={Position.Left} style={{ left: "-17px" }} />);
                    points.push(<Handle id="next_right" position={Position.Right} style={{ right: "-17px", visibility: "hidden" }} />);
                }
                if (sourcePosition == "RIGHT") {
                    points.push(<Handle id="next_right" position={Position.Right} style={{ right: "-17px" }} />);
                    points.push(<Handle id="next_left" position={Position.Left} style={{ left: "-17px", visibility: "hidden" }} />);
                }
            }

        } else {
            points.push(<Handle id="next_top" position={Position.Top} style={{ top: "-17px" }} />);
            points.push(<Handle id="next_right" position={Position.Right} style={{ right: "-17px" }} />);
            points.push(<Handle id="next_left" position={Position.Left} style={{ left: "-17px" }} />);
            points.push(<Handle id="next_bottom" position={Position.Bottom} style={{ bottom: "-17px" }} />);
        }

    } else {
        for (const idx in relationships) {

            const relationship = relationships[idx]
            const controlStyle = {};
            const mode = relationship.mode.toLowerCase();
            const position = relationship.position.toLowerCase();
            let visualPosition = "";
    
            if (position == "top") {
                visualPosition = Position.Top;
                controlStyle.top = "-17px";
            } else if (position == "bottom") {
                visualPosition = Position.Bottom;
                controlStyle.bottom = "-17px";
                if (isControl) controlStyle.display = "none" // TODO Check
            } else if (position == "left") {
                visualPosition = Position.Left;
                controlStyle.left = "-17px";
            } else if (position == "right") {
                visualPosition = Position.Right;
                controlStyle.right = "-17px";
            }
    
            const props = {
                id: `${mode}_${position}`,
                style: {},
                position: visualPosition
            }
    
            if (isControl) props.style = controlStyle;
            points.push(<Handle key={`${mode}-${position}-${idx}`} {...props} />);
        }
    }




    return <div>{points.map((point) => point)}</div>
}

export default TargetPoints;
