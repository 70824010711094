import React from "react";
import { StyleTag } from "seed/helpers";


function StyleColor({ user }) {

	let theme = JSON.parse(sessionStorage.getItem("theme") || "{}");
	if (!theme.primary_color &&
		!user.email.includes("@chevez")) 
		return;

	let primaryColor = theme.primary_color ?? "#2080e5";
	let lightColor = theme.accent_color ?? "#3075BA";
	let darkColor = theme.dark_color ?? getDarkerColor(primaryColor, -15);

	if (user.email.includes("@chevez")) {
		primaryColor = "#007473"
		darkColor = "#00454d"
		lightColor = "#82cf6d"
	}

	return <StyleTag content={`

			#background-hero .path-a {
				fill: ${darkColor} !important;
			}
			#background-hero .path-b {
				fill: ${primaryColor} !important;
			}
			#background-hero .path-c {
				fill: ${lightColor} !important;
			}

			#start-templates {
				background: #ffffff !important;
			}

			#background-hero-template .path-a {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-b {
				fill: ${primaryColor}20 !important;
			}
			#background-hero-template .path-c {
				fill: ${darkColor}20 !important;
			}

			.btn.btn-primary {
				background: ${primaryColor} !important;
				border-color: ${primaryColor} !important;
			}
			.btn.btn-primary:hover {
				background: ${darkColor} !important;
			}
			.btn.btn-outline-primary {
				border-color: ${primaryColor} !important;
				color: ${primaryColor} !important;
			}
			.btn.btn-outline-primary:hover{
				background: ${primaryColor} !important;
				color: white !important;
			}
			.btn-app.active, .btn-app:hover{
				color: ${primaryColor} !important;
			}
			#flow-tab a.active, #flow-tab a:hover{
				color: ${primaryColor} !important;
				border-bottom-color: ${primaryColor} !important;
			}
		` } />

}

function getDarkerColor(color, v) {

	const normalize = (x) =>
		x > 255 ? x - (x - 255) : x;

	const getHex = (x) => {
		if (x < 0 || isNaN(x)) x = 0;
		if (x > 255) x = 255;
		x = x.toString(16);
		return x.length === 1 ? "0" + x : x;
	}

	if (color.length > 6)
		color = color.substring(1, color.length)

	var rgb = parseInt(color, 16);

	var r = Math.abs(((rgb >> 16) & 0xFF) + v);
	r = normalize(r);
	r = getHex(r);

	var g = Math.abs(((rgb >> 8) & 0xFF) + v);
	g = normalize(g);
	g = getHex(g);

	var b = Math.abs((rgb & 0xFF) + v);
	b = normalize(b);
	b = getHex(b);

	return "#" + r + g + b;

}

export default StyleColor;