
/* eslint-disable max-len */
import React from "react";
import { Link, Switch, Redirect } from "react-router-dom";
import { Tab } from "components/flow/Controller.sub.view";
import { Route } from "seed/helpers"
import { Modal } from "seed/helpers";
import Board from "components/flow/board/Board";
import Tables from "components/tables/Tables";
import Activity from "components/start/executions/Executions"
import Toolbox from "components/flow/toolbox/Toolbox";
import RPADesigner from "components/misc/apps/rpa/designer/RPADesigner";
import Contact from "components/flow/forms/contact_us/Contact";
import Execution from "components/flow/forms/executions/Execution";
import ExecMode from "components/visual_mode/ExecMode";
import { postMessage } from "components/util/win";
import HelpMessage from 'components/util/helpers/HelpMessage';
import WSManager from "components/util/ws";

const ControllerView = ({
  flow,
  nodes,
  edges,
  user,
  selectedNodes,
  activePage,
  reactFlowWrapper,
  actualExecutionId,
  execModeRef,
  wsExecutionRef,
  wsNodeRef,
  toolboxRef,
  showAnimations,
  isFlowBeingSaved,
  isGeneratingFlow,
  isDeveloper,
  isAnonymous,
  isContactUsShown,
  isDesignerShow,
  isRPADesigner,
  isExecutionDetailsShown,
  isExecMode,
  isAnimationOptionShown,
  isPaginationShown,
  setIsContactUsShown,
  setReactFlowInstance,
  setActivePage,
  setIsDesignerShow,
  setIsExecutionDetailsShown,
  setFilter,
  refetchExecutions,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onClickAnnotation,
  onClickUndo,
  onClickRedo,
  onDeleteSelectedNodes,
  onChangeExecMode,
  onExecuteNode,
  onExecutionMessage,
  onNodeMessage,
  onChangeAnimations,
  onGenerateFlow,
}) => (
  <div className="card p-0" id="flow">
    <div class="container-fluid p-0 h-100">

      <div class="d-flex h-100" style={{ overflowY: "auto" }}>

        <div id="toolbox">

          <div class="d-flex align-items-center pl-2" id="toolbox-header">

            <a href={!isAnonymous ? "/app" : "/use_cases"}
              className="back-button-flow d-flex align-items-center justify-content-center"
              title="Regresar">
              <i class="fas fa-arrow-left d-block"></i>
            </a>

            <div class="w-auto">
              <div className="logo"></div>
            </div>

            <div class="ml-0" id="toolbox-title">

              <div class="dropdown navbar-dropdown-account-wrapper rounded pr-2"
                style={isAnonymous ? { background: "transparent" } : {}}>
                <div class="dropdown-toggle ml-2" data-toggle={!isAnonymous ? "dropdown" : ""} aria-expanded="false">
                  <span class="d-flex align-items-center h5 text-hover-primary mb-0 text-truncate">
                    <span className="d-inline-block text-truncate" id="toolbox-flow-name">
                      {flow.name}
                    </span>
                  </span>
                  <span class="d-block font-size-sm text-body">
                    {!isAnonymous ? `Plan ${(user?.license?.name ?? "básico").toLowerCase()}` : "Demo"}
                  </span>
                </div>

                <div class="dropdown-menu pr-1" aria-labelledby="dropdownFlowSettings">
                  {!isAnonymous ? <>
                    <Link to="/flow/edit_flow" class="dropdown-item">
                      <i class="fas fa-edit dropdown-item-icon mr-1"></i>Editar proceso
                    </Link>
                    <a href={`/app/settings/billing`} class="dropdown-item">
                      <i class="fas fa-money-bill dropdown-item-icon mr-1"></i>Ver plan
                    </a>
                  </> : null}
                </div>
              </div>

            </div>
          </div>

          <Toolbox
            toolboxRef={toolboxRef}
            flowId={flow.id}
            nodes={nodes.filter((item) => item.type == "node")}
            isDisabled={isExecMode}
            isAnonymous={isAnonymous}
            onClickNode={onClickNode}
          />

        </div>

        <div id="workspace" class={"w-100 mr-4 " + (!isRPADesigner ? "pl-5" : "pl-3")}>
          {!isRPADesigner && (
            <div className="d-flex justify-content-between" id="workspace-header">

              <HelpMessage delay={500}
                style={{ maxWidth: "200px", marginTop: "-5px" }}
                message={!isExecMode ? "Configura las acciones y flujo de tu proceso" : "Visualiza tu proceso de forma gráfica"}>
                <div id="workspace-switch" className="rounded px-2">
                  <div class="custom-control custom-switch my-auto accent">
                    <input
                      id="execMode"
                      name="execMode"
                      type="checkbox"
                      class="custom-control-input accent"
                      checked={isExecMode}
                      onChange={() => onChangeExecMode(!isExecMode)}
                    />
                    <label class="custom-control-label input-label" for="execMode">

                      <span>{!isExecMode ? "Modo edición" : "Modo visual"}</span>

                    </label>
                  </div>
                </div>
              </HelpMessage>

              {!isExecMode && (
                <div id="flow-tab">
                  <Tab
                    flow={flow}
                    route={"/flow"}
                    title={"Proceso"}
                    caption={"Diseña tu proceso con las acciones que necesites"}
                    onClick={refetchExecutions}
                  />

                  <Tab
                    flow={flow}
                    route={"/results"}
                    title={"Resultados"}
                    caption={"Visualiza los resultados y detalles de tus procesos"}
                  />

                  <Tab
                    flow={flow}
                    route={"/data"}
                    title={"Tablas"}
                    caption={"Guarda información de tu proceso como datos de clientes, empleados o solicitudes"}
                  />
                  <label className="flow-tab-padding"></label>
                </div>
              )}

              <div id="workspace-actions" className="d-flex">

                <span className="mr-1 d-flex align-items-center">


                  {!isExecMode ?
                    <HelpMessage message="Obtén recomendaciones de procesos de manera fácil y rápida"
                      style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                      <Link to="/flow/suggestions" className="btn btn-sm btn-outline-primary mr-1 idea-button">
                        <i class="tio-magic-wand mr-1"></i> Generar Ideas
                      </Link>
                    </HelpMessage> :
                    <HelpMessage message="Comparte tu proceso con otras personas, permitiendo a otros colaborar"
                      style={{ marginTop: "-5px", maxWidth: "14rem" }}>
                      <Link to="/flow/share" className="btn btn-sm btn-outline-primary mr-1 share-button">
                        <i class="fas fa-share mr-1"></i> Compartir
                      </Link>
                    </HelpMessage>
                  }

                  {!isAnonymous ?
                    <a href={`/app/settings`} class="avatar avatar-sm avatar-circle ml-2" aria-haspopup="true"
                      id="dropdownGeneralSettings" data-toggle="dropdown" aria-expanded="false">
                      <img class="avatar-img" src="/theme/img/160x160/img1-b.jpg" alt="Profile image" />
                    </a> : null}


                  <div class="dropdown-menu" aria-labelledby="dropdownGeneralSettings">

                    <a href={`/app/settings/billing`} class="dropdown-item-text">
                      <div class="d-flex align-items-center">
                        <div class="avatar avatar-sm avatar-circle mr-3">
                          <img class="avatar-img" src="/theme/img/160x160/img1-b.jpg" alt="Profile image" />
                        </div>
                        <div class="flex-grow-1 ms-3">
                          <h5 class="mb-0">{user?.firstName ?? ""} {user?.lastName ?? ""}</h5>
                          <p class="card-text text-body">
                            {!isAnonymous ? `Plan ${(user?.license?.name ?? "básico").toLowerCase()}` : "Demo"}
                          </p>
                        </div>
                      </div>
                    </a>

                    <hr className="my-2" />

                    {!isExecMode ?
                      <Link to="/flow/share" className="dropdown-item">
                        <i class="fas fa-share dropdown-item-icon mr-1"></i> Compartir
                      </Link> : null}

                    <Link to="/flow/edit_flow" class="dropdown-item">
                      <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar proceso
                    </Link>

                    <hr className="my-2" />

                    <a href={`/app/settings/flows`} class="dropdown-item">
                      <i class="fas fa-list dropdown-item-icon mr-1"></i> Ver procesos
                    </a>


                    <a href={`/app/settings`} class="dropdown-item">
                      <i class="fas fa-cog dropdown-item-icon mr-1"></i> Ajustes
                    </a>


                    {isDeveloper && (
                      <>
                        <hr className="my-2" />

                        <button
                          className="dropdown-item"
                          onClick={() => {
                            postMessage({ type: "open_capture", flow_id: flow.id, page_id: activePage });
                          }}>
                          <i class="fa-solid fa-mouse-pointer dropdown-item-icon mr-1"></i> RPA Debug
                        </button>
                      </>
                    )}

                    <hr className="my-2" />

                    <a href="/logout" className="dropdown-item">
                      <i class="fa-solid fa-sign-out-alt dropdown-item-icon mr-1"></i> Cerrar sesión
                    </a>

                  </div>
                </span>

                {isAnonymous && (
                  <button className="btn btn-primary btn-sm" onClick={() => setIsContactUsShown(true)}>
                    <i class="fa-solid fa-comment mr-1"></i> Contáctanos
                  </button>
                )}

              </div>
            </div>
          )}

          {isExecMode ? (
            <ExecMode
              ref={execModeRef}
              isExecMode={isExecMode}
              flow={flow}
              onChangeExecMode={onChangeExecMode}
            />
          ) : (
            <Switch>
              <Route path="/flow" render={(props) =>
                <Board
                  {...props}
                  nodes={nodes}
                  edges={edges}
                  flowId={flow.id}
                  toolboxRef={toolboxRef}
                  activePage={activePage}
                  selectedNodes={selectedNodes}
                  showAnimations={showAnimations}
                  isFlowBeingSaved={isFlowBeingSaved}
                  isGeneratingFlow={isGeneratingFlow}
                  isRPADesigner={isRPADesigner}
                  isDeveloper={isDeveloper}
                  isAnonymous={isAnonymous}
                  isAnimationOptionShown={isAnimationOptionShown}
                  isPaginationShown={isPaginationShown}
                  reactFlowWrapper={reactFlowWrapper}
                  setActivePage={setActivePage}
                  setReactFlowInstance={setReactFlowInstance}
                  onDropNode={onDropNode}
                  onDragNode={onDragNode}
                  onConnectEdge={onConnectEdge}
                  onUpdateEdge={onUpdateEdge}
                  onNodesChange={onNodesChange}
                  onEdgesChange={onEdgesChange}
                  onClickNode={onClickNode}
                  onClickAnnotation={onClickAnnotation}
                  onDeleteSelectedNodes={onDeleteSelectedNodes}
                  onClickUndo={onClickUndo}
                  onClickRedo={onClickRedo}
                  onChangeAnimations={onChangeAnimations}
                  onGenerateFlow={onGenerateFlow}
                />
              } />
              <Route path="/results" render={() =>
                <Activity
                  flow={flow}
                />
              } />

              <Route path="/data" render={() =>
                <Tables
                  flowId={flow?.id}
                />
              } />
              <Redirect to={"/flow"} />
            </Switch>
          )}
        </div>

      </div>
    </div>

    {isDesignerShow && (
      <Modal
        width={800}
        height={600}
        animation={""}
        onClose={() => setIsDesignerShow(false)}
        component={RPADesigner}
      />
    )}

    {isContactUsShown && (
      <Modal
        width={500}
        height={500}
        animation={""}
        onClose={() => setIsContactUsShown(false)}
        component={Contact}
      />
    )}

    {isExecutionDetailsShown && (
      <Modal
        width={800}
        height={800}
        animation={""}
        executionId={actualExecutionId}
        onClose={() => setIsExecutionDetailsShown(false)}
        component={Execution}
      />
    )}

    <WSManager
      ref={wsExecutionRef}
      onMessageReceived={onExecutionMessage}
    />

    <WSManager
      ref={wsNodeRef}
      onMessageReceived={onNodeMessage}
    />

  </div>
);

export default ControllerView;