import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Modal } from "seed/helpers";
import StyleColor from "components/util/helpers/StyleColor"
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import Forms from "components/misc/apps/forms/Forms";
import ExecMode from "components/visual_mode/ExecMode";
import WSManager from "components/util/ws";
import "styles/css/presentation/Presentation.css";

const PresentationModeView = ({
  user,
  flow,
  reset,
  roomName,
  isEditModalShown,
  isTemplatesShown,
  isShareShown,
  isTutorialFinished,
  execModeRef,
  onCloseEditModal,
  onCloseTemplatesModal,
  onCloseShareModal,
  onResetPresentation,
  onExecutionMessage,
}) => (
  <BrowserRouter basename={`/p/${flow.token}`}>
    <div className="d-flex justify-content-center">
      <div id="presentation-mode" className="card mt-3 p-0">
        <div className="w-100 h-100">

            <ExecMode
              ref={execModeRef}
              flow={flow}
              isShareMode={true}
            />

          {!isTutorialFinished ?
            <div id="presentation-ctas-frame"
            ></div> : null}

        </div>
      </div>
    </div>

    <StyleColor user={user} />

    {isShareShown && (
      <Modal
        width="650"
        height={window.innerHeight - 150}
        component={() => <Collaboration flowId={flow.id} showLink={true} />}
        onClose={onCloseShareModal}
      />
    )}

    <WSManager
      initialRoom={roomName}
      autoSuscribe={true}
      onMessageReceived={onExecutionMessage}
    />

    <Forms flow={flow} />

  </BrowserRouter>
)

export default PresentationModeView;