import React from "react";
import moment from "moment";
import { executionStatus } from "components/start/executions/util/executions";
import { getObjectComponent } from "components/util/table";

const mergeData = (outputStructure, appdatatypes, result) => {

  const mergedData = {};
  outputStructure.forEach((output) => {

    const { name, value, label } = output;
    
    if(typeof value == "string" && value.startsWith("#") && Object.keys(result).length > 0) {

      const dataKey = value.slice(1);
      const datatype = appdatatypes.find(datatype => datatype.name === name);

      if(datatype) {

        const fieldLabels = datatype.structure.reduce((acc, field) => {
          const fieldValue = result[dataKey];
          if(fieldValue) acc[field.label] = fieldValue[field.name];
          return acc;
        }, {});

        return mergedData[label] = fieldLabels;

      }

      return mergedData[label??name] = result[dataKey];

    }
    
    mergedData[label??name] = value;

  });

  return mergedData;

}

const ExecutionDetailsView = ({
  idx,
  randomKey,
  execution,
  details,
  style,
  isDeveloperMode,
  onResumeExecution,
  onCancelExecution,
  onShowDetails
}) => (
  <div className="w-100 h-100">
    <div
      class="d-flex justify-content-between align-items-center pr-2 py-2 w-100 mb-2"
      style={{
        paddingLeft: "1.4rem",
        border: "1px solid #E7EAF3",
        background: "white",
        ...style
      }}
    >
      <div className="d-flex align-items-center w-100 justify-content-between">
        <div className="d-flex align-items-start my-2" style={{ maxWidth: "calc(100% - 120px)" }}>
          <i className={`${executionStatus[execution.status]?.icon} ${executionStatus[execution.status]?.color} mt-1`} />
          <div className="mx-3 w-100">
            <div className="w-100 pr-1">
              <div className="h5 text-truncate">

                {
                  function () {

                    const steps = execution.executionSteps ?? [];
                    const lastStep = steps[steps.length - 1];
                    const uiSettints = (lastStep?.flowNode?.action?.uiSettings ?? {});
                    const title = uiSettints?.node?.title ?? "N/A";

                    return execution.flow.name;

                  }()
                }

                {
                  !execution.isRead && (
                    <span className="badge badge-pill badge-primary ml-2">Nuevo</span>
                  )
                }

              </div>
            </div>
            <div className="h6 font-weight-light mb-0 text-truncate">
              {moment(execution.startDate).format(
                (new Date(execution.startDate)).toDateString() == (new Date()).toDateString()
                  ? "HH:mm" : "DD.MMM")}
            </div>
          </div>
        </div>
        <div className="d-flex flex-column pl-3">
          {
            execution.status == "AWAITING" && (
              <>
                <button
                  className="btn btn-sm btn-primary mb-1 py-1 px-0"
                  onClick={(e) => { e.stopPropagation(); onResumeExecution(execution); }}
                  style={{ width: "5.1rem" }}
                >
                  Reanudar
                </button>
                <button
                  className="btn btn-sm btn-lighter py-1 px-0"
                  onClick={(e) => { e.stopPropagation(); onCancelExecution(execution); }}
                  style={{ width: "5.1rem" }}
                >
                  Cancelar
                </button>
              </>
            )
          }
          {
            execution.status != "AWAITING" && (
              <>
                <button
                  className="btn btn-sm btn-outline-primary mb-1 mr-2"
                  data-toggle="collapse"
                  data-target={"#exec_" + idx + randomKey}
                  aria-expanded="false"
                  aria-controls={"exec_" + idx + randomKey}
                  onClick={() => {
                    onShowDetails(execution);
                  }}
                >
                  Ver resultados
                </button>
              </>
            )
          }
        </div>
      </div>
    </div>
    <div class="collapse ml-2" id={"exec_" + idx + randomKey} >
      <ul class="border-left mr-1 mt-3 ml-2">

        {
          !details?.executionSteps && 
            <div class="d-flex justify-content-center align-items-center">
              <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
        }

        {details?.executionSteps?.map((step, j) => (
          <li key={j} className="d-flex flex-column mb-3">
            <div 
              className="card card-body"
              style={{
                backgroundColor: "white",
              }}
            >
              <div className="d-flex justify-content-between align-items-center py-1 w-100">
                <div className="d-flex justify-content-between align-items-center w-100">
                  <div className="d-flex align-items-start">
                    <i class={`${executionStatus[details.status]?.icon} ${executionStatus[step.status]?.color} mt-2`} />
                    <div className="mx-3">
                      <span className="h6">
                        Paso {step.step + 1}. 
                        <i className={`fas fa ml-2 mr-1 ${step?.flowNode?.action?.app?.uiSettings?.icon}`} />
                        {(step?.flowNode?.action?.uiSettings.general ?? {})?.title}                        
                      </span>
                      <div className="h6 font-weight-light">
                        {moment(step?.startDate).format(
                          (new Date(step?.startDate))
                            .toDateString() == (new Date()).toDateString() ? "HH:mm" : "DD.MMM")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mx-3">

                {
                  step.status === "FAILED" && (
                    <div
                      className="d-flex align-items-center text-danger mx-3"
                      style={{
                        cursor: "pointer",
                        userSelect: "none"
                      }}
                    >
                      <i class="fas fa-exclamation-triangle mx-2" />
                      {details?.exception?.details}
                    </div>
                  )
                }

                {
                  function(){

                    const appDatatypes = [
                      ...(step?.flowNode?.action?.app?.datatypes ?? []),
                      {
                        name: "file",
                        label: "Archivo",
                        structure: [
                          {
                            "name": "id",
                            "type": "string",
                            "label": "ID"
                          },
                          {
                            "name": "url",
                            "type": "string",
                            "label": "URL"
                          },
                          {
                            "name": "hash",
                            "type": "string",
                            "label": "Hash"
                          },
                          {
                            "name": "name",
                            "type": "string",
                            "label": "Nombre"
                          },
                          {
                            "name": "size",
                            "type": "number",
                            "label": "Tamaño"
                          },
                          {
                            "name": "created_at",
                            "type": "datetime",
                            "label": "Fecha de creación"
                          }  
                        ]
                      }
                    ];

                    const inputStructure = step?.flowNode?.inputValue ?? [];
                    const outputStructure = step?.flowNode?.outputValue ?? [];
                    const result = step?.result ?? {};
                    
                    console.log("Creating input result");
                    console.log(inputStructure)
                    const finalInputResult = mergeData(inputStructure, appDatatypes, {});
                    console.log(finalInputResult)

                    console.log("Creating output result");
                    const finalOutputResult = mergeData(outputStructure, appDatatypes, result);
                    console.log(finalOutputResult)

                    return <>
                      
                      <div class="mt-2 mb-1">
                        <span>Entradas</span>
                        <button
                          type="button"
                          class="btn btn-sm btn-link"
                          data-toggle="collapse"
                          data-target={"#exec_" + idx + "step_inputs_" + j + randomKey}
                          aria-expanded="false"
                          aria-controls={"exec_" + idx + "step_inputs_" + j + randomKey}
                        >
                          Ver detalles <i class="fas fa-angle-right" />
                        </button>
                      </div>
                      <div class="collapse" id={"exec_" + idx + "step_inputs_" + j + randomKey}>
                        {getObjectComponent(finalInputResult)}
                      </div>
                      
                      <div class="mt-2 mb-1">Resultados</div>
                      {getObjectComponent(finalOutputResult)}

                    </>;

                  }()
                }

              </div>

            </div>
          </li>
        ))
        }

      </ul>

    </div>
  </div>
);

export default ExecutionDetailsView;