import React, { useState, useRef } from "react";
import Overlay from 'react-bootstrap/Overlay';


function CopyClipboardButton({ link }) {

    const target = useRef(null);
    const [showPopover, setShowPopover] = useState(false);

    const onCopyToClipboard = () => {
        navigator.clipboard.writeText(link).then(() => {
            setShowPopover(true);
            setTimeout(() => setShowPopover(false), 1500);
        });
    }

    return (
        <>
            <button 
                type="button"
                className="btn btn-light px-2 py-0 ml-2" ref={target} 
                onClick={(e) => { e.stopPropagation(); onCopyToClipboard();}}>
                <i class="far fa-copy"></i>
            </button>
            <Overlay target={target.current} show={showPopover} placement="bottom">
                {({
                    placement: _placement,
                    arrowProps: _arrowProps,
                    show: _show,
                    popper: _popper,
                    hasDoneInitialMeasure: _hasDoneInitialMeasure,
                    ...props
                }) => (
                    <div
                        {...props}
                        style={{
                            position: 'absolute',
                            backgroundColor: '#f0f3f5',
                            padding: '2px 10px',
                            color: '#40454a',
                            borderRadius: 3,
                            marginTop: "10px",
                            zIndex: 10000,
                            ...props.style,
                        }}
                    >
                        Link copiado
                    </div>
                )}
            </Overlay>
        </>
    );
}

export default CopyClipboardButton;