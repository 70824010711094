import React, { useEffect } from "react";
import mixpanel from "mixpanel-browser";

const source = "mixpanel";

const Mixpanel = {
  Initialize: () => {
    mixpanel.init("4b4499d2eba1d94e76c5d3d0239cf732", {
      debug: false,
      track_pageview: true,
      persistence: "localStorage",
    });
  },
  CreateEvent: (action, attributes = {}) => 
    mixpanel.track(action, attributes),
  Identify: (id, attributes = {}) => {
    mixpanel.identify(id)
    mixpanel.people.set(attributes);
  }
}

const Hotjar = {
  Initialize: () => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:5165586,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    `;
    document.head.appendChild(script);
  },
  CreateEvent: (action, attributes = {}) => 
    window.hj("event", action, attributes),
  Identify: (id, attributes = {}) =>
    window.hj("identify", id, attributes)
}

const Analytics = {
  Initialize: () => {
    Mixpanel.Initialize();
    Hotjar.Initialize();
  },
  CreateEvent: (action, attributes = {}) => {
    if(source === "mixpanel") Mixpanel.CreateEvent(action, attributes);
    if(source === "hotjar") Hotjar.CreateEvent(action, attributes);
  },
  Identify: (id, attributes = {}) => {
    Mixpanel.Identify(id, attributes);
    Hotjar.Identify(id, attributes);
  }
}

const AnalyticsInit = () => {
  useEffect(() => {
    Analytics.Initialize();
  }, []);
  return <></>;
}

export { Analytics, AnalyticsInit };