import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";


const FlowListView = ({
  flows,
  folder,
  onClickDelete,
}) => (

  <ul class="list-group" id="flow-list">

    {flows.map((flow, index) => (
      <li class="flow list-group-item mb-1" key={flow.id}>

        <div class="row align-items-center gx-2">
          <div class="col-auto">
            <img class="avatar avatar-xs" src="/resources/images/icon-45.svg" alt="" />
          </div>

          <div class="col">
            <h5 class="mb-0">
              <Link to={`/flow/${flow.id}/flow`} className="text-dark">
                {flow.name}
              </Link>
              {flow.isTemplate
                ? flow.templateSettings?.template_type == "PARTIAL" ?
                  <span class="badge badge-soft-primary ml-2">Plantilla (Parcial)</span> :
                  <span class="badge badge-soft-primary ml-2">Plantilla</span>
                : null}
            </h5>
            <ul class="list-inline list-separator small">
              <li class="list-inline-item">
              <i class="far fa-folder mr-1" style={{ display: flow.folder ? "inline-block" : "none" }}></i>
              <span>{flow.folder?.name ?? 'General'}</span>
              </li>
            </ul>
          </div>

          <div class="col-auto">

            <Link to={`/flow/${flow.id}/flow`} className="btn btn-primary btn-sm mr-2">
              Ir a proceso
            </Link>

            <div class="hs-unfold">
              <a
                className="btn btn-lighter btn-sm"
                type="button"
                id={`dropdownMenuButton-${index}`}
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{ cursor: "pointer" }}
              >
                <span class="d-none d-sm-inline-block mr-1">Más</span>
                <i class="tio-chevron-down"></i>
              </a>

              <div className="dropdown-menu" aria-labelledby={`dropdownMenuButton-${index}`} >

                <Link to={`/settings/flows/${flow.id}/edit_flow`} className="dropdown-item">
                  Editar proceso
                </Link>
                <a
                  className="dropdown-item"
                  href=""
                  onClick={(event) => {
                    event.preventDefault();
                    onClickDelete(flow.id);
                  }}>
                  Eliminar
                </a>
              </div>

            </div>
          </div>
        </div>

      </li>
    ))}

    {flows.length == 0
      ? <div id="no-flows" className="text-center my-3 h-100">
        <i className="fas fa-solid fa-inbox mb-2"></i>
        <br />No tienes procesos registrados<br />
        <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-primary btn-sm mt-3">
          Crea un nuevo proceso
        </Link>
      </div>
      : null}

  </ul>

)

export default FlowListView;