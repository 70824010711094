import React from "react";
import { Link } from "react-router-dom";
import { StyleTag } from "seed/helpers";
import ScrollArrow from "components/util/helpers/ScrollArrow";


const FlowsView = ({
  userId,
  folder,
  folders,
  recentFlows,
  flowCount,
  setSelectedFolder,
  onDeleteFlow,
}) => (
  <div id="start-flows">
    <div id="start-flows-container">
      <div id="start-flows-header" className={"d-flex align-items-center ml-1 justify-content-between"}>

        <h2 className="mb-0" style={{ color: "white", paddingTop: "0.1rem" }}>
          Procesos recientes
        </h2>

        <div className="d-flex">

          <div className="dropdown dropup">
            
            <div className="d-flex btn btn-lighter-white justify-content-between align-items-center dropdown-toggle"
              data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
              style={{ color: "#555a60" }}>
              <div></div>
              <div className="d-flex align-items-center">
                <span className="text-truncate text-center" style={{ maxWidth: "12.7rem" }}>
                  {folder ? <i class="fas fa-folder mr-2"></i>: null } {folder?.name ?? "Todos"}
                </span>
              </div>
              <i class="fas fa-caret-down ml-2"></i>
            </div>

            <StyleTag content={`
              .page-item .page-item-edit{
                display: none;
                transition: 0.3s;
              }
              .page-item:hover .page-item-edit{
                display: inline-block;
              }
            `} />

            <div class="dropdown-menu">
              <a class="dropdown-item page-item d-flex justify-content-between align-items-center" key={0}
                style={{ cursor: "pointer" }}
                onClick={() => setSelectedFolder(null)}>
                <div class="py-1">Todos</div>
              </a>

              {folders.map((folder) => (
                <a class="dropdown-item page-item d-flex justify-content-between align-items-center" key={folder.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => setSelectedFolder(folder.id)}>
                  <div class="py-1">{folder.name}</div>
                  <Link class="py-1 btn-lighter page-item-edit rounded"
                    to={`/${folder.id}/edit_folder`}
                    style={{paddingLeft: "0.7rem", paddingRight: "0.7rem"}}
                    onClick={e => e.stopPropagation()}>
                    <i class="fa-solid fa-pen" style={{ fontSize: "0.9em", color: "#999", border: "none" }}></i></Link>
                </a>
              ))}
              <div class="dropdown-divider"></div>
              <Link class="dropdown-item" to={`/create_folder`}>
                <i class="fa-solid fa-plus mr-2"></i>Crear carpeta
              </Link>

            </div>
          </div>

          <Link to={`${folder?.id ?? 0}/create_flow`}
            className={"btn btn-primary ml-2 mr-1"}
            style={{ display: recentFlows.length > 0 || folder != null ? "block" : "none" }}>
            <i class="fas fa-plus mr-2"></i>Crear proceso
          </Link>
        </div>
      </div>

      {recentFlows.length > 0 ? (
        <div id="flows" class="w-100">
          <div id="flows-container" class="d-flex flex-wrap justify-content-between">
            <ScrollArrow scrollAmount={600} itemsContainerStyle={{ width: "100%" }} align="left" arrowsWidth="70px">
              {recentFlows.map((flow) => (
                <div key={flow.id} className={"flow col-12 px-0 col-md-" + (recentFlows.length > 1 ? "6" : "12")}>
                  <div className="card card-hover-shadow pb-0 px-0 mr-2">
                    <div className="d-flex justify-content-between align-items-center mb-0">
                      <div>
                        <span class="badge bg-soft-success text-success p-2 ml-3">Activo</span>
                        {function () {
                          const participants = flow?.participants ?? [];
                          const participant = participants.find((participant) => participant?.user?.id == userId);
                          const isParticipant = participant != null && participant != undefined;

                          if (isParticipant) {
                            return (<span class="badge bg-soft-primary text-primary p-2 ml-2"
                              style={{ margin: "1.2rem 0" }}>Compartido</span>);
                          }

                        }()}
                      </div>

                      {flow.user.id == userId && (
                        <div class="btn-group dropup flow-settings">
                          <button type="button" class="btn btn-lighter text-center dropdown-toggle py-3 px-4 m-2 rounded-circle"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                            onClick={e => e.preventDefault()}
                          >
                            <i class="fas fa-ellipsis-v"></i>
                          </button>
                          <div class="dropdown-menu pr-1 flow-settings-content">
                            <Link to={`/${flow.id}/share`} class="dropdown-item">
                              <i class="fa-solid fa-share dropdown-item-icon mr-1"></i> Compartir
                            </Link>
                            <Link to={`/${flow.id}/edit_flow`} class="dropdown-item">
                              <i class="fas fa-edit dropdown-item-icon mr-1"></i> Editar proceso
                            </Link>

                            <a class="dropdown-item" style={{ cursor: "pointer" }}
                              onClick={e => { e.preventDefault(); onDeleteFlow(flow.id); }}>
                              <i class="tio-delete dropdown-item-icon mr-1"></i> Eliminar
                            </a>

                          </div>
                        </div>
                      )}
                    </div>

                    <div to={`/flow/${flow.id}/flow`} class="card-body text-center flow-contents mt-n2">
                      <Link to={`/flow/${flow.id}/flow`} class="avatar avatar-xl avatar-border-lg mb-1">
                        <img src="/resources/images/icon-45.svg" alt="Flow" style={{ width: "3.7rem" }} />
                      </Link>

                      <h3 className={"flow-name mt-0 mb-0"}>
                        <Link to={`/flow/${flow.id}/flow`} class="text-dark h4 text-truncate w-100 d-inline-block mb-0">
                          {flow.name}
                        </Link>
                      </h3>

                      <div class="flow-folder w-100">
                        <a className="text-dark text-truncate w-100 d-inline-block" href="#"
                          onClick={() => setSelectedFolder(flow.folder?.id)}>
                          <i class="far fa-folder" 
                            style={{ display: flow.folder ? "inline-block" : "none", marginRight: "0.35rem" }}></i>
                          <span>{flow.folder?.name ?? 'General'}</span>
                        </a>
                      </div>

                    </div>

                    <div class="card-footer">
                      <div class="py-1 w-100 px-3">
                        <Link to={`/flow/${flow.id}/flow`}
                          class="btn btn-primary w-100">
                          <i class="fas fa-play mr-2"></i>
                          Ir a proceso
                        </Link>
                      </div>
                    </div>

                  </div>
                </div>
              )).concat(
                flowCount > 4 && recentFlows.length >= 4 ?
                  [

                    <div key="more" className="flow col-4 px-0 col-md-3" style={{ minHeight: "20rem" }}>
                      <div className="card card-hover-shadow pb-0 px-0 mr-2 h-100">
                        <div className="d-flex justify-content-between align-items-center mb-0">
                        </div>

                        <Link to={`/settings/flows`} class="card-body text-center flow-contents">
                          <h3 className={"flow-name mt-0 mb-0"}>
                            <div class="text-dark h4 text-truncate w-100 d-inline-block mb-0 mt-10">
                              Ver todos
                            </div>
                          </h3>

                        </Link>

                      </div>
                    </div>
                  ]

                  : [])

              }

            </ScrollArrow>
          </div>

        </div>
      ) : (
        <div id="no-flows" class="card w-100 shadow-none mt-2">
          <div class="card-body">
            <div className="d-flex flex-column justify-content-center py-9">
              <span id="no-flows-title" className="text-center">Todavía no tienes procesos registrados</span>
              <div className="d-flex justify-content-center">
                <Link to={`${folder?.id ?? 0}/create_flow`} className="btn btn-primary w-25" href="#">
                  <i class="fas fa-plus mr-2"></i>Crear proceso
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

    </div>

  </div>
)

export default FlowsView;