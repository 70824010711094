import React from "react";
import HelpMessage from "components/util/helpers/HelpMessage"


const UseCaseCategory = ({ idx, category, isActive, onChangeCategory }) => (
    <HelpMessage delay={0} message={category?.uiSettings?.display?.title} style={{ marginTop: "-7px" }}>
        <div className="mx-2" style={{ cursor: "pointer" }} onClick={() => onChangeCategory(category)}>
            <span className={`icon icon-lg icon-circle ${isActive ? "icon-primary text-white" : "icon-light text-primary"}`}
                style={{ border: "2px solid white" }} >
                <i className={category?.uiSettings?.display?.icon}></i>
            </span>
        </div>
    </HelpMessage>
)

export default UseCaseCategory;