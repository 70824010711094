/* eslint-disable max-len */
import React from "react";
import { useEdges } from 'reactflow';

const defaultPoints = [
    { "mode": "NEXT", "position": "TOP" },
    { "mode": "NEXT", "position": "RIGHT" },
    { "mode": "NEXT", "position": "BOTTOM" },
    { "mode": "NEXT", "position": "LEFT" }
]

function HelpPlaceholder({ nodeId, action }) {

    const edges = useEdges();
    const sourceEdge = edges.find(edge => edge.target == nodeId);
    const placeholderStyle = { border: "none", background: "white", fontSize: "10px", fontStyle: "italic", width: "60px", color: "#8797a8" };

    const getPositionMapper = (position) => {
        if (position == "top")
            return { position: "absolute", top: "-30px", left: "22px" };
        if (position == "bottom")
            return { position: "absolute", bottom: "-30px", left: "22px" };
        if (position == "left")
            return { position: "absolute", top: "0", left: "-70px" };
        if (position == "right")
            return { position: "absolute", top: "0", right: "-65px" };
        return {};
    }

    const getPlaceholderName = (type) => {
        if (action.name == "conditional") {
            if (type == "next") return "Si cumple";
            if (type == "else") return "Si no cumple";
            // TODO check if (type == "end") return "Al finalizar";
        }
        if (action.name == "iterate") {
            if (type == "next") return "Repetir";
            // TODO check if (type == "end") return "Al finalizar";
        }
        return "";
    }

    const relationships = action.uiSettings?.relationships ?? defaultPoints;
    const placeholders = [];

    if (action.name == "conditional") {
        
        if (sourceEdge) {
            
            const sourcePosition = (sourceEdge?.targetHandle ?? "_").split("_")[1].toUpperCase();

            if (sourcePosition == "LEFT" || sourcePosition == "RIGHT") {
                placeholders.push(
                    <div style={getPositionMapper("top")}> <div style={placeholderStyle}>Si no cumple</div></div>
                );
                placeholders.push(
                    <div style={getPositionMapper("bottom")}> <div style={placeholderStyle}>Si cumple</div> </div>
                );
            } else if (sourcePosition == "TOP" || sourcePosition == "BOTTOM") {
                placeholders.push(
                    <div style={getPositionMapper("left")}> <div style={placeholderStyle}>Si no cumple</div></div>
                );
                placeholders.push(
                    <div style={getPositionMapper("right")}> <div style={placeholderStyle}>Si cumple</div> </div>
                );
            }
        }
        
    } else {
        for (const relationship of relationships) {

            const mode = relationship.mode.toLowerCase();
            const position = relationship.position.toLowerCase();
    
            placeholders.push(
                <div style={getPositionMapper(position)}>
                    <div style={{ border: "none", background: "white", fontSize: "10px", fontStyle: "italic", width: "60px", color: "#8797a8" }}>
                        {getPlaceholderName(mode)}
                    </div>
                </div>
            );
        }   
    }

    return (<> {placeholders.map((item) => item)} </>);
}

export default HelpPlaceholder;
