import { React, useState } from "react";

const Selection = ({ children }) => {
  return children;
  // const [selectionState, setSelectionState] = useState({
  //   mouseDown: false,
  //   startPoint: null,
  //   endPoint: null,
  //   selectionBox: {},
  //   selectedItems: {},
  //   appendMode: false,
  // });

  // const getCoordinates = (e) => {
  //   const rect = e.currentTarget.getBoundingClientRect();
  //   const x = e.clientX - rect.left;
  //   const y = e.clientY - rect.top;
  //   return { x, y };
  // }

  // const onMouseDown = (e) => {

  //   if(e.button !== 0) return;

  //   const { x, y } = getCoordinates(e);

  //   setSelectionState({
  //     ...selectionState,
  //     mouseDown: true,
  //     startPoint: { x, y },
  //     endPoint: { x, y },
  //   });

  // }

  // const onMouseMove = (e) => {

  //   if(selectionState.mouseDown) {

  //     const { x, y } = getCoordinates(e);

  //     setSelectionState({
  //       ...selectionState,
  //       selectionBox: {
  //         left: selectionState.startPoint && selectionState.endPoint && selectionState.endPoint.x < selectionState.startPoint.x 
  //           ? selectionState.endPoint.x 
  //           : selectionState.startPoint.x,
  //         top: 
  //           selectionState.startPoint && selectionState.endPoint && selectionState.endPoint.y < selectionState.startPoint.y 
  //           ? selectionState.endPoint.y 
  //           : selectionState.startPoint.y,
  //         width: selectionState.startPoint && selectionState.endPoint 
  //           ? Math.abs(selectionState.endPoint.x - selectionState.startPoint.x) 
  //           : 0,
  //         height: 
  //           selectionState.startPoint && selectionState.endPoint ? Math.abs(selectionState.endPoint.y - selectionState.startPoint.y) 
  //           : 0,
  //       },
  //       endPoint: { x, y },
  //     });

  //   }

  // }

  // const onMouseUp = () => {
  //   setSelectionState({
  //     ...selectionState,
  //     mouseDown: false,
  //     selectionBox: null,
  //   });
  // }

  // return (
  //   <div
  //     className="w-100 h-100"
  //     style={{
  //       WebkitUserSelect: "none",
  //       MozUserSelect: "none",
  //       msUserSelect: "none",
  //       userSelect: "none",
  //       cursor: "crosshair",
  //     }}
  //     onMouseDown={onMouseDown}
  //     onMouseMove={onMouseMove}
  //     onMouseUp={onMouseUp}
  //   >

  //     {children}

  //     {
  //       // display selection box only when mouse is down and moving
  //       selectionState.mouseDown && (
  //         <div
  //           style={{
  //             position: "absolute",
  //             zIndex: 1000,
  //             backgroundColor: "rgba(35,90, 150, 0.2)",
  //             border: "1px solid #235a96",
  //             pointerEvents: "none",
  //             ...selectionState.selectionBox,
  //           }}
  //         >
  //         </div>
  //       )
  //     }

  //   </div>
  // );

}

export default Selection;