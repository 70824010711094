import React from 'react';
import ModalWindow from "components/util/helpers/ModalWindow";
import SamplePicker from 'components/misc/apps/rpa/designer/SamplePicker';

const CategoryNames = {
  "CLICK": "Click",
  "DOUBLE_CLICK": "Doble click",
  "DRAG": "Seleccionar",
  "INPUT": "Ingresar dato",
  "GET_TEXT": "Obtener información",
  "GET_ELEMENTS": "Recorrer lista",
}

const RPADesignerView = ({
  category,
  showPicker,
  input,
  parameters,
  screenshot,
  responses,
  onSamplePicked,
  onExecuteAction,
  onRemoveResponse,
  onFinish,
}) => (
  <div
    className='d-flex flex-column h-100 w-100 justify-content-between bg-white'

  >

    <div className=''>
      <div
        style={{
          overflowY: "hidden",
          height: "100vh",
        }}
      >

        <div className='mt-2'>
          <div className='row'>
            <div className='col-12 d-flex justify-content-start pl-4'>
              Acciones
            </div>
          </div>
          <div className='row'>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('CLICK')}
              >
                {CategoryNames["CLICK"]}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('DOUBLE_CLICK')}
              >
                {CategoryNames["DOUBLE_CLICK"]}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('DRAG')}
              >
                {CategoryNames["DRAG"]}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('INPUT')}
              >
                {CategoryNames["INPUT"]}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('GET_TEXT')}
              >
                {CategoryNames["GET_TEXT"]}
              </button>
            </div>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn btn-primary w-100'
                disabled={showPicker}
                onClick={() => onExecuteAction('GET_ELEMENTS')}
              >
                {CategoryNames["GET_ELEMENTS"]}
              </button>
            </div>
          </div>
        </div>

        <div className='mb-4'>
          <div className='row'>
            <div className='col-12 d-flex justify-content-center align-items-center m-0 px-4 my-2'>
              <button
                type='button'
                className='btn w-75 btn-accent'
                onClick={() => onFinish()}
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>

        <hr />

        <div className='mb-2'>
          <div className='row'>
            <div className='col-12 d-flex justify-content-start pl-4'>
              Historial
            </div>
          </div>
        </div>

        {
          responses.slice(-3).map((response, index) => (
            <div
              key={`${response.category}-${response.sample.url}`}
              className='card card-body py-2 my-3'
              style={{
                height: "60px",
                position: "relative",
              }}
            >
              <div className='d-flex justify-content-between align-items-center w-100 h-100'>
                <div className='d-flex'>
                  <i className='fas fa-check-circle text-success mr-2 mt-1'></i>
                  <div>
                    {index + 1} .-
                  </div>
                  <div className='ml-1'>
                    {CategoryNames[response.category]}
                  </div>
                </div>
                <div>
                  <img
                    src={response.sample.url}
                    alt='sample'
                    style={{
                      maxWidth: "50px",
                      maxHeight: "50px",
                      objectFit: "cover",
                    }}
                  />
                </div>
              </div>
              <i
                className='fas fa-times text-danger'
                style={{
                  position: "absolute",
                  top: "2px",
                  right: "5px",
                  cursor: "pointer",
                }}
                onClick={() => onRemoveResponse(index)}
              ></i>
            </div>
          ))
        }

      </div>
    </div>

    {showPicker && (
      <ModalWindow
        id='samplePicker'
        width={1200}
        height={820}
        parameters={{ screenshot, parameters, category, input }}
        callback={(data) => onSamplePicked(data)}
      >
        <SamplePicker />
      </ModalWindow>
    )}

  </div>
);

export default RPADesignerView;