import React, { useState, useRef, useEffect } from "react";
import "styles/css/helpers/ScrollArrow.css";


const defaultLinearGradientColor = {
	red: 255,
	green: 255,
	blue: 255
}


function ScrollArrow({
	children,
	mainContainerStyle = {},
	itemsContainerStyle = {},
	leftButtonStyle = {},
	rightButtonStyle = {},
	scrollAmount = 100,
	backgroundFadeColor = defaultLinearGradientColor,
	infiniteScroll = false,
	arrowsWidth = "40px",
	align = "center"
}) {

	const [itemsContainerMaxScroll, setItemsContainerMaxScroll] = useState(300);
	const [scrollPosition, setScrollPosition] = useState(0);
	const [isContentScrollable, setIsContentScrollable] = useState(false);
	const [showLeftArrow, setShowLeftArrow] = useState(false);
	const [showRightArrow, setShowRightArrow] = useState(false);
	const scrollTabsContainerRef = useRef();


	const handleHorizontalScroll = (scrollAmount) => {

		let newScrollPosition = scrollPosition + scrollAmount;
		let remainingScroll = itemsContainerMaxScroll;

		if (infiniteScroll) {
			if (newScrollPosition < 0 && scrollPosition == 0)
				newScrollPosition = itemsContainerMaxScroll;

			if (newScrollPosition > itemsContainerMaxScroll && scrollPosition == itemsContainerMaxScroll)
				newScrollPosition = 0;
		}

		// Check for right and left scroll, respectively
		if (scrollAmount < 0) remainingScroll = newScrollPosition;
		if (scrollAmount > 0) remainingScroll = itemsContainerMaxScroll - newScrollPosition;

		if (remainingScroll <= itemsContainerMaxScroll * 0.2) {
			// Check for right and left scroll, respectively
			if (scrollAmount < 0) newScrollPosition = 0;
			if (scrollAmount > 0) newScrollPosition = itemsContainerMaxScroll;
		}

		if (newScrollPosition < 0) newScrollPosition = 0;
		if (newScrollPosition > itemsContainerMaxScroll) newScrollPosition = itemsContainerMaxScroll;

		setScrollPosition(newScrollPosition);
		scrollTabsContainerRef.current.scrollLeft = newScrollPosition;
	}

	const onClickScrollLeft = () => {
		handleHorizontalScroll(-scrollAmount);
	};

	const onClickScrollRight = () => {
		handleHorizontalScroll(scrollAmount);
	};

	useEffect(() => {
		const scrollContainer = scrollTabsContainerRef.current;
		if (scrollContainer.scrollWidth > scrollContainer.clientWidth) {
			setShowLeftArrow(false);
			setShowRightArrow(true);
			setIsContentScrollable(true);
		} else {
			setShowLeftArrow(false);
			setShowRightArrow(false);
			setIsContentScrollable(false);
		}

		if (scrollContainer) {
			const scrollWidth = scrollContainer.scrollWidth;
			const clientWidth = scrollContainer.clientWidth;
			const hiddenWidth = scrollWidth - clientWidth;
			setItemsContainerMaxScroll(hiddenWidth);
		} else {
			setItemsContainerMaxScroll(300);
		}
	}, [children, scrollTabsContainerRef.current?.scrollWidth])


	useEffect(() => {
		if (isContentScrollable) {
			if (scrollPosition <= 0) {
				setShowLeftArrow(false);
				setShowRightArrow(true);
			} else if (scrollPosition >= itemsContainerMaxScroll) {
				setShowLeftArrow(true);
				setShowRightArrow(false);
			} else {
				setShowLeftArrow(true);
				setShowRightArrow(true);
			}
		}
	}, [children, scrollPosition, itemsContainerMaxScroll])

	const rgbGradientColor = `${backgroundFadeColor.red}, ${backgroundFadeColor.green}, ${backgroundFadeColor.blue}`;

	return (
		<div id="scroll-arrow-main-container" style={{
			overflow: 'hidden',
			...mainContainerStyle
		}}>
			<div
				className="scroll-btn left-btn"
				style={{
					display: "flex",
					justifyContent: "center",
					width: arrowsWidth,
					visibility: (showLeftArrow || infiniteScroll) ? "visible" : "hidden",
					background: `
						linear-gradient(
							to left,
							rgba(${rgbGradientColor}, 0) 0%,
							rgba(${rgbGradientColor}, 0.5) 50%,
							rgba(${rgbGradientColor}, 1) 100%
						)`
				}}
				onClick={onClickScrollLeft}
			>
				<button style={leftButtonStyle}>
					&#10094;
				</button>
			</div>
			<div className={`scroll-container ${!isContentScrollable ? "justify-content-" + align : ""}`}
				style={itemsContainerStyle} ref={scrollTabsContainerRef}
			>
				{children}
			</div>
			<div
				className="scroll-btn right-btn"
				style={{
					display: "flex",
					justifyContent: "center",
					width: arrowsWidth,
					visibility: (showRightArrow || infiniteScroll) ? "visible" : "hidden",
					background: `
						linear-gradient(
							to right, 
							rgba(${rgbGradientColor}, 0) 0%, 
							rgba(${rgbGradientColor}, 0.5) 50%, 
							rgba(${rgbGradientColor}, 1) 100%)`
				}}
				onClick={onClickScrollRight}
			>
				<button style={rightButtonStyle}>
					&#10095;
				</button>
			</div>
		</div>
	)

}

export default ScrollArrow;