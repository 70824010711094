const filtersData = [
  { name: "initials", label: "Para iniciar", helpMessage: "Acciones recomendadas para iniciar el proceso" },
  { name: "updates", label: "Actualizaciones", helpMessage: "Acciones relacionadas para crear o actualizar registros de otros sistemas" },
  { name: "notifications", label: "Notificaciones", helpMessage: "Acciones que realizan notificación a través de diversas apps de comunicación" },
  { name: "ai", label: "IA", helpMessage: "Herramientas IA que permiten integrar capacidades avanzadas para analizar datos, clasificar información, ..." },
]

const categoriesData = [
  { name: "input", label: "Entradas", groupLabel: "Básicos", icon: "fas fa-sign-in-alt"},
  { name: "events", label: "Eventos", groupLabel: "Básicos", icon: "fas fa-bolt" },
  { name: "process", label: "Proceso", groupLabel: "Básicos", icon: "fas fa-gears" },
  { name: "data", label: "Datos", groupLabel: "Básicos", icon: "fas fa-table" },
  { name: "notifications", label: "Mensajes", groupLabel: "Generales", icon: "fas fa-envelope" },
  { name: "documents", label: "Documentos", groupLabel: "Generales", icon: "fas fa-envelope" },
  { name: "ai", label: "IA", groupLabel: "Generales", icon: "fas fa-brain"  },
  { name: "updates", label: "Actualizaciones", groupLabel: "Generales", icon: "fas fa-cloud-arrow-up"}
]


export { filtersData, categoriesData};