import React from "react";
import { DEFINITION_TYPES } from "components/misc/apps/rpa/generator/FlowQuestions";
import "styles/css/misc/Generator.css";

const DefinitionView = ({
  flow,
  index, 
  currentIndex,
  values,
  handleEdit,
  handleAddDepth,
  handleBackDepth,
  flowResult,
  isHovered,
  setIsHovered
}) => (
  <div>
    <div
      className="d-flex justify-content-between align-items-end my-2 px-4"
      style={{  marginLeft: (flow.depth * 1.5) + "rem", position: "relative" }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >

      {
        isHovered && 
          <div style={{ position: "absolute", left: "-10px", top: "30%" }}>
            {
              (flow.depth > 0 && !flow.hasRule) || (flow.hasRule && flow.depth > 1)
                ? <i
                    className="fas fa-angle-double-left px-2"
                    style={{
                      cursor: "pointer", 
                      fontSize: "15px",
                      width: "15%",
                    }}
                    onClick={() => handleBackDepth(index)}
                  />
                : null
            }
          </div>
      }

      <span 
        style={{ 
          width: "5px", 
          backgroundColor: "#f0b000", 
          height: flow.hasRule ? "6.3rem" : "3rem" 
        }}
      />
      
      <div className="d-flex flex-column w-100">

        {
          flow.hasRule &&
            <div 
              className={
                "d-flex flex-column align-items-start justify-content-center " +
                "rounded w-100 mx-2 mb-2"
              }
              style={{
                height: "2.8rem",
                border: "1px solid #ccc",
                borderColor: index == currentIndex ? "#176DC7" : "#ccc",
                background: index == currentIndex ? "#2080E5" : "transparent",
                color: index == currentIndex ? "#fff" : "#777",
                cursor: "pointer"
              }}
              onClick={() => handleEdit(values, index)}
            >
              
              <div className="px-3 font-weight-bold">
                Repetir
              </div>

              { function(){

                const rule = flow.answers?.RULE;

                if(rule) {
                  
                  const condition = rule.split("__")[0];
                  const value = rule.split("__")[1];
                  let label = "Si el texto "

                  switch(condition) {
                    case "IN":
                      label += "incluye ";
                      break;
                    case "GT":
                      label += "es mayor que ";
                      break;
                    case "LT":
                      label += "es menor que ";
                      break;
                    case "NEQ":
                      label += "es diferente a ";
                      break;
                    default:
                      break;
                  }

                  return <span className="px-3">
                    {label + "'" + value + "'"}
                  </span>

                }

              }() }
          </div>
        }
        
        <div
          className={
            "d-flex align-items-center justify-content-center" +
            "px-4 rounded w-100 mx-2"
          }
          style={{
            height: "3rem",
            border: "1px solid #ccc",
            borderColor: index == currentIndex ? "#176DC7" : "#ccc",
            background: index == currentIndex ? "#2080E5" : "transparent",
            color: index == currentIndex ? "#fff" : "#777",
            cursor: "pointer"
          }}
          onClick={() => handleEdit(values, index)}
        >

          <span className="d-flex align-items-center px-3" style={{ width: "85%" }}>
            <span>
              <b>{index + 1}.&nbsp;</b>
            </span>
            <span className="ml-1">
              {flow?.skip ? <i>Omitir</i> : DEFINITION_TYPES[flow.definition]??""}
            </span>
          </span>

          <i
            className="fas fa-pen px-2"
            style={{
              cursor: "pointer", fontSize: "15px",
              color: index == currentIndex ? "#fff" : "#777", width: "15%"
            }}
            onClick={() => handleEdit(values, index)}
          />
          
        </div>

      </div>

      {
        isHovered && 
          <div style={{ position: "absolute", right: "-10px", top: "30%" }}>
            {
              flowResult[index - 1]?.depth > flow.depth || 
              (flowResult[index - 1]?.hasRule && flowResult[index - 1]?.depth > flow.depth)
                ? <i
                    className="fas fa-angle-double-right px-2"
                    style={{
                      cursor: "pointer", 
                      fontSize: "15px",
                      width: "15%",
                    }}
                    onClick={() => handleAddDepth(index)}
                  />
                : null
            }
          </div>
      }

    </div>
  </div>
);

export default DefinitionView;