import React, { useState, useEffect, useRef } from "react";
import { useQuery } from "seed/gql";
import { parseDatatypes } from "components/tables/util/datatypes";
import { filterData, getFilteredColumns } from "components/tables/util/filters";
import ColumnsEvents from "components/tables/Table.lib.columns";
import RowsEvents from "components/tables/Table.lib.rows";
import TableEvents from "components/tables/Table.lib.table";
import TableListeners from "components/tables/Table.lib.listeners";
import View from "components/tables/Table.view";

function Table({
	query,
	filters,
	search,
	pageSize,
	pageNum,
	totalPages,
	values,
	rawValues,
	structure,
	collections = [],
	collectionColumns = [],
	isReadOnly = false,
	trackKeybindings = true,
	useCustomSearch = false,
	useCustomPagination = false,
	useCustomFilters = false,
	useCustomImport = false,
	useCustomExport = false,
	height = "auto",
	loading = false,
	showFilters = true,
	showOptions = true,
	showImport = true,
	showExport = true,
	showSearch = true,
	showForm = false,
	onAddColumn = () => { },
	onChangeColumn = () => { },
	onChangeColumnOptions = () => { },
	onDeleteColumn = () => { },
	onAddRow = () => { },
	onDeleteRow = () => { },
	onInsertRow = () => { },
	onChangeRow = () => { },
	onImport = () => { },
	onExport = () => { },
	onDownloadZip = () => { },
	onPageChange = () => { },
	onChangeSearch = () => { },
	onChangeQuery = () => { },
	onChangeFilters = () => { },
	onClickCell = () => { },
	folioComponent
}) {

	const [_rows, setRows] = useState([]);
	const [_columns, setColumns] = useState([]);
	const [_query, setQuery] = useState("");
	const [_search, setSearch] = useState("");
	const [_filters, setFilters] = useState(filters || []);
	const [_pageNum, setPageNum] = useState(1);
	const [_pageSize, setPageSize] = useState(10);
	const [_totalPages, setTotalPages] = useState(1);

	const [selectedFile, setSelectedFile] = useState(null);
	const [isFilterModalShown, setIsFilterModalShown] = useState(false);
	const [isOptionModalShown, setIsOptionModalShown] = useState(false);
	const [isFilePreviewShown, setIsFilePreviewShown] = useState(false);
	const [isImportModalShown, setIsImportModalShown] = useState(false);
	const [isExportModalShown, setIsExportModalShown] = useState(false);
	const [isFormModalShown, setIsFormModalShown] = useState(false);
	const [isSearchShown, setIsSearchShown] = useState(false);
	const [selectedCell, setSelectedCell] = useState(null);
	const [formValue, setFormValue] = useState(null);
	const shiftPressedRef = useRef(false);
	const tableRef = useRef(null);
	
	const qDataTypes = useQuery(`{
		appDatatypes {
			name
			structure
			app {
				name
				version
			}
		}
	}`);

	useEffect(() => {

		const { onKeyDown, onKeyUp } = TableListeners({ _rows, _columns, 
			trackKeybindings, shiftPressedRef, selectedCell, setSelectedCell });

		document.addEventListener("keydown", onKeyDown);
		document.addEventListener("keyup", onKeyUp);

		return () => {
			document.removeEventListener("keydown", onKeyDown);
			document.removeEventListener("keyup", onKeyUp);
		}

	}, [selectedCell, trackKeybindings]);

	useEffect(() => {
		if (selectedFile)
			setIsFilePreviewShown(true);
	}, [selectedFile]);

	useEffect(() => {
		if (values) setRows(values);
	}, [values]);

	useEffect(() => {
		if(!rawValues) return setColumns(structure);
		let { newStructure, newRawValues } = parseDatatypes(structure, rawValues, appDatatypes);
		setColumns(newStructure);
		setRows(newRawValues);
	}, [structure, appDatatypes, rawValues]);

	useEffect(() => {
		if (totalPages) setTotalPages(totalPages);
	}, [totalPages]);

	useEffect(() => {
		if (pageNum) setPageNum(pageNum);
	}, [pageNum]);

	useEffect(() => {
		if (pageSize) setPageSize(pageSize);
	}, [pageSize]);

	useEffect(() => {
		if (query) setQuery(query);
	}, [query]);

	useEffect(() => {
		if (search) setSearch(search);
	}, [search]);

	useEffect(() => {
		if (filters) setFilters(filters);
	}, [filters]);

	useEffect(() => {
		if (!trackKeybindings) setSelectedCell(null);
	}, [trackKeybindings]);

	useEffect(() => {
		if (_pageNum > _totalPages && _totalPages > 0)
			_onPageChange(_totalPages);
	}, [_totalPages]);

	useEffect(() => {
		if(!useCustomPagination) {
			let filteredData = filterData({ data: _rows, search: _search, query: _query, pageNum: _pageNum, 
				pageSize: _pageSize, useSearch: !useCustomSearch, useQuery: !useCustomFilters });
			setTotalPages(filteredData.length > _pageSize ? Math.ceil(filteredData.length / _pageSize) : 1);
		}
	}, [pageNum, totalPages, _rows, _query, _search]);

	const _onPageChange = (page) => {
		if(page < 1) page = 1;
		if(page > _totalPages) page = _totalPages;
		setPageNum(page);
		onPageChange(page);
	}

	const { _onAddColumn, _onChangeColumn, _onChangeColumnOptions, _onDeleteColumn } = ColumnsEvents({
		_columns, _rows, isReadOnly, setColumns, setRows, setSelectedCell, onAddColumn, onChangeColumn, 
		onChangeColumnOptions, onDeleteColumn });

	const { _onAddRow, _onDeleteRow, _onInsertRow, _onChangeRow, _onUpdateRow } = RowsEvents({
		_columns, _rows, _pageNum, _pageSize, _totalPages, selectedCell, isReadOnly, setRows, setSelectedCell, onAddRow,
		onChangeRow, onDeleteRow, onInsertRow, _onPageChange });

	const { _onImport, _onExport, _onChangeSearch, _onChangeQuery, _onChangeFilters, _onClickCell, _onSubmitForm } = TableEvents({
		_rows, _columns, isReadOnly, useCustomImport, useCustomExport, formValue, setIsFormModalShown,
		setRows, setSearch, setQuery, setFilters, setSelectedCell, onImport, onExport, onChangeSearch, onChangeQuery, 
		onChangeFilters, onClickCell, _onAddRow, _onUpdateRow });

	const { appDatatypes } = qDataTypes.data || {};

	const filteredData = filterData({ data: _rows, search: _search, query: _query, pageNum: _pageNum, pageSize: _pageSize, 
		useSearch: !useCustomSearch, useQuery: !useCustomFilters, usePagination: !useCustomPagination });

	const filteredColumns = getFilteredColumns(_filters);

	console.log(selectedCell)

	return <View
		height={height}
		columns={_columns}
		rows={filteredData}
		filteredData={filteredData}
		filteredColumns={filteredColumns}
		collections={collections}
		collectionColumns={collectionColumns}
		isReadOnly={isReadOnly}
		isLoading={loading}
		isFilePreviewShown={isFilePreviewShown}
		isFilterModalShown={isFilterModalShown}
		isOptionModalShown={isOptionModalShown}
		isImportModalShown={isImportModalShown}
		isExportModalShown={isExportModalShown}
		isFormModalShown={isFormModalShown}
		isSearchShown={isSearchShown}
		showFilters={showFilters}
		showOptions={showOptions}
		showImport={showImport}
		showExport={showExport}
		showSearch={showSearch}
		showForm={showForm}
		tableRef={tableRef}
		pageSize={_pageSize}
		pageNum={_pageNum}
		totalPages={_totalPages}
		query={_query}
		filters={_filters}
		search={_search}
		formValue={formValue}
		selectedCell={selectedCell}
		selectedFile={selectedFile}
		setSelectedCell={setSelectedCell}
		setSelectedFile={setSelectedFile}
		setFormValue={setFormValue}
		setIsFilePreviewShown={setIsFilePreviewShown}
		setIsFilterModalShown={setIsFilterModalShown}
		setIsOptionModalShown={setIsOptionModalShown}
		setIsImportModalShown={setIsImportModalShown}
		setIsExportModalShown={setIsExportModalShown}
		setIsFormModalShown={setIsFormModalShown}
		setIsSearchShown={setIsSearchShown}
		onAddColumn={_onAddColumn}
		onChangeColumn={_onChangeColumn}
		onChangeColumnOptions={_onChangeColumnOptions}
		onDeleteColumn={_onDeleteColumn}
		onAddRow={_onAddRow}
		onDeleteRow={_onDeleteRow}
		onInsertRow={_onInsertRow}
		onChangeRow={_onChangeRow}
		onImport={_onImport}
		onExport={_onExport}
		onDownloadZip={onDownloadZip}
		onPageChange={_onPageChange}
		onChangeSearch={_onChangeSearch}
		onChangeQuery={_onChangeQuery}
		onChangeFilters={_onChangeFilters}
		onClickCell={_onClickCell}
		onSubmitForm={_onSubmitForm}
		folioComponent={folioComponent}
	/>

}

export default Table;