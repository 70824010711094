import React from "react";

const AppListView = ({
  apps,
  onEnableApp,
  onDisableApp,
  onRequestAccess,
}) => (
  <ul className="list-group">
    {apps.map((app, index) => {
      const uiSettings = app.uiSettings;
      return (
        <div key={app.id} className="app-element">

          {(index == 0 || apps[index].uiSettings?.group_label != apps[index - 1].uiSettings?.group_label) ?
            <div className={"apps-group px-3 py-2 w-100 text-left mb-1 " + (index > 0 ? "mt-3" : "mt-0")}>
              {apps[index].uiSettings?.group_label}
            </div> : null
          }

          <li className="app-item list-group-item d-flex justify-content-between align-items-center pr-3 py-3">
            <div className="app-icon image-placeholder mr-3 pb-2">
              <i className={uiSettings?.icon ?? "fas fa-layer-group"}></i>
            </div>
            <div className="app-title flex-grow-1">
              <div className="app-label mb-0 h5">{uiSettings?.label}</div>
              <small className="app-group text-muted">{apps[index].uiSettings?.group_label}</small>
            </div>
            <div className="app-actions">
              {app.requestOnly && !app.requested ? (
                <button
                  className="btn btn-outline-primary btn-sm px-0 app-request-access"
                  onClick={() => onRequestAccess(app.id)}
                >
                  Solicitar acceso
                </button>
              ) : !app.requestOnly && !app.enabled ? (
                <button
                  className="btn btn-outline-primary btn-sm px-0 app-install"
                  onClick={() => onEnableApp(
                    app.id,
                    app.userAppSetting && app.userAppSetting.id ? app.userAppSetting.id : 0
                  )}
                >
                  Instalar
                </button>
              ) : app.enabled ? (
                <div class="btn-group dropdown ml-1 app-uninstall">
                  <button class="dropdown-toggle-no-caret" id="dropdownMenuButton" data-toggle="dropdown"
                    aria-expanded="false">
                    <i class="fas fa-ellipsis-v"></i>
                  </button>
                  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <div class="dropdown-item" onClick={() => onDisableApp(app.userAppSetting.id)}>
                      <i class="far fa-minus-square mr-2" /> Desinstalar
                    </div>
                  </div>
                </div>
              ) : app.requested ? (
                <button className="btn btn-outline-secondary btn-sm px-0 app-requested" disabled>
                  Solicitado
                </button>
              ) : (
                null
              )}
            </div>
          </li>
        </div>
      );
    })}
  </ul>
);

export default AppListView;
