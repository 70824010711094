import React from "react";


const Option = ({ item, selectedItem, onSelectItem, children }) => (
  <li
    key={item.id}
    className={`list-group-item hover ${item.id == selectedItem ? "selected" : ""}`}
    onClick={() => onSelectItem(item.id)}
    style={{ cursor: "pointer" }}
  >
    {children}
  </li>
)

const VariableItem = ({ item, selectedItem, onSelectItem }) => (
  <Option item={item} selectedItem={selectedItem} onSelectItem={onSelectItem}>
    <i class="fas fa-project-diagram list-group-icon"></i> {item.label}
  </Option>
)

const CollectionItem = ({ item, selectedItem, onSelectItem }) => (
  <Option item={item} selectedItem={selectedItem} onSelectItem={onSelectItem}>
    <i class="fas fa-table list-group-icon"></i> {item.label}
  </Option>
)

const AttributeItem = ({ item, selectedItem, onSelectItem }) => (
  <Option item={item} selectedItem={selectedItem} onSelectItem={onSelectItem}>
    {item.label}
  </Option>
)

const OptionListView = ({ items, selectedItem, isVariable, onSelectItem }) => (
  <div className="items">
    <ul className="list-group">
      {items.length ? (
        <>
          {items.filter((item) => item.id.startsWith("#")).length > 0 && (<li className="list-group-item subtitle">Acciones</li>)}
          {items.filter((item) => item.id.startsWith("#")).map((item, index) => (
            <VariableItem key={item.id} item={item} selectedItem={selectedItem} onSelectItem={onSelectItem} />
          ))}

          {items.filter((item) => item.id.startsWith("@")).length > 0 && (<li className="list-group-item subtitle">Tablas</li>)}
          {items.filter((item) => item.id.startsWith("@")).map((item, index) => (
            <CollectionItem key={item.id} item={item} selectedItem={selectedItem} onSelectItem={onSelectItem} />
          ))}

          {items.filter((item) => !item.id.startsWith("#") && !item.id.startsWith("@")).map((item, index) => (
            <AttributeItem key={item.id} item={item} selectedItem={selectedItem} onSelectItem={onSelectItem} />
          ))}
        </>
      ) : <li className="list-group-item subtitle">Sin resultados</li>}
    </ul>
  </div >
)
export default OptionListView;