import React from "react";
import { DEFINITION_TYPES } from "components/misc/apps/rpa/generator/FlowQuestions";
import RegionPicker from "components/misc/apps/rpa/generator/RegionPicker";
import ClickPoint from "components/misc/apps/rpa/generator/events/ClickPoint";
import DragPoint from "components/misc/apps/rpa/generator/events/DragPoint";
import EventDescription from "components/misc/apps/rpa/generator/events/EventDescription";
import GeneratorForm from "components/misc/apps/rpa/generator/GeneratorForm";
import Definition from "components/misc/apps/rpa/generator/Definition";
import { Formik, Form } from "formik";
import "styles/css/misc/Generator.css";

const GeneratorView = ({

  formikRef,
  flowCaptures,
  flowResult,
  saved,
  showLastMessage,
  autoCompleteApps,

  currentFormCompleted,
  currentDefinition,
  currentHiddenRegions,
  currentParameters,
  currentIndex,
  currentKey,
  currentImage,
  currentImageHeight,
  currentImageWidth,
  currentRuleAdded,
  currentRuleIndex,
  nextImage,

  initialRegions,
  showRegionPicker,
  regionPickerMode,
  onRegionSubmit,

  response,
  responseStatus,
  loading,

  handleFinish,
  handleSkip,
  handleRule,
  handleNext,
  handleEdit,
  handleBackDepth,
  handleAddDepth,
  handleCloseRegionPicker,
  callParamGenerator,

  setCurrentFormCompleted,
  setCurrentDefinition,
  setCurrentIndex,
  setCurrentHiddenRegions,
  setInitialRegions,
  setShowRegionPicker,
  setRegionPickerMode,
  setCurrentRuleIndex,
  setOnRegionSubmit,

}) => (
  <div className="card card-body d-flex flex-column px-6 pb-2 container-fluid pt-1" style={{ overflowY: "auto", height: "100vh" }}>

    <Formik
      initialValues={{}}
      innerRef={formikRef}
      onSubmit={handleNext}>
      {({ values, setFieldValue }) => (
        <Form>

          <div className="d-flex align-items-start justify-content-between w-100">
            <div className="d-flex align-items-start ">
              {/*<div className="mr-5">
                <div className="d-flex justify-content-center align-items-center">
                  <div
                    className="d-flex justify-content-center align-items-center rounded-circle bg-primary text-white"
                    style={{ width: "40px", height: "40px", fontSize: "17px" }}
                  >
                    {currentIndex + 1}
                  </div>
                  <span className="display-3 text-primary mx-1"> / </span>
                  <span style={{ fontSize: "14px" }}> {flowIds.length} </span>
                </div>
              </div>*/}
              <div className="mt-2">
                <h2>Ajustar rutina</h2>
                {/* <p>Responde algunas preguntas para verificar los pasos del proceso</p> */}
              </div>
            </div>
            <div className="d-flex ml-6">
              <div className="mt-1">
                {
                  saved && !showLastMessage ? (
                    <button
                      type="button"
                      className="btn btn-success px-5"
                      onClick={handleFinish}
                    >
                      ▶&nbsp; Guardar rutina
                    </button>
                  ) : null
                }
              </div>
            </div>
          </div>

          <div className="row">

            <div className="col-md-8 mt-3">

              <div className="d-flex mb-5 mt-1">
                {
                  currentImage ? (
                    <div 
                      className="d-flex flex-column align-items-center" 
                      style={{ 
                        position: "relative",
                        // height: currentImageHeight + 'px',
                        // width: currentImageWidth + 'px'
                      }}
                    >

                      <div className="w-100">
                        <img
                          className="img-fluid"
                          src={currentImage}
                          alt="Captura"
                          style={{
                            position: "absolute",
                            // height: currentImageHeight + 'px',
                            // display: "block"
                          }}
                        />
                        {
                          nextImage != "" ?
                            <div>
                              <img 
                                src={nextImage} alt="Siguiente Captura"
                                className={"img-fluid screen-next" + (currentIndex % 2 == 1 ? "-i" : "-j")}
                                style={{
                                  // height: currentImageHeight + 'px',
                                  // marginTop: '-' + currentImageHeight + 'px',
                                }}
                              />
                              <div className={"screen-process" + (currentIndex % 2 == 1 ? "-i" : "-j")}></div>
                            </div>
                            : null
                        }
                      </div>

                      <div
                        class={"visor-current-img-" + currentIndex}
                        style={{
                          width: currentImageWidth + 'px',
                          height: currentImageHeight + 'px',
                          marginTop: '-' + currentImageHeight + 'px',
                        }}
                      >

                        <ClickPoint
                          currentIndex={currentIndex}
                          capture={flowCaptures[currentIndex]}
                          image={currentImage} />

                        <DragPoint
                          currentIndex={currentIndex}
                          capture={flowCaptures[currentIndex]}
                          image={currentImage} />

                      </div>

                      <EventDescription capture={flowCaptures[currentIndex]} />

                      {
                        currentHiddenRegions.absolute.map((region, index) => (
                          <div
                            key={`${region.y}-${region.x}-${region.width}-${region.height}`}
                            onClick={() => setCurrentHiddenRegions(currentHiddenRegions.filter((_, i) => i != index))}
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              top: region.y + "%",
                              left: region.x + "%",
                              width: region.width + "%",
                              height: region.height + "%",
                              border: "4px dashed #000",
                              backgroundColor: "rgba(0, 0, 0, 1)"
                            }}
                          >
                          </div>
                        ))
                      }

                    </div>
                  ) : null
                }
              </div>

              {/* <EventImage 
                currentImage={currentImage}
                currentImageWidth={currentImageWidth}
                currentImageHeight={currentImageHeight}
                nextImage={nextImage}
                currentIndex={currentIndex}
                flowCaptures={flowCaptures}
                currentHiddenRegions={currentHiddenRegions}
                setCurrentHiddenRegions={setCurrentHiddenRegions}
              /> */}

              {
                !showLastMessage
                  ?
                  <div className="w-100">

                    <div className="d-flex justify-content-between mb-3">
                      <b className="text-primary mb-1 d-block mr-4" style={{ fontSize: "18px" }}>
                        PASO {currentIndex + 1}
                        {/* DEBUG */
                          <small className="ml-2" style={{ color: "#bbb" }}>
                            {
                              flowResult[currentIndex]?.skip 
                                ? <i>Omitir</i> 
                                : DEFINITION_TYPES[flowResult[currentIndex]?.definition]
                            }
                            <span style={{ color: "#fff" }}>{flowCaptures[currentIndex]?.event}</span>
                          </small>
                        }
                      </b>
                      <div>
                        <button
                          type="button"
                          className={"btn btn-sm py-1 btn-grey mr-2 " +
                            (flowResult[currentIndex]?.skip ? "selected" : "")}
                          onClick={handleSkip}
                        >
                          <small class="fas fa-chevron-right ml-1 mr-2" style={{ fontSize: "0.75rem" }}></small>
                          {flowResult[currentIndex]?.skip ? "Omitido" : "Omitir paso"}
                        </button>
                        <button
                          type="button"
                          className="btn btn-sm py-1 btn-grey"
                          onClick={handleRule}
                        >
                          <small class="fas fa-project-diagram ml-1 mr-2" style={{ fontSize: "0.75rem" }}></small>
                          {
                            currentRuleAdded ? "Dejar de repetir" : "Repetir"
                          }
                        </button>
                      </div>
                    </div>

                    <GeneratorForm
                      handleShowPicker={(name, show = true) => {
                        setOnRegionSubmit(() => (regions) => setFieldValue(name, regions));
                        setRegionPickerMode("REGION");
                        setInitialRegions(values[name] ?? []);
                        setShowRegionPicker(show);
                        setTimeout(() => {
                          const ele = document.querySelector("#generator-region-picker-ref")
                          if(ele != null) ele.scrollIntoView({ behavior: 'smooth', block: 'end' });
                        }, 350);
                      }}
                      values={values}
                      flowCaptures={flowCaptures}
                      currentIndex={currentIndex}
                      currentRuleAdded={currentRuleAdded}
                      currentRuleIndex={currentRuleIndex}
                      autoCompleteApps={autoCompleteApps}
                      showRegionPicker={showRegionPicker}
                      setFormCompleted={setCurrentFormCompleted}
                      setCurrentDefinition={setCurrentDefinition}
                      setCurrentRuleIndex={setCurrentRuleIndex}
                      setFieldValue={setFieldValue}
                    />

                    {
                      <div
                        className={"region-picker " + (showRegionPicker ? "active" : "")}
                        style={{ width: "75%", overflowY: "hidden" }}
                      >
                        <RegionPicker

                          type={currentDefinition}
                          image={currentImage}
                          capture={flowCaptures[currentIndex]}
                          initialRegions={initialRegions}
                          hiddenRegions={currentHiddenRegions}

                          currentParameters={currentParameters}
                          regionPickerMode={regionPickerMode}

                          response={response}
                          responseStatus={responseStatus}
                          loading={loading}

                          onClose={handleCloseRegionPicker}
                          onSubmit={onRegionSubmit}
                          callParamGenerator={callParamGenerator}

                        />
                        <div class="mt-7" id="generator-region-picker-ref" />
                      </div>
                    }

                  </div>
                  : <div class="text-center animate__animated animate__fadeInUp">
                    <label class="h3 mb-3 mt-3">Rutina completa</label><br />
                    <button
                      type="button"
                      className="btn btn-success btn-sm px-3"
                      onClick={handleFinish}
                    >
                      ▶&nbsp; Guardar rutina
                    </button>
                  </div>
              }

              <div className="my-2 w-100">
                <div className="d-flex justify-content-between w-100">
                  <div></div>
                  <div>
                    {!showLastMessage
                      ? <button
                        type="submit"
                        className="btn btn-primary btn-sm mr-2 px-3"
                      >
                        {flowResult.length == flowCaptures.length ? "Actualizar" : "Siguiente"}
                        {
                          currentFormCompleted
                            ? <>
                              &nbsp;&nbsp;
                              {
                                flowResult.length == flowCaptures.length
                                  ? <i class="fas fa-save"></i>
                                  : ""
                              }
                            </>
                            : null
                        }
                      </button>
                      : null}
                  </div>
                </div>
              </div>

            </div>

            <div 
              className="col-md-4 w-100 mt-3"
              style={{ minHeight: "80vh" }}
            >
              <div className="card w-100 h-100">

                <div className="card-body w-100 overflow-auto h-100 position-absolute">
                  <h4 className="mb-3 ml-1">Pasos</h4>
                    {
                      flowResult.map((flow, index) => (
                        flow.show &&
                          <Definition
                            key={flow.id}
                            flow={flow}
                            index={index}
                            currentIndex={currentIndex}
                            values={values}
                            handleEdit={handleEdit}
                            handleAddDepth={handleAddDepth}
                            handleBackDepth={handleBackDepth}
                            flowResult={flowResult}
                          />
                      ))
                    }
                </div>
              </div>
            </div>

          </div>

        </Form>
      )}
    </Formik>

  </div>
);

export default GeneratorView;