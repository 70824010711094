import React, { useState } from "react";
import moment from "moment";
import 'moment/locale/es';
import { useGet } from "seed/api";
import * as lib from "components/start/usage/Usage.lib.format";
import * as chartOptions from "components/start/usage/Usage.lib.chartOptions";
import { Loading } from "seed/helpers";
import View from "components/start/usage/Usage.view";
moment.locale('es');


function Usage() {

  const userId = sessionStorage.getItem("id");
  const [isModalPlansShown, setIsModalPlansShown] = useState(false);

  const reqHeatmapData = useGet("/executions/get_executions_heatmap", { "user_id": userId });

  if (reqHeatmapData.loading) return <Loading />;
  const response = reqHeatmapData.data?.executions ?? {};
  const executions = response?.data ?? {};
  const headers = response?.header ?? [];

  const formattedExecutions = [];
  Object.keys(executions).forEach((key) => {
    formattedExecutions.push({
      "name": lib.getWeekNameByNumber(lib.getWeekDayNum(key)),
      "weekday": lib.getWeekDayNum(key),
      "data": executions[key].map((execution, idx) => {
        return {
          x: headers.length > idx ? moment(headers[idx], "YYYY-MM-DD").format('D MMMM') : `Sem ${idx}`,
          y: execution
        }
      })
    })
  })
  formattedExecutions.sort((a, b) => b["weekday"] - a["weekday"]);
  const heatMapExecutions = {
    "data": formattedExecutions,
    "options": chartOptions.heatmapChartOptions
  }

  return (
    <View
      userId={userId}
      heatMapExecutions={heatMapExecutions}
      isModalPlansShown={isModalPlansShown}
      setIsModalPlansShown={setIsModalPlansShown}
    />
  );
}

export default Usage;