
import React, { useRef } from "react";
import { Formik, Form } from "formik";
import { Modal } from "seed/helpers";
import * as Fields from "components/schema_form/fields";
import { FieldWrapper, MultipleFieldWrapper } from "components/visual_mode/Settings.sub.wrappers.view";
import Filters from "components/tables/helpers/Filters";

const TableSettingsView = ({
  flow,
  collections,
  currentDefaults,
  initialValues,
  setParentFieldValue,
  getColumnOptions,
  onCreateTable,
  onClose
}) => {

  const tableFormRef = useRef(null);

  const onChangeSelectedTable = (value, index) => {
    
    const table = collections.find(c => `@${c.name}` == value);
    if(!table) return;
    
    const columns = table.schema.structure.map(c => c.name);
    const values = tableFormRef.current.values;
    
    if(!values.tables[index].title)
      tableFormRef.current.setFieldValue(`tables[${index}].title`, table.name);

    tableFormRef.current.setFieldValue(`tables[${index}].columns`, columns);
    tableFormRef.current.setFieldValue(`tables[${index}].query`, "");

  }

  const onSubmit = (values) => {
    setParentFieldValue("tables", values.tables)
    setParentFieldValue("is_manual_tables", values.is_manual_tables)
    onClose()
  }

  return <div className="card p-3" style={{ overflowX: "hidden" }}>

    <h5 className="">Opciones de tabla</h5>

      <Formik
        innerRef={tableFormRef}
        initialValues={{
          is_manual_tables: initialValues.is_manual_tables ?? currentDefaults.is_manual_tables,
          tables: initialValues.tables ?? currentDefaults.tables,
        }}
        onSubmit={onSubmit}
      >
        {({ values, setValues, setFieldValue }) => (
          <Form className="m-0 p-0">

            <MultipleFieldWrapper
              title={"Tablas"}
              isDefault={values.tables == currentDefaults.tables}
              values={values}
              valueKey={"tables"}
              defaultAddValue={{ columns: [], view: "TABLE" }}
              setFieldValue={setFieldValue}
              onChangeDefaults={() => {
                setFieldValue("is_manual_tables", false)
                setFieldValue("tables", currentDefaults.tables)
              }}
            >
              {
                (values.tables??[]).map((table, idx) => (
                  <>

                    <FieldWrapper key={"table_" + idx}>
                      <Fields.VariableSelector
                        required={true}
                        label={"Tabla"}
                        flowId={flow.id}
                        value={table.name}
                        values={values}
                        name={`tables[${idx}].name`}
                        acceptTypes={["collection"]}
                        enableAttributes={false}
                        showCollectionAddButton={true}
                        showCollectionCondition={false}
                        setFieldValue={setFieldValue}
                        onSaveCollectionSuggestion={onCreateTable}
                        onChange={(value) => onChangeSelectedTable(value, idx)}
                      />
                    </FieldWrapper>

                    <button
                      class="btn btn-link p-0 mb-3"
                      type="button"
                      data-toggle="collapse"
                      data-target={`#table_${idx}_collapse`}
                      aria-expanded="false"
                      aria-controls={`table_${idx}_collapse`} 
                    >
                      Opciones avanzadas
                    </button>

                    <div class="collapse" id={`table_${idx}_collapse`}>

                      <FieldWrapper>
                        <div className="d-flex justify-content-between align-items-end">
                          <Fields.TextField
                            required={true}
                            label={"Título"}
                            name={ `tables[${idx}].title` }
                            values={values}
                            setFieldValue={setFieldValue}
                          />
                        </div>
                      </FieldWrapper>
                      <FieldWrapper>
                        <div className="w-100 mt-1 text-dark">
                          Columnas <span className='text-danger ml-1'>*</span>
                        </div>
                        {
                          (table.columns??[]).map((col, colIdx) => (
                            <div
                              key={`table_${idx}_col_${colIdx}`}
                              className="d-flex align-items-center w-100"
                            >
                              <Fields.SelectField
                                required={true}
                                value={col}
                                values={values}
                                name={`tables[${idx}].columns[${colIdx}]`}
                                options={getColumnOptions(table.name, collections)}
                                setFieldValue={setFieldValue}
                              />
                              <button
                                type="button"
                                class="btn btn-link px-1"
                                onClick={() => {
                                  let tempVal = {}
                                  let tempCols = table.columns
                                  tempCols.splice(colIdx, 1)
                                  tempVal[`tables[${idx}].columns`] = tempCols
                                  setValues({ ...values, ...tempVal })
                                }}
                                style={{ color: "#c0c5d5", fontSize: "1.15em" }}
                              >
                                <i class="fas fa-minus-circle"></i>
                              </button>
                            </div>
                          ))
                        }
                        <button
                          type="button"
                          class="btn pl-0 mt-1 pt-1"
                          onClick={() => setFieldValue(`tables[${idx}].columns`, [...(table.columns??[]), null])}
                        >
                          <div className="d-flex align-items-center">
                            <i class="fas fa-plus"></i>&nbsp;Agregar
                          </div>
                        </button>
                      </FieldWrapper>
                      <FieldWrapper>
                        <Fields.SelectField
                          required={true}
                          label={"Visualización"}
                          name={`tables[${idx}].view`}
                          value={table.view}
                          values={values}
                          setFieldValue={setFieldValue}
                          options={[
                            { label: "Vista de tabla", value: "TABLE" },
                            { label: "Vista de tarjetas", value: "CARDS" },
                            { label: "Vista de lista", value: "LIST" }
                          ]}
                        />
                      </FieldWrapper>
                      <FieldWrapper>
                        <button
                          type="button"
                          className="btn btn-outline-primary"
                          onClick={() => {
                            setFieldValue(`tables[${idx}].show_filters`, true)
                          }}
                        >
                          Filtros
                        </button>
                      </FieldWrapper>

                    </div>

                    <hr className="my-3" />

                    {
                      table.show_filters &&
                        <Modal
                          width="800"
                          height={window.innerHeight - 150}
                          onClose={() => setFieldValue(`tables[${idx}].show_filters`, false)}
                          component={() =>
                            <div className="card card-body w-100">
                              <Filters
                                columns={getColumnOptions(table.name, collections)}
                                query={table.query}
                                filters={table.filters}
                                onChangeQuery={(query) => setFieldValue(`tables[${idx}].query`, query)}
                                onChangeFilters={(filters) => setFieldValue(`tables[${idx}].filters`, filters)}
                                onClose={() => setFieldValue(`tables[${idx}].show_filters`, false)}
                              />
                            </div>
                          }
                        />
                    }

                  </>
                ))
              }
            </MultipleFieldWrapper>

            <button
              type="submit"
              class="btn btn-primary btn-block mt-3"
            >
              Guardar
            </button>

            </Form>
        )}
      </Formik>

  </div>

}

export default TableSettingsView;