import React from "react";
import ExecutionDetails from "components/start/executions/ExecutionDetails";


function ExecutionHistoryItem({ title = "", executions = [], placeholder = "" }) {

	return (
		<div className="mb-2">
			<div className="execution-history-group px-3 py-2 w-100 text-left mb-1">
				{title}
			</div>

			{executions.length > 0 ? (
				executions.map((execution, i) => (
					<li className="execution-history-item list-group-item px-3 py-2" key={execution.id}>
						<ExecutionDetails
							idx={i}
							style={{
								border: "none !important",
								padding: "0"
							}}
							execution={execution}
						/>
					</li>
				))
			) : (
				<li className="execution-history-item list-group-item">
					<h5 className="text-center">{placeholder}</h5>
				</li>
			)}

		</div>
	);
}

export default ExecutionHistoryItem;