import React from 'react';
import { Formik, Form, Field } from "formik";

const FlowPrompt = ({ isGeneratingFlow, onSubmit }) => (
  <div className='w-100'>
    <Formik
      initialValues={{}}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue }) => (
        <Form className="m-0 p-0">
          <div className='d-flex'>
            <input
              type="text"
              className="form-control"
              name="description"
              value={values.prompt}
              onChange={(e) => setFieldValue("description", e.target.value)}
              placeholder="Describe tu proceso (Ej. Enviar correo)"
            />
            <button
              type="submit"
              className="btn btn-sm btn-primary px-3"
            >
              {!isGeneratingFlow ? "Crear" : "Creando..." }
            </button>
          </div>
        </Form>
        )}
    </Formik>
  </div>
);

export default FlowPrompt;