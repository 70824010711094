import React, { useState, useEffect, useRef } from 'react';
import { usePost } from "seed/api";
import { useHistory } from 'react-router-dom';
import SchemaForm from 'components/schema_form/SchemaForm';
import CopyClipboardButton from "components/util/helpers/CopyClipboardButton";

const ExecModeForm = ({ 
  execModeSetting,
}) => {

  const historyRouter = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [activeForm, setActiveForm] = useState(null);
  const [status, setStatus] = useState("NONE");
  const submitRef = useRef(null);

  const [callSendForm] = usePost("/apps/forms/send_form", {
    onCompleted: (data) => setStatus("SUCCESS"),
    onError: (error) => setStatus("ERROR"),
  });

  const onFormSubmit = (data) => {
    callSendForm({
      response: data,
      node_id: activeForm.id,
      user_id: sessionStorage.getItem("id"),
    });
    setRefresh(true);
  }

  const previewWebsite = (url) =>
    historyRouter.replace(`/flow/website/${encodeURIComponent(url)}`);

  useEffect(() => {
    if(refresh) setRefresh(false);
  }, [refresh]);

  useEffect(() => {
    setActiveForm(null);
  }, [execModeSetting]);

  useEffect(() => {
    if(!activeForm  && (execModeSetting.forms??[]).length > 0)
      setActiveForm(execModeSetting.forms[0]);
  }, [activeForm, execModeSetting]);

  if(refresh) 
    return <></>;

  return <div 
    class="card border-0 h-100 py-4 px-3"
    style={{
    }}
  >

    {
      !activeForm &&
        <div className="d-flex justify-content-center align-items-center h-100 pb-5">
          <div className="h4 ml-1 d-flex flex-column justify-content-center align-items-center mb-5">
            <h3 className="text-muted text-center" style={{ width: "25rem", lineHeight: "1.7" }}>
              No hay formularios configurados
            </h3>
          </div>
        </div>
    }

    {
      status == "SUCCESS" &&
        <div className='d-flex justify-content-center align-items-center h-100 mb-5'>
          <Message 
            icon={"fas fa-check-circle"} 
            iconColor={"#00C9A7"} 
            title={"Formulario enviado exitosamente"} 
            onClick={() => setStatus("NONE")} 
          />
        </div>
    }

    {
      status == "ERROR" &&
        <div className='d-flex justify-content-center align-items-center h-100 mb-5'>
          <Message 
            icon={"far fa-times-circle"} 
            title={"Ha ocurrido un error en el envío"} 
            subtitle={"Prueba de nuevo"} 
            onClick={() => setStatus("NONE")} 
          />
        </div>
    }

    {
      activeForm && status == "NONE" &&
        <>

          <div 
            class="card-body pt-0 pb-0" 
            style={{ overflowY: "auto" }}
          >

            {
              execModeSetting.forms.length > 1 &&
                <div 
                  className="d-flex w-100 align-items-baseline pb-2 mb-3"
                  style={{ overflowX: "auto" }}
                >
                  {(execModeSetting.forms ?? []).map((form, index) => (
                    <a
                      key={index}
                      style={{
                        maxWidth: "16rem",
                      }}
                      className={"btn exec-mode-tab d-flex align-items-center mr-2 px-3 py-0 " +
                        (activeForm.id == form.id ? "active" : "")}
                    >
                      <span 
                        className="text-truncate py-2" 
                        onClick={() => setActiveForm(form)}
                      >
                        {form.title ?? "Formulario " + (index + 1)}
                      </span>
                    </a>
                  ))}
                </div>
            }

            <h3 class="mb-5 mt-1">
              {activeForm.title ?? "Completar Formulario"}
              <small>
                {
                  activeForm.url && 
                    <div class="text-muted mt-1">
                      Liga para compartir:&nbsp;
                      <a className='text-primary' style={{ cursor: "pointer" }} onClick={() => previewWebsite(activeForm.url)}>
                        {activeForm.url}
                      </a>
                      <CopyClipboardButton link={decodeURIComponent(activeForm.url)} />
                    </div>
                }
              </small>
            </h3>
            <SchemaForm 
              onSubmit={onFormSubmit}
              schema={activeForm.schema}
              appId={activeForm.app_id}
              actionId={activeForm.action_id}
              flowId={activeForm.flow_id}
              nodeId={activeForm.id}
              submitComponent={() => 
                <button
                  ref={submitRef}
                  type="submit"
                  style={{ display: "none" }}
                />
              }
              submitText="Enviar" 
            />
          </div>

          <div class="card-footer">
            <button 
              class="btn btn-block btn-success"
              onClick={() => {
                submitRef.current.click();
              }}
            >
              Enviar
            </button>
          </div>

        </>
    }
  </div>;

}

const Message = ({ icon, iconColor = "#607080", title, subtitle, onClick }) =>
  <div class="text-center animate__animated animate__fadeInUp animate__faster">
    {icon ? <i className={icon + " mb-5"} style={{ fontSize: "4em", color: iconColor }}></i> : null}
    {title ? <h2>{title}</h2> : null}
    {subtitle ? <p className='pb-0'>{subtitle}</p> : null}
    <button 
      type='button'
      onClick={onClick}
      className='btn btn-lighter'
    >
      Enviar de nuevo
    </button>
  </div>

export default ExecModeForm;