import React from "react";
import { Link } from "react-router-dom";
import { getFlowName } from "components/util/flow";
import { Analytics } from "components/util/analytics";

const UseCaseItem = ({ idx, flow, category }) => (
  <div className="col-11 col-md-6 col-lg-3 px-0">
    <div
      class={`flow card animate__animated animate__fadeInUp animate__delay-1s h-100`}
      style={{ "--animate-delay": (idx * 0.22) + "s" }}
    >

      <div class="card-body text-center mt-5 mb-2">

        <div className="avatar avatar-xl avatar-border-lg mb-2">
          <img src="/resources/images/icon-45.svg" alt="Flow" />
        </div>

        <h3 className={"flow-name mt-1 mb-0"}>
          <div className="text-dark h4 text-truncate w-100 d-inline-block mb-0">
            {getFlowName(category, flow)}
          </div>
        </h3>

        <div class="flow-card w-100 d-inline-block">
          {function () {
            const templateCategories = flow?.templateCategories ?? [];
            if (templateCategories.length > 0) {
              const templateCategory = templateCategories[0];
              return (
                <div key={templateCategory.id} className="flow-apps text-dark text-truncate w-100 d-inline-block">
                  <i className={`${templateCategory?.uiSettings?.display?.icon} mr-1`}></i>
                  <span>{templateCategory?.uiSettings?.display?.title}</span>
                </div>
              );
            }
            return <></>;
          }()}
        </div>

      </div>

      <div class="card-footer">
        <div class="py-1 w-100 px-3">
          <Link to={`/use_case/${flow.templateName}?title=${getFlowName(category, flow)}`}
            onClick={() => Analytics.CreateEvent("use_case_selected", { flow: flow.id, title: getFlowName(category, flow) })}
            class="btn btn-primary w-100">
            <i class="fas fa-play mr-2"></i>
            Probar ejemplo
          </Link>
        </div>
      </div>

    </div>
  </div>
);

export default UseCaseItem;