import React from 'react';
import Table from "components/tables/Table";

const ExecModeDataTable = ({ 
  collections,
  values,
  structure,
  pageNum, 
  pageSize,
  totalPages,
  query,
  filters,
  search,
  onPageChange,
  onExport,
  onChangeSearch,
  onChangeFilters,
  onChangeQuery,
}) => {
  return <Table
    collectionColumns={collections}
    isReadOnly={true}
    useCustomPagination={true}
    useCustomExport={true}
    useCustomFilters={true}
    useCustomSearch={true}
    showExport={true}
    showImport={false}
    showOptions={false}
    showSearch={true}
    showFilters={true}
    pageNum={pageNum}
    pageSize={pageSize}
    totalPages={totalPages}
    values={values}
    structure={structure}
    search={search}
    query={query}
    filters={filters}
    onPageChange={onPageChange}
    onExport={onExport}
    onChangeSearch={onChangeSearch}
    onChangeFilters={onChangeFilters}
    onChangeQuery={onChangeQuery}
    folioComponent={null}
  />
}

export default ExecModeDataTable;