import React from "react";
import { Modal } from "seed/helpers";
import Plans from "components/misc/plans/Plans";
import HeatmapChart from "components/misc/charts/HeatmapChart";
import ExecutionHistory from "components/start/executions/ExecutionHistory";


const UsageView = ({
  userId,
  heatMapExecutions,
  isModalPlansShown,
  setIsModalPlansShown
}) => (
  <>
    <div id="heatmap-usage" className="card mt-3">
      <div id="heatmap-usage-container" class="card-body">

        <small id="heatmap-usage-title" class="nav-subtitle mb-2" title="Pages">
          <i class="fas fa-chart-bar mr-2"></i>
          Actividad
        </small>

        <div className="mt-n4">
          <HeatmapChart data={heatMapExecutions.data} options={heatMapExecutions.options} height={250} />
        </div>
      </div>
    </div>

    <div id="recent-executions" className="card mt-3">
      <div id="recent-executions-container" class="card-body">

        <small id="recent-executions-title" class="nav-subtitle" title="Pages">
          <i class="fas fa-chart-line mr-2"></i>
          Actividad reciente
        </small>

        <div className="my-3">
          <ExecutionHistory
            user_id={userId}
            status={""}
            placeholder={"No hay actividad reciente"} />
        </div>

      </div>
    </div>

    {isModalPlansShown && (
      <Modal
        width={1000}
        height={600}
        animation={""}
        onClose={() => setIsModalPlansShown(false)}
        component={Plans}
      />
    )}

  </>
);

export default UsageView;