
import React from 'react';
import { getField } from 'components/visual_mode/panels/Data.lib.parser';

const ExecModeDataList = ({ 
  collections,
  values, 
  structure, 
}) => {
  return <div className='table-responsive mt-3 px-3'>
    <table className='table table-centered table-nowrap table-hover mb-0'>
      <thead className='thead-light'>
        <tr>
          {
            structure.map((column, idx) =>
              <th key={idx}>{column.label}</th>
            )
          }
        </tr>
      </thead>
      <tbody>
        {
          values.map((row, idx) =>
            <tr key={idx}>
              {
                structure.map((column, idx) =>
                  <td key={idx} style={{ maxWidth: "230px" }}>
                    {
                      function(){
                        const value = row.data[column.name];
                        if(!value) return "-";
                        return getField(column, value, collections);
                      }()
                    }
                  </td>
                )
              }
            </tr>
          )
        }
      </tbody>
    </table>
  </div>
}

export default ExecModeDataList;