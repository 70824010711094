/* eslint-disable max-len */
import React from "react";
import { bodyContentMapper } from "components/flow/board/nodes/Node.lib";

function Content({ nodeId, isControl = false, isIterable = false, app, action, nodeInput, isSelected, onSelectNode }) {

	const nodeValues = nodeInput;
	const displayOptions = action.uiSettings?.node ?? [];
	const formSettings = action.uiSettings.form;

	const title = bodyContentMapper(displayOptions, nodeValues, "title", formSettings);
	const subtitle = bodyContentMapper(displayOptions, nodeValues, "subtitle", formSettings);
	const offsetIconLeft = action.name == "conditional" ? "6px" : "6px";
	let icon = app.uiSettings?.icon;
	if (action.name == "conditional") icon = "fas fa-diagram-project";
	else if (action.name == "iterate") icon = "fa-solid fa-arrows-rotate";
	else if (action.name == "dummy_node") icon = nodeValues.find(val => val.name == "icon")?.value ?? "fas fa-square";


	const appIconStyle = {
		position: "absolute",
		top: "-19px",
		right: "calc(100% - 25px)",
		width: "2.8rem",
		height: "2.8rem",
		zIndex: "100"
	}

	if (action.type == "TRIGGER") {
		appIconStyle.top = "-21px";
		appIconStyle.right = "calc(100% - 26px)"
	}

	if (isControl) {
		appIconStyle.top = "-40px";
		appIconStyle.right = "calc(100% - 25px)"
	}

	return (
		<div>

			{/* Node title */}
			{!isControl ? <h6 className="px-2 mb-0 text-center node-title">
				<div dangerouslySetInnerHTML={{ __html: title }} />
			</h6> : null}

			{/* Node subtitle */}
			<p className="px-2 mb-0 node-subtitle">
				{!isControl && subtitle != "" && (
					<label>
						<div dangerouslySetInnerHTML={{ __html: subtitle }} />
					</label>
				)}

				{/* Main icon for control nodes */}
				{isControl && <i className={`${icon} text-dark`}></i>}
			</p>

			{/* Node subtitle and icon, only apply for control nodes */}
			{isControl && subtitle != "" && (
				<div className="control-node-subtitle" style={{ left: `calc(100% - ${offsetIconLeft})` }}>
					<div className="control-node-subtitle-message badge badge-pill badge-light">
						<div dangerouslySetInnerHTML={{ __html: subtitle }} />
					</div>
				</div>
			)}

			{/* Node icon, the icon in the left upper corner */}
			<div className={"node-icon-app badge-container " + (isSelected ? "selected " : "")}
				style={appIconStyle}
				onClick={e => e.stopPropagation()}
			>

				{/* Checkbox used to select the node when mouse is hover */}
				<div class="badge badge-pill badge-checkbox select-node-checkbox" onClick={onSelectNode}>
					<div class="form-group">
						<div class="custom-control custom-checkbox">
							<input type="checkbox" id={`checkSelectNode${nodeId}`} class="custom-control-input"
								onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
							/>
							<label class="custom-control-label" for={`checkSelectNode${nodeId}`}></label>
						</div>
					</div>
				</div>

				{/* Icon on the left upper corner */}
				{!isControl && (
					<span class="node-icon badge badge-pill badge-light text-center px-0 pb-0 badge-icon">
						<i className={icon}></i>
					</span>
				)}

			</div>

			{/* Legend that indicates the node is iterable */}
			{isIterable && (
				<div className="iterable-legend" style={{ bottom: action.type != "TRIGGER" ? "-12px" : "-15px", right: action.type != "TRIGGER" ? "calc(100% - 8px)" : "calc(100% - 10px)" }}>
					<span class="badge badge-pill badge-light text-center">
						Iterable
					</span>
				</div>
			)}
		</div >
	);

}

export default Content;
