import React from "react";
import { NodeResizeControl } from 'reactflow';
import "styles/css/flow/actions/Pipe.css"

const PipeView = ({ title, orientation, isEditable, onEditAnnotation }) => (
  <>

    {!isEditable && (
      <div className="no-editable-overlay drag-handle-container">
      </div>
    )}

    <div className={`pipe ${orientation} w-100 h-100`} onMouseDown={() => false} onMouseMove={() => false} onClick={() => false}>

      <div className="pipe-title drag-handle-container" onClick={onEditAnnotation}>
        <div className="pipe-wrapper">
          {title}
        </div>
      </div>

      <div className="pipe-border pipe-border-a drag-handle-container">
      </div>

      <div className="pipe-border pipe-border-b drag-handle-container">

        <NodeResizeControl minWidth={10} minHeight={10} className="pipe-resizer pipe-resizer-b">
          <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
        </NodeResizeControl>
      </div>

    </div>

  </>
);

export default PipeView;