import React, { useEffect } from "react";
import View from "components/App.view";

function App() {

  useEffect(() => {
    const handleMessage = (event) => {
      const eventType = event.data?.eventType ?? "";
      if (eventType == "chrome_extension") {
        const isInsideExtension = event.data?.isInsideExtension ?? false;
        localStorage.setItem("isInsideExtension", isInsideExtension)
        sessionStorage.setItem("isInsideExtension", isInsideExtension)
      }
    };

    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // TODO: Hack -> Remove the resizeObserver error
	useEffect(() => {

		const errorHandler = (e) => {
			if(e.message.includes(
				"ResizeObserver loop completed with undelivered notifications" ||
				"ResizeObserver loop limit exceeded"
			)) {
				const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
				if(resizeObserverErr) resizeObserverErr.style.display = "none";
			}
		};

		window.addEventListener("error", errorHandler);
		return () => window.removeEventListener("error", errorHandler);

	}, []);

  return <View />;
}

export default App;