import React from "react";

const execModeTutorialSteps = [
  {
    selector: "#workspace",
    content: () => <label><b>En el modo visual podrás personalizar como se verá tu proceso para utilizarlo y compartirlo fácilmente.</b></label>
  }
];


export { execModeTutorialSteps };