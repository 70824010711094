import React from "react";
import { NodeResizeControl } from 'reactflow';
import "styles/css/flow/actions/Text.css";


const TextView = ({ id, content, isSelected, isEditable, onEditText, onSelectAnnotation }) => (
  <>

    {!isEditable && (
      <div className="no-editable-overlay drag-handle-container">
      </div>
    )}

    <div className="text w-100 h-100">

      <div className="text-content drag-handle-container w-100 h-100" onClick={onEditText}>
        <span>{content}</span>

        <div className={"badge-container " + (isSelected ? "selected " : "")} onClick={e => e.stopPropagation()}>

          <div class="badge badge-pill badge-checkbox" onClick={onSelectAnnotation}>
            <div class="form-group">
              <div class="custom-control custom-checkbox">
                <input type="checkbox" id={`checkSelectAnnotation${id}`} class="custom-control-input"
                  onClick={(e) => { e.stopPropagation(); }} checked={isSelected}
                />
                <label class="custom-control-label" for={`checkSelectAnnotation${id}`}></label>
              </div>
            </div>
          </div>

        </div>
      </div>

      <NodeResizeControl minWidth={10} minHeight={10} className="text-resizer">
        <i class="fa-solid fa-up-right-and-down-left-from-center"></i>
      </NodeResizeControl>
    </div>
  </>
);

export default TextView;