import React from "react";
import { useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/flow/board/annotations/Note.view";

function Note({ id, data }) {

  const nodes = useNodes();
  const historyRouter = useHistory();
  const { setNodes } = useReactFlow();

  const actionId = data.actionId;
  const isSelected = data?.is_selected ?? false;
  const input = data?.input_value ?? [];
  const backgroundColor = input.find(inp => inp.name == "background")?.value ?? "#ffffff";

  let content = "";
  let readOnly = false;
  for (let inp of input) {
    if (inp.name == "content")
      content = inp.value
    if (inp.name == "read_only")
      readOnly = inp.value
  }

  if (readOnly)
    content = content.replace("{internal_id}", id)

  const reqAction = useDetail(`{
    action { 
      name
      type
      isEditable
      inputStructure
      outputStructure
      uiSettings
      app {
        uiSettings
      }
    }
  }`, actionId);

  if (reqAction.loading) return <Loading size={27} />;
  const { action = {} } = reqAction.data;
  const isEditable = action?.isEditable;

  const onEditNote = () => {
    if (!isEditable) return;
    const note = nodes.find((nd) => nd.id == id);
    if (note) {
      const url = `/flow/edit_annotation/${note.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  const onSelectAnnotation = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_selected = !isSelected;
    setNodes(nodes);
    data.onSelectNode()
  }

  return (
    <View
      id={id}
      content={content}
      readOnly={readOnly}
      backgroundColor={backgroundColor}
      isSelected={isSelected}
      isEditable={isEditable}
      onEditNote={onEditNote}
      onDeleteNode={data.onDeleteNode}
      onSelectAnnotation={onSelectAnnotation}
    />
  );
}

export default Note;