import React, { useState, useRef } from "react";
import swal from "sweetalert";
import { usePost, useGet } from "seed/api";
import { useQuery } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/start/templates/Templates.view";


function Templates() {

  const userId = sessionStorage.getItem("id");
  const searchFieldRef = useRef(null);
  const [selectedProfile, setSelectedProfile] = useState(null);
  const [filters, setFilters] = useState({
    template_categories: [],
    template_type: ["COMPLETE", "SAMPLE"]
  })

  const [callCloneTemplate, reqCloneTemplate] = usePost('/flows/clone_template', {
    onCompleted: (data) => {
      const clonedFlowId = data?.cloned_flow_id;
      if (clonedFlowId) {
        window.location.replace(`/app/flow/${clonedFlowId}/flow`);
      }
    },
    onError: (error) => {
      console.log(error);
      swal("Error al usar plantilla", "Ha ocurrido un error inesperado, por favor intente de nuevo mas tarde", "error")
    }
  })

  const reqFlows = useGet('/flows/get_template_flows', {...filters});
  const reqCategories = useQuery(`{
    templateCategories {
      name
      description
      uiSettings
    }
  }`, "");

  const onSearchTemplates = () => {
    if (searchFieldRef.current == null || searchFieldRef.current == undefined)
      return;

    const searchPrompt = searchFieldRef.current?.value ?? ""
    const newFilters = {
      template_categories: "all",
      template_type: ["SAMPLE"],
      search: searchPrompt
    }
    setFilters(newFilters);
    setSelectedProfile(null);
    reqFlows.refetch();
  }

  const onClearSearch = () => {
    const newFilters = {
      template_categories: [],
      template_type: ["COMPLETE", "SAMPLE"],
      search: null
    }
    setFilters(newFilters);
    setSelectedProfile(null);
    reqFlows.refetch();
  }

  const onChangeProfile = (newProfile) => {
    const newFilters = {
      template_categories: [newProfile.id],
      template_type: ["COMPLETE", "SAMPLE"],
      search: null
    }
    setFilters(newFilters)
    setSelectedProfile(newProfile);
    searchFieldRef.current.value = "";
    reqFlows.refetch();
  }

  const onCloneTemplate = (flowId) => {
    callCloneTemplate({
      flow_id: flowId,
      user_id: userId,
      template_category_id: selectedProfile?.id
    })
  }

  const isTemplateCategoriesLoading = reqCategories.loading;
  const isFlowsLoading = reqFlows.loading;
  if (isTemplateCategoriesLoading) return <Loading />;

  const templateCategories = reqCategories.data?.templateCategories ?? [];
  const flows = reqFlows.data;

  return (
    <View
      templateFlows={flows}
      templateCategories={templateCategories}
      selectedProfile={selectedProfile}
      filters={filters}
      isFlowsLoading={isFlowsLoading}
      isTemplateCategoriesLoading={isTemplateCategoriesLoading}
      searchFieldRef={searchFieldRef}
      onChangeProfile={onChangeProfile}
      onCloneTemplate={onCloneTemplate}
      onSearchTemplates={onSearchTemplates}
      onClearSearch={onClearSearch}
    />
  );
}

Templates.propTypes = {};

export default Templates;