import React from "react";
import { useNodes, useReactFlow } from 'reactflow';
import { useHistory } from 'react-router-dom';
import { useDetail } from "seed/gql";
import { Loading } from "seed/helpers";
import View from "components/flow/board/annotations/Text.view";


function Text({ id, data }) {

  const nodes = useNodes();
  const historyRouter = useHistory();
  const { setNodes } = useReactFlow()
  
  const actionId = data.actionId;
  const isSelected = data?.is_selected ?? false;
  const input = data?.input_value ?? [];

  let content = "";
  for (let inp of input) {
    if (inp.name == "content")
      content = inp.value
  }

  const reqAction = useDetail(`{
    action { 
      name
      type
      isEditable
      inputStructure
      outputStructure
      uiSettings
      app {
        uiSettings
      }
    }
  }`, actionId);

  if (reqAction.loading) return <Loading size={27} />;
  const { action = {} } = reqAction.data;
  const isEditable = action?.isEditable;

  const onEditText = () => {
    if (!isEditable) return;
    const text = nodes.find((nd) => nd.id == id);
    if (text) {
      const url = `/flow/edit_annotation/${text.remoteId}`
      historyRouter.replace(url);
    }
    return nodes;
  }

  const onSelectAnnotation = () => {
    let actualNode = nodes.find((nd) => nd.id == id);
    actualNode.data.is_selected = !isSelected;
    setNodes(nodes);
    data.onSelectNode()
  }

  return (
    <View
      id={id}
      content={content}
      isSelected={isSelected}
      isEditable={isEditable}
      onEditText={onEditText}
      onSelectAnnotation={onSelectAnnotation}
    />
  );
}


export default Text;