import React from "react";
import UseCaseItem from "components/misc/use_cases/UseCases.sub.Item.view";
import UseCaseCategory from "components/misc/use_cases/UseCases.sub.Category.view";
import { LANDING_URL } from "settings"
import Categories from "components/misc/templates/Categories";
import Flows from "components/misc/templates/Flows";
import "styles/css/misc/UseCases.css";


const UseCasesView = ({
  flows,
  selectedCategory,
  flowTemplateFilters,
  templateCategories,
  onChangeCategory
}) => (
  <div id="use-cases">
    <div id="use-cases-container" class="container w-100 px-3 pt-3">

      <a class="d-flex justify-content-center" href={LANDING_URL}>
        <img class="z-index-2 workix-logo"
          src="/theme/svg/logos/logo-white.svg" alt="Logo" />
      </a>

      <div className="d-flex flex-column align-items-center justify-content-center mt-4 pb-5 animate__animated animate__fadeInUp w-100">

        <div id="use-cases-title" class="mb-3 text-center">
          <h1 className="text-white mb-1">Ejemplos</h1>
          <span className="text-wrap">
            Conoce algunos casos de uso que Workix tiene para ti
          </span>
        </div>

        {/* Template Categories */}
        <Categories
          Category={(props) => <UseCaseCategory {...props} onChangeCategory={onChangeCategory} />}
          manualCategories={templateCategories}
          initialActiveCategory={selectedCategory ? selectedCategory.id : null}
          style={{ width: (templateCategories.length * 7) + "%", marginBottom: "1.4rem" }}
          arrowsStyle={{
            color: "white",
            border: "2px solid white",
            fontSize: "1em"
          }}
          arrowContainerStyle={{ width: "100%" }}
          scrollAmount={400}
        />

        {/* Template Flows */}

        <Flows
          Flow={(props) => <UseCaseItem {...props} category={selectedCategory} />}
          manualFlows={flows}
          scrollAmount={600}
          filters={flowTemplateFilters}
          style={{ width: "100%" }}
          arrowContainerStyle={{ width: "100%" }}
        />

      </div>

    </div>
  </div>
);

export default UseCasesView;