import React from "react";
import { CommentComponent } from "components/schema_form/fields/commons";


const BaseField = ({ name, label, children, required, helpMessage = "", caption = "", enableComments, values, setFieldValue }) => {
	return (
		<div className="w-100">

			<div class="d-flex mb-1">
				{label != null && label != "" ?
					<label class="input-label w-100" for={name}>
						<div>
							<span>{label}</span>
							{required ? (<span className='text-danger ml-1'>*</span>) : (<span className='ml-1' style={{ color: "#a0a5b0", fontSize: "0.75rem" }}>(Opcional)</span>)}
							{helpMessage != "" ?  <div style={{ fontSize: "0.95em", color: "#959aa0", paddingBottom: "0.17rem", marginTop: "0rem" }}>{helpMessage}</div> : null}
							</div>
						{caption != "" ? <div>
							<span style={{ fontSize: "0.9em", color: "#a0a5aa", paddingBottom: "0.15rem"}}>{caption}</span>
						</div> : null}
						<div>
							{enableComments ? <CommentComponent name={name} values={values} setFieldValue={setFieldValue} /> : null}
						</div>
					</label> : null}
			</div>
			{children}
		</div>
	);
}

export default BaseField;