/* eslint-disable max-len */
import React from "react";
import { Link } from "react-router-dom";
import { ModalRoute } from "seed/helpers";
import Apps from "components/flow/toolbox/apps/Apps";
import { TabButton } from "components/flow/toolbox/Toolbox.sub.ActionElement.view"
import NavApps from "components/flow/toolbox/Toolbox.sub.NavApps.view";
import NavCategories from "components/flow/toolbox/Toolbox.sub.NavCategories.view";
import ScrollArrow from "components/util/helpers/ScrollArrow";
import "styles/css/flow/Toolbox.css";


const ToolboxView = ({
  apps,
  flow,
  user,
  filtersData,
  categoriesData,
  suggestions,
  actionSearch,
  actionFilters,
  isDisabled,
  isAnonymous,
  isDeveloper,
  isSuggestionsLoading,
  isFilterSectionShown,
  reqApps,
  onSearchActions,
  onClickSearchRemove,
  onClickSearchFilter,
  onDragStart,
  onClickAction,
  onClickFilterTab,
  onClickFilterRemove
}) => (

  <div id="toolbox-container" className="d-md-block"
    style={{ height: !isFilterSectionShown ? "calc(100% - 90px)" : "calc(100% - 135px)" }}>

    {isDisabled && <div id="disabling-frame" />}

    <div id="toolbox-main">

      <div className="search-wrapper" id="toolbox-search">
        <input type="text" placeholder="Buscar acciones" class="pl-5 pr-5 rounded w-100 search-input"
          value={actionSearch} onChange={(event) => onSearchActions(event.target.value)}
        />
        <i className="fas fa-search d-block ml-2 search-icon"></i>
        <div className="d-flex flex-row-reverse search-cta">

          <i class="fas fa-sliders search-filters py-0 px-2"
            style={{ color: isFilterSectionShown ? "#50555a" : "#a0aab0" }}
            onClick={onClickSearchFilter}
          ></i>

          {actionSearch != "" ?
            <i class="fas fa-times search-close py-0 px-2"
              onClick={onClickSearchRemove}
            ></i> : null}
        </div>
      </div>

      <div id="toolbox-tabs"
        style={{ display: isFilterSectionShown ? "block" : "none" }}>
        <ScrollArrow
          scrollAmount={150}
          backgroundFadeColor={{ red: 235, green: 241, blue: 246 }}
          leftButtonStyle={{
            paddingLeft: "2px",
            paddingRight: "2px"
          }}
          rightButtonStyle={{
            paddingLeft: "2px",
            paddingRight: "2px"
          }}
          arrowsWidth="auto"
        >
          {filtersData.map(filter =>
            <TabButton
              key={filter.name}
              name={filter.name}
              label={filter.label}
              helpMessage={filter?.helpMessage ?? ""}
              actionFilters={actionFilters}
              onClickFilterTab={onClickFilterTab}
              onClickFilterRemove={onClickFilterRemove}
            />
          )}
        </ScrollArrow>
      </div>

      <div id="toolbox-actions"
        className={"w-100 scrollbar-narrow d-flex px-3 " + (actionSearch != "" || actionFilters.length > 0 ? "flex-column" : "flex-wrap")}
      >

        {actionSearch != "" || actionFilters.length > 0 ?
          <span className={"d-flex justify-content-between dropdown-header pb-2 px-2 search-title " +
            (actionSearch != "" ? "pt-3" : "pt-2")}>
            {actionSearch != "" ? "Búsqueda" : filtersData.find(tab => tab.name == actionFilters[0])?.label}
          </span> : null}

          <NavCategories
            apps={apps}
            categoriesData={categoriesData}
            actionSearch={actionSearch}
            actionFilters={actionFilters}
            isDeveloper={isDeveloper}
            onDragStart={onDragStart}
            onClickAction={onClickAction}
          />

        {/*<NavApps
          apps={apps}
          actionSearch={actionSearch}
          actionFilters={actionFilters}
          isDeveloper={isDeveloper}
          onDragStart={onDragStart}
          onClickAction={onClickAction}
        />*/}

        {
          actionSearch != "" ?
            <div className="text-center mx-2 my-7 no-results">No se encontraron resultados</div> : null
        }
      </div>

      <div className="px-4 my-4">
        <Link to="/flow/apps"
          class="btn btn-sm btn-primary py-1 w-100" data-toggle="collapse">
          Personalizar acciones
        </Link>
      </div>

    </div>

    <ModalRoute
      height="600"
      path={"/flow/apps"}
      component={() =>
        <div className="card">
          <div class="card-header d-flex justify-content-between"><h3 class="mb-0">Acciones</h3></div>
          <Apps reqApps={reqApps} flowId={flow.id} />
        </div>}
    />


  </div>
);

export default ToolboxView;
