import React, { useEffect, useState } from "react";
import { useStoreApi, useReactFlow } from 'reactflow';
import { useQuery } from "seed/gql";
import moment from "moment";
import * as util from "components/flow/board/util/board";
import View from "components/flow/board/Board.view";


function Board({
  nodes,
  edges,
  flowId,
  toolboxRef,
  activePage,
  selectedNodes = [],
  showAnimations,
  isFlowBeingSaved,
  isGeneratingFlow,
  isRPADesigner,
  isDeveloper,
  isAnonymous,
  isAnimationOptionShown,
  isPaginationShown,
  isControlsShown=true,
  reactFlowWrapper,
  setActivePage,
  setReactFlowInstance,
  onDropNode,
  onDragNode,
  onConnectEdge,
  onUpdateEdge,
  onNodesChange,
  onEdgesChange,
  onClickNode,
  onClickAnnotation,
  onDeleteSelectedNodes,
  onClickUndo,
  onClickRedo,
  onChangeAnimations,
  onGenerateFlow,
}) {

  const store = useStoreApi();
  const { setCenter, getZoom } = useReactFlow();
  const [controlActions, setControlActions] = useState([]);
  const [annotations, setAnnotations] = useState([]);
  const [prevLengthNodes, setPrevLengthNodes] = useState(nodes.length);
  const [isPlaceholderShown, setAnimationOptionShown] = useState(true)
  const isDeleteNodesShown = selectedNodes.length > 0;

  const reqPages = useQuery(`{
    flowPages {
      name
      isDefault
    }
  }`, `flow.id = ${flowId}`, {
    onCompleted: (data) => {
      let defaultPageId = 0
      for (let page of data.flowPages)
        if ((page.isDefault && defaultPageId == 0) || (page.id == sessionStorage.getItem("page_" + flowId)))
          defaultPageId = page.id
      if (activePage != defaultPageId) {
        setActivePage(defaultPageId)
        sessionStorage.setItem("page_" + flowId, defaultPageId)
      }
    },
    orderBy: "name"
  })

  const reqConditionalAction = useQuery(`{
    actions {
      name
      inputStructure
      outputStructure
      uiSettings
    }
  }`, `name=conditional`, {
    onCompleted: (data) => {
      const actions = data.actions;
      if (actions.length == 0) return;
      const conditionalAction = actions[0];
      setControlActions((prev) => prev.concat(conditionalAction));
    },
    orderBy: "relevance"
  })

  const reqAnnotations = useQuery(`{
    actions {
      name
      inputStructure
      outputStructure
      uiSettings
    }
  }`, `isAnnotation=true`, {
    onCompleted: (data) => {
      const annotations = [...data.actions];
      const formattedAnnotations = annotations.map(annotation => {
        const newAnnotation = {...annotation}
        if (annotation.name == "node_annotation") newAnnotation.isAnnotation = false;
        else newAnnotation.isAnnotation = true;
        return newAnnotation;
      })
      setAnnotations((prev) => prev.concat(formattedAnnotations));
    },
    orderBy: "relevance"
  })

  useEffect(() => {
    if (nodes.length > prevLengthNodes) {
      onFocusLastNode()
    }
    setPrevLengthNodes(nodes.length)
  }, [nodes])

  

  const onClickChangePage = pageId => {
    setActivePage(pageId);
    sessionStorage.setItem("page_" + flowId, pageId)
  }

  const onDragStart = (event, type, actionId) => {
    const data = {
      "type": type,
      "actionId": actionId
    };
    event.dataTransfer.setData('application/reactflow', JSON.stringify(data));
    event.dataTransfer.effectAllowed = 'move';
  };

  const onClickAction = (actionId, type) => {
    if (type == "annotation") {
      onClickAnnotation(actionId)
    } else if (type == "node") {
      onClickNode(actionId)
    }
  };

  const onFocusLastNode = () => {
    const { nodeInternals } = store.getState();
    const nodes = Array.from(nodeInternals).map(([, node]) => node);

    if (nodes.length > 0) {

      const node = nodes.reduce((maxElem, currentElem) => {
        return moment(currentElem.createdAt) > moment(maxElem.createdAt) ? currentElem : maxElem;
      }, nodes[0])

      const x = node.position.x + 50;
      const y = node.position.y + 25;
      onFocusPoint(x, y);
    }
  }

  const onFocusPoint = (x, y) => {
    const zoom = getZoom();
    setCenter(x, y, { zoom: zoom, duration: 1000 });
  }

  const onClickClosePlaceholder = () =>
    setAnimationOptionShown(false)

  if (reqPages.loading || reqConditionalAction.loading || reqAnnotations.loading) return <div />;
  if (reqPages.error || reqConditionalAction.error || reqAnnotations.error) return "Error";

  const { flowPages = [] } = reqPages.data;

  const initialBoardRect = util.getInitialBoardRect(nodes);
  const boardFitView = initialBoardRect.width < 400;

  const initialBoardZoom = 1.35;
  let initialViewport = { //Only applies when rect.width > 400
    x: -(initialBoardRect.x - 50) * initialBoardZoom,
    y: -(initialBoardRect.y - 35) * initialBoardZoom,
    zoom: initialBoardZoom
  };

  return (
    <View
      nodes={nodes}
      edges={edges}
      flowPages={flowPages}
      flowId={flowId}
      activePageId={activePage}
      toolboxRef={toolboxRef}
      initialViewport={initialViewport}
      boardFitView={boardFitView}
      reactFlowWrapper={reactFlowWrapper}
      annotations={annotations}
      controlActions={controlActions}
      showAnimations={showAnimations}
      isFlowBeingSaved={isFlowBeingSaved}
      isGeneratingFlow={isGeneratingFlow}
      isRPADesigner={isRPADesigner}
      isDeveloper={isDeveloper}
      isAnonymous={isAnonymous}
      isDeleteNodesShown={isDeleteNodesShown}
      isAnimationOptionShown={isAnimationOptionShown}
      isPaginationShown={isPaginationShown}
      isPlaceholderShown={isPlaceholderShown}
      isControlsShown={isControlsShown}
      setReactFlowInstance={setReactFlowInstance}
      onDropNode={onDropNode}
      onDragNode={onDragNode}
      onConnectEdge={onConnectEdge}
      onUpdateEdge={onUpdateEdge}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      onDragStart={onDragStart}
      onClickAction={onClickAction}
      onClickChangePage={onClickChangePage}
      onDeleteSelectedNodes={onDeleteSelectedNodes}
      onClickUndo={onClickUndo}
      onClickRedo={onClickRedo}
      onClickClosePlaceholder={onClickClosePlaceholder}
      onChangeAnimations={onChangeAnimations}
      onGenerateFlow={onGenerateFlow}
    />
  );
}

export default Board;