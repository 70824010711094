import React from "react";
import swal from "sweetalert";
import { useQuery, useDelete, useDetail, useCount } from "seed/gql";
import { DELETE_FLOW } from "seed/gql/queries";
import { Loading } from "seed/helpers"
import View from "components/start/flows/Flows.view";

function Flows({ folders, selectedFolder, setSelectedFolder }) {

  const userId = sessionStorage.getItem("id");
  let flowFilter = "";
  const [callDelete, reqDelete] = useDelete(DELETE_FLOW, {
    onCompleted: () => {
      reqRecentFlows.refetch();
    }
  })

  if (selectedFolder) {
    flowFilter = `user.id=${userId} AND folder.id=${selectedFolder}`;
  } else {
    flowFilter = `user.id=${userId} OR (participants.user.id=${userId} AND participants.isAccepted=True)`
  }

  const reqRecentFlows = useQuery(`{
    flows {
      name
      createdAt
      user {
        firstName
        lastName
        license { name }
      }
      folder { name }
      participants {
        user { email }
      }
    }
  }`, flowFilter, { limit: 4, orderBy: "-updated_at" })

  const reqFlowCount = useCount('flow', `user.id=${userId}`)

  const reqFolder = useDetail(`{
    folder { name }
  }`, selectedFolder);

  const onDeleteFlow = (flowId) => {
    swal({
      title: "Eliminar proceso",
      icon: "warning",
      text: "¿Estás seguro que desea eliminar el proceso?",
      buttons: {
        confirm: {
          text: "Aceptar",
          className: "swal-button swal-button--cancel btn-success",
        },
        cancel: "Cancelar",
      },
    }).then((response) => {
      if (response) {
        callDelete({ id: flowId });
      }
    })
  }

  if (reqRecentFlows.loading || reqFlowCount.loading || reqFolder.loading) return <EmptyView />;
  const recentFlows = reqRecentFlows.data?.flows ?? [];
  const flowCount = reqFlowCount.data.flowCount.count;
  const folder = reqFolder.data?.folder ?? null;

  return (
    <View
      userId={userId}
      folder={folder}
      folders={folders}
      recentFlows={recentFlows}
      flowCount={flowCount}
      setSelectedFolder={setSelectedFolder}
      onDeleteFlow={onDeleteFlow}
    />
  );
}

const EmptyView = () =>
  <div id="start-flows">
    <div id="start-flows-container">
      <div id="start-flows-header" className={"d-flex align-items-center ml-1 justify-content-between"}>

        <h2 className="mb-0" style={{ color: "white", paddingTop: "0.1rem" }}>
          Procesos recientes
        </h2>
        <div>
          <a href="#" className={"btn btn-primary ml-3 mr-1"} style={{ visibility: "hidden" }}>
            <i class="fas fa-plus mr-2"></i>Crear proceso
          </a>
        </div>
      </div>

      <div id="flows" class="w-100">
        <div id="flows-container" class="d-flex flex-wrap justify-content-between">

          <div key="more" className="flow col-12 px-0" style={{ minHeight: "20rem" }}>
            <div className="card card-hover-shadow pb-0 px-0 mr-2 h-100">
              <Loading />
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>

export default Flows;