/* eslint-disable max-len */
import React from "react";
import { ActionElement } from "components/flow/toolbox/Toolbox.sub.ActionElement.view"
import $ from 'jquery';
import "styles/css/flow/Toolbox.css";


const CategoryItem = ({ id, groupIdx, label, icon, actionSearch, hasFilters }) =>
  <div key={"category-" + id}
    onClick={e =>
      setTimeout(() => {
        try {
          let currentScrollTop = $('#toolbox-actions').scrollTop()
          let nextScrollTop = $(e.target).offset().top - 400
          console.log(currentScrollTop, nextScrollTop)
          if (currentScrollTop - nextScrollTop < 0)
            $('#toolbox-actions').animate({
              scrollTop: nextScrollTop
            }, 500)
        } catch { }
      }, 150)}
    className={"btn-category-wrapper apps " + (groupIdx % 2 == 0 ? "first-col" : "")}
    style={{ display: actionSearch == "" && !hasFilters ? "block" : "none" }}>
    <a
      class=" btn-category d-flex justify-content-between px-1 w-100 rounded" data-toggle="collapse"
      href={"#section-" + id} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
      <div className="d-flex flex-column justify-content-center w-100" style={{ cursor: "pointer" }}>
        <div className={"category-icon ml-0 " + icon}></div>
        <div className="category-title">
          <span>{label}</span>
        </div>
      </div>
    </a>
  </div>;

const CollapseView = ({ id, mainActions, hiddenActions, onClickAction, onDragStart, actionSearch, hasFilters, groupActions = false }) => {
  const sectionId = "section-" + id
  const hiddenId = "hidden-" + id

  const getTitle = (actions, idx) =>
    (idx == 0 || actions[idx].app.name != actions[idx - 1].app.name) &&
      (groupActions || actionSearch != "" || hasFilters) ?
      <div className={"w-100 collapse-divider pr-1"}>
        <label className="w-100 text-left">
          {actions[idx].app.uiSettings?.label}
        </label>
      </div> : null


  return (
    <div id={sectionId} key={"collapse-" + id}
      className={"collapse w-100 " +
        (actionSearch != "" || hasFilters ? "show " : "") +
        (mainActions.length + hiddenActions.length == 0 ? "mt-0 mb-0 " :
          (actionSearch != "" || hasFilters ? "pt-1 pb-1 " : "pt-0 pb-3 "))}
      data-parent="#toolbox-actions">
      <ul className={"actions-list list-group pt-0 w-100 " + (actionSearch != "" || hasFilters ? "pb-0 " : "pb-2 ")}>
        {mainActions.map((action, idx) => {
          return (
            <div key={action.id}>
              {getTitle(mainActions, idx)}
              <ActionElement
                action={action}
                app={action.app}
                onClickAction={onClickAction}
                onDragStart={onDragStart}
              />
              {idx < mainActions.length - 1 &&
                mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                <div className="my-3"></div> : null
              }
            </div>
          );
        })}

        <div className="hidden-actions">
          {hiddenActions.length > 0 ?
            <a
              class="btn btn-sm py-2 px-3 mt-1 text-left hidden-actions-title" data-toggle="collapse"
              href={"#" + hiddenId} role="button" aria-expanded="false" aria-controls="schema-builder-hidden">
              Opciones avanzadas
              <i class="ml-2 fas fa-chevron-down"></i>
            </a> : null}

          <div class="collapse mt-1 pt-2 mb-2" id={hiddenId}>
            {hiddenActions.map((action, idx) => {
              return (
                <div key={action.id}>
                  <ActionElement
                    action={action}
                    app={action.app}
                    onClickAction={onClickAction}
                    onDragStart={onDragStart}
                  />
                  {idx < mainActions.length - 1 &&
                    mainActions[idx].uiSettings?.general?.group_label != mainActions[idx + 1].uiSettings?.general?.group_label ?
                    <div className="my-3"></div> : null
                  }
                </div>
              );
            })}
          </div>
        </div>

      </ul>
    </div>
  );
}


export { CategoryItem, CollapseView }