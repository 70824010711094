import React from "react";
import { Switch, Redirect } from "react-router-dom";
import { Route } from "seed/helpers"
import Panel from "components/misc/settings/Panel";
import Account from "components/misc/settings/Account";
import Flows from "components/misc/settings/Flows";
import Billing from "components/misc/settings/Billing";
import ExternalApps from "components/misc/settings/external_apps/ExternalApps";
import Dev from "components/misc/settings/Dev";
import "styles/css/misc/AccountSettings.css";



const SettingsView = () => (

	<div id="account-settings-general" className="position-relative">

		<div className="container px-2 px-md-7 py-4 py-md-7 py-xl-8">
			<div class="row m-0 w-100">

				<div className="col-md-4">
					<Panel />
				</div>

				<div className="col-md-8 mt-3 mt-md-0">
					<Switch>
						<Route path="/settings/account" component={Account} />
						<Route path="/settings/flows" component={Flows} />
						<Route path="/settings/billing" component={Billing} />
						<Route path="/settings/external_apps" component={ExternalApps} />
						<Route path="/settings/dev" component={Dev} />
						<Redirect to="/settings/account" />
					</Switch>
				</div>

			</div>
		</div>

	</div>
);

export default SettingsView;