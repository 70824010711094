import React, { useEffect, useState } from "react";
import { useGetCall } from "seed/api";
import View from "components/Home.view";
import { postMessage } from "./util/win";
import { Analytics } from "components/util/analytics";

function Home() {
	
	const urlParams = new URLSearchParams(window.location.search);
	const token = urlParams.get("token");
	const userId = urlParams.get("user_id");
	
	const [isAuth, setIsAuth] = useState(false);
	const [user, setUser] = useState(null)

	const [callAuth, reqCall] = useGetCall("/auth/user", "",  {
		onCompleted: (data) => {

			setIsAuth(true)
			setUser(data)
      const segment = localStorage.getItem("userProfile")
      const hiddenUserId = localStorage.getItem("hiddenUserId");
			
			Analytics.Identify(hiddenUserId || data.user, {
				'hidden_user_id': hiddenUserId || data.user,
				'user_id': data.user,
				'segment': segment
			});

		}, 
		onError: () => {
			sessionStorage.clear();
			localStorage.clear();
			postMessage({ type: "logout" });
			window.location.replace(`/login?next=${encodeURIComponent(new URL(window.location.href).pathname)}`);
		}
	})

	useEffect(() => {
		if (token) { //Preload data from URL
			sessionStorage.setItem("token", token);
			sessionStorage.setItem("id", userId);
			localStorage.setItem("token", token);
			localStorage.setItem("id", userId);
		} 
		if (localStorage.getItem("id") != null) { //Preload data from localStorage
			sessionStorage.setItem("token", localStorage.getItem("token"));
			sessionStorage.setItem("id", localStorage.getItem("id"));
		}
		callAuth({ user_id: sessionStorage.getItem("id") });
	}, [token, userId]); // eslint-disable-line react-hooks/exhaustive-deps
	
	if (!isAuth) return null;
	return <View user={user} />;
}

export default Home;