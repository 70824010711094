

const getMonthName = (monthNum) => {
    if (monthNum == 1) return "Ene";
    if (monthNum == 2) return "Feb";
    if (monthNum == 3) return "Mar";
    if (monthNum == 4) return "Abr";
    if (monthNum == 5) return "May";
    if (monthNum == 6) return "Jun";
    if (monthNum == 7) return "Jul";
    if (monthNum == 8) return "Ago";
    if (monthNum == 9) return "Sep";
    if (monthNum == 10) return "Oct";
    if (monthNum == 11) return "Nov";
    if (monthNum == 12) return "Dic";
    return "";
}

const getWeekDayNum = (weekday) => {
    weekday = weekday.toLowerCase();
    if (weekday == "monday") return 0;
    if (weekday == "tuesday") return 1;
    if (weekday == "wednesday") return 2;
    if (weekday == "thursday") return 3;
    if (weekday == "friday") return 4;
    if (weekday == "saturday") return 5;
    if (weekday == "sunday") return 6;
    return -1;
}

const getWeekNameByNumber = (weekday) => {
    if (weekday == 0) return "Lun";
    if (weekday == 1) return "Mar";
    if (weekday == 2) return "Mie";
    if (weekday == 3) return "Jue";
    if (weekday == 4) return "Vie";
    if (weekday == 5) return "Sab";
    if (weekday == 6) return "Dom";
    return "";
}


export { getMonthName, getWeekDayNum, getWeekNameByNumber }