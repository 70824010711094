import React, { useState } from 'react';
import { useQuery } from "seed/gql";
import { usePost } from "seed/api";
import { Loading } from "seed/helpers"
import { SERVER_URL} from "settings";
import { downloadFile, uploadFile } from "components/util/file_util";
import View from "components/misc/settings/Dev.view";

function Dev() {

  const userId = sessionStorage.getItem("id");
  const [selectedFlows, setSelectedFlows] = useState([]);

  const reqFlows = useQuery(`{
    flows {
      name
      createdAt
      isTemplate
      user {
        firstName
        lastName
        license { name }
      }
      folder { name }
      participants {
        user { email }
      }
    }
  }`, `user.id=${userId}`, {orderBy: "name"});

  const [callExport, reqExport] = usePost("/flows/export_flows", {
    onCompleted: (data) => downloadFile(SERVER_URL + data.url)
  });

  const [callImport, reqImport] = usePost("/flows/import_flows", {
    onCompleted: (data) => {
      window.location.reload();
    }
  });

  if (reqFlows.loading) return <Loading />;
  const { flows = [] } = reqFlows.data;

  const onImport = () =>
    uploadFile((file) => callImport({ user_id: userId, file }));

  const onExport = () => 
    callExport({ flow_ids: selectedFlows });

  return <View 
    flows={flows}
    selectedFlows={selectedFlows}
    setSelectedFlows={setSelectedFlows}
    onExport={onExport}
    onImport={onImport}
  />;
  
}

export default Dev;