import React from 'react';
import { toast, Flip } from 'react-toastify';
import { Loading } from "seed/helpers";

const getFlowName = (profile, flow) => {
    const templateSettings = flow?.templateSettings ?? {};
    const templateSettingsName = templateSettings?.name ?? {};
  
    if (typeof templateSettingsName === "string")
      return templateSettingsName;
  
    const templateSettingsNameKeys = Object.keys(templateSettingsName)
  
    if (profile && templateSettingsNameKeys.includes(profile?.name)) {
      return templateSettingsName[profile.name];
    }
  
    if (templateSettingsNameKeys.includes("default")) {
      const defaultName = templateSettingsName["default"];
      return defaultName;
    }
  
    return flow?.name ?? "";

}

const addPostMessageListener = (callback) =>
  window.addEventListener("message", (event) =>
    callback(event.data));

const removePostMessageListener = (callback) =>
  window.removeEventListener("message", callback);

const sendPostMessage = (data) =>
  window.parent.postMessage(data, "*");

const refreshExecMode = () => {
  sendPostMessage({ type: "refetch-exec-mode-charts" });
  sendPostMessage({ type: "refetch-exec-mode-data" });
  sendPostMessage({ type: "refetch-exec-mode-list" });
}

const showExecutionToast = ({
  status, 
  executionId, 
  message,
  setIsExecutionDetailsShown = () => {},
  setActualExecutionId = () => {},
}) => {

  const isDeveloper = sessionStorage.getItem("developer_mode") == "true";

  let attrs = {
    position: "top-right",
    autoClose: status == "RUNNING" ? false : 2250,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
    toastId: executionId,
    transition: Flip,
    onClick: () => {
      setIsExecutionDetailsShown(true);
      setActualExecutionId(executionId);
    }
  }

  let text = ""
  let subtitle = ""
  let button = ""

  if (status == "RUNNING") {
    text = 'Proceso en ejecución ...'
    attrs.icon = ({ theme, type }) => <Loading size={18} />
    attrs.type = "info"
  }

  if (status == "OK") {
    refreshExecMode();
    text = 'Proceso completado'
    attrs.icon = null
    attrs.type = "success"
    attrs.autoClose = 500000
    button = <button className="btn btn-sm btn-primary py-1 mt-1" onClick={() => setIsExecutionDetailsShown(true)}>
      Ver resultados
    </button>
  }

  if (status == "FAILED") {
    refreshExecMode();
    text = 'Ha ocurrido un error'
    subtitle = message?.type == "flow_limit" ? "Revisa los límites de tu plan" : ""
    attrs.icon = null
    attrs.type = "error"
  }

  if (status == "AWAITING") {
    toast.dismiss(executionId);
    return
  }

  if (isDeveloper) text += ` (exec_id=${executionId})`

  let result = (
    <div className="ml-1" style={{ cursor: "pointer !important" }}>
      <div>{text}</div>
      {subtitle ? <div><span style={{ fontSize: "0.9em" }}>{subtitle}</span></div> : null}
      {button}
    </div>
  )

  if (!toast.isActive(executionId)) toast(result, attrs);
  else toast.update(executionId, { ...attrs, render: result });

}

export { getFlowName, showExecutionToast, addPostMessageListener, removePostMessageListener, sendPostMessage };