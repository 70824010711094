import React from "react";
import { Link } from "react-router-dom";
import { Modal, ModalRoute, ScriptTag } from "seed/helpers";
import Templates from "components/start/templates/Templates";
import Flows from "components/start/flows/Flows";
import FormSaveFlow from "components/start/flows/FormSaveFlow";
import FormSetFlow from "components/start/flows/FormSetFlow";
import FormSaveFolder from "components/start/folders/FormSaveFolder";
import FormSetFolder from "components/start/folders/FormSetFolder";
import Collaboration from "components/flow/forms/collaboration/Collaboration";
import FlowsUser from "components/misc/settings/Flows";
import Usage from "components/start/usage/Usage";
import "styles/css/start/Start.css";


const StartView = ({
  user,
  folders,
  selectedFolder,
  isExecutionsShown,
  isDeveloperMode,
  setSelectedFolder,
  setIsExecutionsShown,
  onClickLogout
}) => (

  <div>

    <div id="start">

      <div class="container px-2 px-md-10 py-4 py-md-7">
        <div id="start-container" class="row m-0 px-2 px-md-7 w-100">

          <div className="col-0 col-md-1"></div>

          <div className="col-12 col-md-10 px-0 mt-3 mt-md-0 mb-10">

            <div className="d-flex justify-content-between mb-3">
              <img class="z-index-2"
                src="/theme/svg/logos/logo-white.svg" alt="Logo"
                style={{ height: "2.8rem" }} />

              <aside class="navbar p-0 bg-transparent mt-n2">

                <a id="start-profile" className="btn btn-outline-primary js-hs-unfold-invoker navbar-dropdown-account-wrapper mr-1"
                  data-hs-unfold-options='{
                "target": "#accountNavbarDropdown",
                "type": "css-animation"
              }'>
                  <div class="avatar avatar-sm avatar-circle mr-2">
                    <img class="avatar-img" src="/theme/img/160x160/img1-b.jpg" alt="Imagen de perfil" />
                  </div>
                  <div className="text-left mr-1 ml-1">
                    <span class="d-block h5 text-hover-primary mb-0">
                      {user?.firstName ?? ""} {user?.lastName ?? ""}
                    </span>
                    <span class="d-block font-size-sm text-body">
                      Plan {(user?.license?.name ?? "básico").toLowerCase()}
                    </span>
                  </div>
                  <div className="mr-0 pb-2">
                    <small class="ml-1 text-hover-primary mr-1"><i class="fas fa-chevron-down"></i></small>
                  </div>
                </a>

                <div id="accountNavbarDropdown"
                  class="hs-unfold-content dropdown-unfold dropdown-menu dropdown-menu-right navbar-dropdown-menu navbar-dropdown-account"
                >

                  <Link to="/settings/account" class="dropdown-item">
                    <span class="text-truncate pr-2">Cuenta & Perfil</span>
                  </Link>

                  <Link to="/settings/plan" class="dropdown-item">
                    <span class="text-truncate pr-2">Plan</span>
                  </Link>

                  <Link to="/settings/flows" class="dropdown-item">
                    <span class="text-truncate pr-2">Procesos</span>
                  </Link>

                  <div class="dropdown-item">
                    <span role="button" class="text-truncate pr-2" title="Settings" onClick={onClickLogout}>
                      Cerrar sesión
                    </span>
                  </div>
                </div>

              </aside>

            </div>

            <Flows
              folders={folders}
              selectedFolder={selectedFolder}
              setSelectedFolder={setSelectedFolder}
            />

            <div className="mt-3">
              <Templates />
            </div>

            <Usage />
          </div>

          <div className="col-0 col-md-1"></div>

        </div>
      </div>

    </div>

    <ModalRoute
      width="425"
      height="450"
      path="/create_folder"
      component={() => <FormSaveFolder setSelectedFolder={setSelectedFolder} />}
    />

    <ModalRoute
      width="425"
      height="450"
      path="/:folderId/edit_folder"
      component={FormSetFolder}
    />

    <ModalRoute
      width="500"
      height="500"
      path="/:folderId/create_flow"
      component={FormSaveFlow}
    />

    <ModalRoute
      width="500"
      height="500"
      path="/:flowId/edit_flow"
      component={FormSetFlow}
    />

    <ModalRoute
      width="800"
      height={window.innerHeight - 150}
      path="/:flowId/share"
      component={(props) =>
        <Collaboration flowId={props.match?.params?.flowId} />
      }
    />


    {isExecutionsShown && (
      <Modal
        width={500}
        height={500}
        onClose={() => setIsExecutionsShown(false)}
        component={FlowsUser}
      />
    )}

    <ScriptTag content={`
      // Initialization of unfold
      $('.js-hs-unfold-invoker').each(function () {
        var unfold = new HSUnfold($(this)).init();
      });
    `} />

  </div>
);

export default StartView;