const ColumnTypes = {
  STRING: "string",
  FLOAT: "float",
  DATE: "date",
  FILE: "file",
  BOOLEAN: "boolean",
  COLLECTION: "collection",
  ENUM: "enum",
  OBJECT: "object",
};

const ColumnTypeLabels = {
  [ColumnTypes.STRING]: "Texto",
  [ColumnTypes.FLOAT]: "Número",
  [ColumnTypes.DATE]: "Fecha",
  [ColumnTypes.FILE]: "Archivo",
  [ColumnTypes.BOOLEAN]: "Booleano",
  [ColumnTypes.COLLECTION]: "Tabla",
  [ColumnTypes.ENUM]: "Enum",
  [ColumnTypes.OBJECT]: "Objeto",
}

const parseExportData = (column, data) => {
  try {
    switch (column.type) {
      case ColumnTypes.STRING:
        return data;
      case ColumnTypes.ENUM:
        return data;
      case ColumnTypes.FLOAT:
      case ColumnTypes.BOOLEAN:
        return data?.toString();
      case ColumnTypes.DATE:
        return data?.toISOString();
      case ColumnTypes.FILE:
        return data.url;
      default:
        return data;
    }
  }
  catch (e) {
    return data;
  }
}

const parseSuggestionType = (type) => {
  switch(type) {
    case "string": return "string";
    case "text": return "string";
    case "date": return "date";
    case "file": return "file";
    case "select": return "enum";
    case "checkbox": return "boolean";
    case "number": return "number";
    default: return "string";
  }
}

const parseRowByColumn = (row, columns) => {

  let newRow = {};

  Object.keys(row).forEach((key) => {

    const column = columns.find((column) => column.name == key);

    if(column) {

      switch(column.type) {

        case ColumnTypes.FLOAT:
          try {
            newRow[key] = parseFloat(row[key]);
            if(isNaN(newRow[key])) newRow[key] = null;
          } catch(e) { }
          break;

        case ColumnTypes.DATE:
          try {
            newRow[key] = new Date(row[key]);
          } catch(e) { }
          break;

        case ColumnTypes.BOOLEAN:
          try {
            newRow[key] = row[key].toLowerCase() == "true";
          } catch(e) { }
          break;

        case ColumnTypes.FILE:
          try{
            
            if(typeof row[key] == "object") newRow[key] = row[key];
            else if(row[key]?.startsWith("http")) newRow[key] = { url: row[key] };
            else if(!isNaN(row[key])) newRow[key] = { id: parseInt(row[key]) };
            else newRow[key] = JSON.parse(row[key]);

            if(isNaN(newRow[key]?.id)) newRow[key] = null;

          } catch(e) { }
          break;

        case ColumnTypes.COLLECTION:
          try{
            
            if(typeof row[key] == "object") newRow[key] = row[key];
            else if(!isNaN(row[key])) newRow[key] = { id: parseInt(row[key]) };
            else newRow[key] = JSON.parse(row[key]);

            if(isNaN(newRow[key]?.id)) newRow[key] = null;

          } catch(e) { }
          break;

        default:
          try {
            newRow[key] = row[key].toString();
          } catch(e) { }
          break;

      }
      
    }

  });

  return newRow;

}

const getSelectTypes = () => {
  const keys = Object.keys(ColumnTypes).filter((key) => key !== "OBJECT");
  return keys.map((key) => {
    return ColumnTypes[key]
  });
}

const parseDatatypes = (structure, rawValues, appDatatypes) => {

  // Wrappeer for values
  const rule1 = (structure, rawValues, datatypes) => {

    if(typeof rawValues === "object" && !Array.isArray(rawValues))
      return { structure, rawValues: [rawValues] };

    return { structure, rawValues };

  }

  // Wrapper for structure
  const rule2 = (structure, rawValues, datatypes) => {

    if(typeof structure === "object" && !Array.isArray(structure)) {

      let nativeDataTypes = Object.keys(ColumnTypes)
        .map((key) => ColumnTypes[key])
          .filter((key) => key != ColumnTypes.OBJECT);

      if(structure.type != ColumnTypes.OBJECT && nativeDataTypes.includes(structure.type))
        return { structure: [structure], rawValues };
      
    }

    return { structure, rawValues };

  }

  // Wrapper for structure datatype
  const rule3 = (structure, rawValues, datatypes) => {

    if(typeof structure === "object" && !Array.isArray(structure)) {

      let nativeDataTypes = Object.keys(ColumnTypes)
        .map((key) => ColumnTypes[key])
          .filter((key) => key != ColumnTypes.OBJECT);

      if(structure.type == ColumnTypes.OBJECT)
        return { structure: structure.structure??[structure], rawValues };
      
      if(!nativeDataTypes.includes(structure.type)) {
        let datatype = datatypes?.find(dt => dt.name == structure?.type);
        if(datatype) return { structure: [{ ...structure, type: ColumnTypes.OBJECT, structure: datatype.structure }], rawValues };
      }

    }
    else {

      let nativeDataTypes = Object.keys(ColumnTypes)
        .map((key) => ColumnTypes[key])
          .filter((key) => key != ColumnTypes.OBJECT);

      structure = structure.map((column) => {

        if(!nativeDataTypes.includes(structure.type)) {
          let datatype = datatypes?.find(dt => dt.name == column?.type);
          if(datatype) return { ...column, type: ColumnTypes.OBJECT, structure: datatype.structure };
        }

        return column;

      });

    }

    return { structure, rawValues };

  }

  // Wrapper for rawValues which keys are not in structure
  const rule4 = (structure, rawValues, datatypes) => {

    if(Array.isArray(structure) && Array.isArray(rawValues)) {

      let newRawValues = rawValues.map((row) => {

        let newRow = {};
        structure.forEach((column) => {
          if(!row[column.name]) {
            newRow[column.name] = row;
          }
        });

        return Object.assign({}, newRow, row);

      });

      return { structure, rawValues: newRawValues };

    }

    return { structure, rawValues };

  }

  let newRawValues = rawValues;
  let newStructure = structure;

  // Apply rules
  ({ structure: newStructure, rawValues: newRawValues } = rule1(newStructure, newRawValues, appDatatypes));
  ({ structure: newStructure, rawValues: newRawValues } = rule2(newStructure, newRawValues, appDatatypes));
  ({ structure: newStructure, rawValues: newRawValues } = rule3(newStructure, newRawValues, appDatatypes));
  ({ structure: newStructure, rawValues: newRawValues } = rule4(newStructure, newRawValues, appDatatypes));

  // Wrapper for rawValues 
  if(newRawValues)
    newRawValues = newRawValues.map((row) => ({ data: row }));

  if(!Array.isArray(newStructure)) newStructure = [newStructure];

  return { newStructure, newRawValues };

}

export {
  ColumnTypes,
  ColumnTypeLabels,
  getSelectTypes,
  parseExportData,
  parseSuggestionType,
  parseRowByColumn,
  parseDatatypes
}