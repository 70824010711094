import React from "react";

const Options = ({ columns, onChangeColumnOptions }) => {

  return <div className="card card-body">

    <div className="h5 mb-3">
      Ocultar / Mostrar columnas
    </div>

    {
      columns.map((column, index) => {
        return <div 
          key={`${columns.label}-${index}`}
          className="d-flex justify-content-between"
        >
          <div>
            {column.label}
          </div>
          <div>
            <input 
              type="checkbox"
              checked={column.visible??true}
              onChange={(e) => onChangeColumnOptions(index, {...column, visible: e.target.checked})}
            />
          </div>
        </div>
      })
    }

  </div>

}

export default Options;